import { Button } from 'mdbreact'
import React from 'react'

export const BoutonComposant = ({disabled,type,couleur,titre,color,funcExcute}) => {
  return (
    <button disabled={disabled} onClick={()=> funcExcute()} type={type} style={{backgroundColor:couleur,color:color}} className={"btn btn-" +couleur}  >{titre}</button>
  )
}


