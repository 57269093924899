
import api from "./Api";
import AuthService from "./AuthService";
import Cookies from "js-cookie";

class DocumentService {

 AddDocument(document) {
        const url= "/api/documents";
        const formData = new FormData();
        formData.append("order",document.order)
        formData.append("name",document.name)
        formData.append("description",document.description)
        formData.append("file",document.file)
        formData.append("type",document.type)

        const config = {
         headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${Cookies.get('token')}`,
            }
                }

        return  api.post(url,formData,config)

    }




    AddDocumentOfClient(document) {
        const url= "/api/documents/customer";
        const formData = new FormData();
        formData.append("customer",document.customer)
        formData.append("name",document.name)
        formData.append("description",document.description)
        formData.append("file",document.file)
        formData.append("type",document.type)

        const config = {
         headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${Cookies.get('token')}`,
            }
                }

        return  api.post(url,formData,config)
        //   return  post(url,formData,config)

    }


    getDocumentsCustomer(id) {
        return api.get("/api/documents/customer/"+id,AuthService.getToken('token'))
    }

    getDocumentById(id) {
        return api.get("/api/documents/order/"+ id,AuthService.getToken('token'))
    }


    getAllDocument(){
        return api.get("/api/documents",AuthService.getToken('token'))
    }

  // CURRENT, EXPIRED, CANCELED, ARCHIVED

  changeStatusDocument(documentId,status){
    return api.put(`/api/documents/${documentId}/status?status=${status}`, {}, AuthService.getToken('token'))
  }

  title=(etat)=>{
    switch(etat) {
        case "documents":
          return "LISTE DOCUMENTS"
          break;
        case "ARCHIVED":
          return "LISTE DOCUMENTS ARCHIVÉS"
          break;
        case "ACCEPTED":
            return "LISTE DEMANDES ACCEPTÉES"
          break;
          case "REFUSED":
            return "LISTE DEMANDES REFUSÉES"
          break;
          case "CREATED":
            return "LISTE NOUVELLE DEMANDES"
          break;
         
         
        default:
          // code block
      }

}



}

export default new DocumentService();
