import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import AuthService from '../../Service/AuthService';

function CookieManager() {
    const navigate=useNavigate()
  let timeoutId;

  function resetTimeout() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      AuthService.logout()
      navigate("/")
    //   console.log(document.cookie);
      // Remplacer "authToken" par le nom du cookie de connexion que vous utilisez dans votre application.
    }, 3 * 60 * 1000);
    // 30 minutes en millisecondes
  }

  useEffect(() => {
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keypress', resetTimeout);

    return () => {
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keypress', resetTimeout);
    };
  }, []);

  return null;
}

export default CookieManager;
