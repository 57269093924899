import * as React from "react";

import TextField from "@mui/material/TextField";
import generalService from "../../Service/generalService";

export default function InputOutline({
  value,
  func,
  label,
  disabled,
  limit,
  type,
  name,
  positif,
  bloc,
  onBlur,
  error,
  helperText
}) {
  const [errorEmail, setErrorEmail] = React.useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      bloc(true);
      setErrorEmail("Email est invalide");
    } else {
      bloc(false);
      setErrorEmail(null);
    }
  };

  function checkLength(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, limit);
    }
  }

  return (
    <div className="mb-3">
      <TextField
        fullWidth
        size="small"
        name={name}
        type={type}
        value={value}
        required
        label={label}
        onChange={func}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        onInput={(e) => checkLength(e)}
        // onChange={(e) => {
        //   type == "email" ? handleChange(e) : func(e.target.value);
        //   generalService.handleNumChange(e, func, limit);
        // }}
      />
      {/* {errorEmail && <span style={{ color: "red" }}>{errorEmail}</span>} */}
    </div>
  );
}
