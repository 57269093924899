import NavBar from "../../NavBar/NavBar";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EquipementService from "../../../Service/EquipementService";
import Example from "../../Notification/NotificationCompenent";
import { Alert, Button } from "react-bootstrap";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ConsommationService from "../../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";
import InstitutionService from "../../../Service/InstitutionService";
import UserService from "../../../Service/UserService";
import Title from "../../eng_composants/Title";
import UploadAndDisplayImage from "../../Inputs/UploadLogo";
// import { Paper } from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BoutonComposant } from "../../eng_composants/BoutonComposant";
import ImageUpload from "./ImageUpload";
import PhoneInputNumber from "../../Inputs/PhoneInputNumber";

const LenderForm = () => {
  const [clientId, setClientId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [budget, setBudget] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [latenessGracePeriod, setLatenessGracePeriod] = useState("");
  const [collectionGracePeriod, setCollectionGracePeriod] = useState("");
  
  const [location, setLocation] = useState("");
  const [deactive, setDeactive] = useState(true);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [fermer, setFermer] = useState(false);
  const [insLogo, setInsLogo] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    UserService.getLenderById(id).then((res) => {
      setClientId(res.data.id);
      setName(res.data.name);
      setLocation(res.data.location);
      setEmail(res.data.email);
      setCollectionGracePeriod(res.data.collectionGracePeriod);
      setPhoneNumber(res.data.phoneNumber);
      // setLatenessGracePeriod(res.data.latenessGracePeriod);
      // setBudget(res.data.budget);
      // setInterestRate(res.data.interestRate);
    });
  }, []);


  function myRedirect() {
    navigate("institution/id");
  }

  const lender = {
    code: "string",
    name: name,
    location: location,
    email: email,
    phoneNumber: phoneNumber,
    budget: budget,
    interestRate: interestRate,
    status: "ACTIVE",
    latenessGracePeriod: latenessGracePeriod,
    collectionGracePeriod: collectionGracePeriod,
  };

  const getLogo = (logo) => {
    setInsLogo(logo);
  };

  const logo = {
    lender: id,
    file: insLogo,
  };

 

  const handleSubmit = (lender) => {
    if (id) {
      lender.id = id;
      InstitutionService.editInstitution(lender, id).then(() => {
        ConsommationService.notifier(
          "success",
          "Institution Modifiée avec succès",
          "erreur"
        );
      });
    } else {
      InstitutionService.createLender(lender)
        .then(() => {
          ConsommationService.notifier(
            "success",
            "Institution enregistrée avec succès",
            "erreur"
          );
          setFermer(true);
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Institution enregistrée avec succès",
            "Une erreur est survenue lors de la création d'Institution, Veuillez réessayer !"
          );
          console.log(err);
        });
    }
  };
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <NotificationContainer />
      <div className="text-center m-5">
        <Title texte="INFORMATIONS INSTITUTION" />
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="MyTab">
              <Tabs defaultIndex={0}>
                <TabList>
                  <Tab>
                    <b>INFORMATIONS GÉNÉRALES</b>
                  </Tab>
                  <Tab>
                    <b>LOGO INSTITUTION </b>
                  </Tab>

                  <Tab>
                    <b>CONFIGURATION SERVEUR SMTP</b>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Formik onSubmit={() => handleSubmit()}>
                    {({ resetForm }) => (
                      <Form className="pb-3 pt-4">
                        <div className="row col-md-12 justify-content-center">
                          <div>
                            <NotificationContainer />
                          </div>

                          <div className="gauche col-md-6">
                            {tabIndex === 0 && (
                              <div>
                                <div className="form-group">
                                  <label htmlFor="name" className="mb-2">
                                    <b> Nom</b>
                                  </label>
                                  <Field
                                    type="text"
                                    value={name}
                                    id="name"
                                    name="name"
                                    placeholder="Saisir Nom Institution"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="email" className="mb-2">
                                    <b>Email</b>
                                  </label>
                                  <Field
                                    // style={{ height: "25px" }}
                                    type="email"
                                    value={email}
                                    id="email"
                                    name="email"
                                    placeholder="Saisir Email"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="location" className="mb-2">
                                    <b> Adresse</b>
                                  </label>
                                  <Field
                                    type="text"
                                    id="location"
                                    value={location}
                                    name="location"
                                    placeholder="Saisir Nom Institution"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setLocation(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="location" className="mb-2">
                                    <b>Téléphone</b>
                                  </label>
                                  <PhoneInputNumber
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                  />
                                </div>
                                {/* <div className="form-group">
                                  <label htmlFor="location" className="mb-2">
                                    <b>Budget</b>
                                  </label>
                                  <Field
                                    type="number"
                                    id="location"
                                    value={budget}
                                    name="budget"
                                    placeholder="Saisir le budget"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setBudget(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="location" className="mb-2">
                                    <b> Intérêt</b>
                                  </label>
                                  <Field
                                    type="number"
                                    id="interestRate"
                                    value={interestRate}
                                    name="interestRate"
                                    placeholder="Saisir l'interet"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setInterestRate(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="location" className="mb-2">
                                    <b> Délai de Grâce</b>
                                  </label>
                                  <Field
                                    type="number"
                                    id="latenessGracePeriod"
                                    value={latenessGracePeriod}
                                    name="latenessGracePeriod"
                                    placeholder="Saisir période de grace"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setLatenessGracePeriod(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="location" className="mb-2">
                                    <b>Délai de Recouvrement</b>
                                  </label>
                                  <Field
                                    type="number"
                                    id="latenessGracePeriod"
                                    value={collectionGracePeriod}
                                    name="collectionGracePeriod"
                                    placeholder="Saisir période de grace"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                      setCollectionGracePeriod(e.target.value);
                                    }}
                                  />
                                </div> */}
                              </div>
                            )}
                          </div>
                          <div className="d-flex justify-content-center gap-4 mt-4">
                            <Button
                              type="button"
                              onClick={resetForm}
                              className="btn btn-dark"
                              href="/home"
                            >
                              Annuler
                            </Button>
                            <button
                              type="button"
                              onClick={() => {
                                handleSubmit(lender);
                              }}
                              className="btn btn-success"
                              style={{
                                backgroundColor: "#2b4366",
                                border: "#2b4366",
                              }}
                              disabled={
                                lender.name == "" ||
                                lender.location == "" ||
                                lender.email == ""
                                  ? deactive
                                  : !deactive
                              }
                            >
                              Enregistrer
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <div className="mt-5">
                    <ImageUpload cardName="Input Image" />
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="col-4">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Adresse Serveur
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="127.0.0.1"
                        ></input>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Mot De Passe
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="********"
                        ></input>
                      </div>
                      <BoutonComposant
                        disabled={false}
                        type="button"
                        couleur="#2b4366"
                        titre="Enregistrer"
                        color="white"
                      />
                    </form>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default LenderForm;
