import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../Service/UserService";
import SF_logo from "../../images/SF_logo.JPG";
import ValidatedLoginForm, {
  LoginForm,
} from "../FormValidationLogin/LoginForm";
import { auth } from "../../hooks/auth";
import BanqueService from "../../Service/BanqueService";
import AuthService from "../../Service/AuthService";
import { BoutonComposant } from "../eng_composants/BoutonComposant";
import { useFormik } from "formik";

import styles from "./ForgetPassword.module.css";
import { Form } from "react-bootstrap";

export default function ForgetPassword() {
  const [success, setSuccess] = useState("");

  
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/home");
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    onSubmit: (values) => {
      const passwordData = {
        ...values,
      };

      console.log(passwordData);

        UserService.changepassword(passwordData).then(() => {
        setSuccess("Mot De Passe Modifier Avec Succée");
        AuthService.logout();
        myRedirect();
      });
    },
  });

  // const change = {
  //   oldPassword: password,
  //   newPassword: newpassword,
  // };

  function myRedirect() {
    navigate("/");
  }

  // const changePassword = () => {
  //   UserService.changepassword(change).then(() => {
  //     setSuccess("Mot De Passe Modifier Avec Succée");
  //     AuthService.logout();
  //     myRedirect();
  //   });
  // };

  return (
    <div className={styles.app}>
      <div className={styles.loginForm}>
        <div className="d-flex justify-content-center mb-3">
          <img src={SF_logo} style={{ display: "flex", maxWidth: "40%" }} />
        </div>

        <div>
          <Form onSubmit={formik.handleSubmit}>
            {success ? (
              <div class="alert alert-success" role="alert">
                {success}
              </div>
            ) : (
              ""
            )}
            <div class="mb-3 text-start">
              <label
                for="exampleFormControlInput1"
                class="form-label font-weight-bold "
              >
                Ancien Mot De Passe
              </label>
              <input
                type="password"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Ancien"
                name="oldPassword"
                onChange={formik.handleChange}
                value={formik.values.oldPassword}
              />
            </div>
            <div class="mb-3 text-start">
              <label
                for="exampleFormControlInput1"
                class="form-label font-weight-bold "
              >
                Nouveau Mot De Passe
              </label>
              <input
                type="password"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Nouveau"
                name="newPassword"
                onChange={formik.handleChange}
                value={formik.values.newPassword}
              />
            </div>
            <div className="d-flex justify-content-around mt-4">
              <BoutonComposant
                disabled={false}
                type="button"
                couleur="black"
                titre="Annuler"
                color="white"
                funcExcute={redirect}
              />
              <BoutonComposant
                disabled={!formik.isValid}
                type="submit"
                couleur="#2b4366"
                titre="Modifier"
                color="white"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
