import React from "react";
import { Label } from "reactstrap";

function FiltreComposant({
  labelCritere,
  labelFiltre,
  listeCritere,
  functionFiltere,
}) {
  const [search, setSearch] = React.useState("");
  const [criteriaFilter, setCriteriaFilter] = React.useState("");
  functionFiltere(criteriaFilter, search);
  return (
      <div className="row col-6">
        <div className="col-4">
          <Label className="mb-1">
            <b>{labelCritere}</b>{" "}
          </Label>
          <select
            className="form-select form-select-sm  mb-3"
            aria-label=".form-select-sm example"
            name="criteriaFilter"
            onChange={(e) => setCriteriaFilter(e.target.value)}
          >
            <option selected disabled>
              Critère
            </option>
            {listeCritere.map((operator) => (
              <option key={operator} value={operator}>
                {operator}
              </option>
            ))}
          </select>
        </div>
        <div className="col-4">
          <Label className="mb-1" style={{ marginRight: "0%" }}>
            <b>{labelFiltre}</b>
          </Label>
          <input
            type="text"
            className="form-control"
            id="inputPassword"
            style={{ height: "32px" }}
            name="search"
            placeholder="Filtre"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
  );
}

export default FiltreComposant;
