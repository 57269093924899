import axios from "axios";
import api from "./Api";
import AuthService from "./AuthService";
import ConsommationService from "./ConsommationService";
import EquipementService from "./EquipementService";
import Cookies from "js-cookie";

const USER_API_URL = "/api/customers";

const URL_ORDERS_CUSTOMER = "/api/orders/customer";
const DEMANDE_URL = "/api/orders";
const AUTHENTICATE_URL = "http://34.201.99.216:8081/api/auth/authenticate";
const REGISTER_URL = "/api/auth/register";
const CREATE_USER_URL = "/api/users";
const REGION_URL = "http://34.201.99.216:8081/api/references/regions";
const LENDER_URL = "/api/references/lenders";
const clientEtat = ["ACTIVE", "INACTIVE", "PENDING", "REJECTED", "BLOCKED"];

class UserService {
  getAllClients() {
    return api.get(USER_API_URL, AuthService.getToken("token"));
  }

  getClientById(clientId) {
    return api.get(
      USER_API_URL + "/" + clientId,
      AuthService.getToken("token")
    );
  }

  getDemandesForOneCustomer = (clientId) => {
    return api.get(
      URL_ORDERS_CUSTOMER + "/" + clientId,
      AuthService.getToken("token")
    );
  };

  recupereClient(id) {
    EquipementService.getClientDemande(id, AuthService.getToken("token"));
  }

  createClient(client) {
    return api.post(USER_API_URL, client, AuthService.getToken("token"));
  }
  changeStatuscustomer(id, status) {
    return api.put(
      `/api/customers/${id}/status?status=${status}`,
      {},
      AuthService.getToken("token")
    );
  }

  editClient(clientData) {
    return api.put(
      `${USER_API_URL}/${clientData.id}`,
      clientData,
      AuthService.getToken("token")
    );
  }

  editDemandeEquip(objet, id) {
    return api.put(
      DEMANDE_URL + "/" + id,
      objet,
      AuthService.getToken("token")
    );
  }

  authenticateUser(user) {
    return axios.post(AUTHENTICATE_URL, user);
  }

  register(user) {
    return api.post(REGISTER_URL, user);
  }

  createUser(user) {
    return api.post(CREATE_USER_URL, user, AuthService.getToken("token"));
  }

  getAllUsers() {
    return api.get(CREATE_USER_URL, AuthService.getToken("token"));
  }

  updateStatusUser = (id, status) => {
    return axios({
      method: "put",
      url:
        process.env.REACT_APP_TODOLIST_DATAFILE_URL +
        CREATE_USER_URL +
        "/" +
        id +
        "/" +
        status,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  getUserByUserName(email) {
    return api.get(
      "/api/users/username/" + email,
      AuthService.getToken("token")
    );
  }

  getUserById(id) {
    return api.get("/api/users/" + id, AuthService.getToken("token"));
  }

  updateUser(userData) {
    return api.put(
      `${CREATE_USER_URL}/${userData.id}`,
      userData,
      AuthService.getToken("token")
    );
  }

  getLenderById(id) {
    return api.get(`${LENDER_URL}/${id}`, AuthService.getToken("token"));
  }

  //
  changepassword(objet) {
    return api.put(
      `/api/users/${AuthService.getCookie("user")}/change-password`,
      objet,
      AuthService.getToken("token")
    );
  }
}

export default new UserService();
