import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../hooks/auth";
import UserService from "../../Service/UserService";
import logo_crediz from "../../images/logo_crediz.png";
import SF_logo from "../../images/SF_logo.JPG";

import ValidatedLoginForm, {
  LoginForm,
} from "../FormValidationLogin/LoginForm";
import { auth } from "../../hooks/auth";
import BanqueService from "../../Service/BanqueService";

import styles from "./Login.module.css";

export default function Login() {

  return (
    <div className={styles.app}>
      <div className={styles.loginForm}>
        <div className="d-flex justify-content-center">
          <img src={SF_logo} style={{ display: "flex", maxWidth: "39%" }} />
        </div>
        <div className="form">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}
