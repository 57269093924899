import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import notFound from "../../images/notFound.jpg";
import AuthService from '../../Service/AuthService';

function PageNotFound() {
  const navigate=useNavigate()
  const deconnecteUser=()=>{
    AuthService.logout()
    navigate("/")
  }
  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
    <div>
    <div className='text-center'>
    <h1 className='mb-4'>PAGE INTROUVABLE</h1>
    <h3>SOLUTIONFLEXI</h3>
    <h3>APPLICATION DE GESTION  DES PRETS</h3>
      </div>
  
    <img src={notFound}  style={{border:"2px",borderRadius:"10px",padding:"2rem",textAlign:"center",boxShadow:'inherit'}}/>
     <Button onClick={()=> deconnecteUser()}>Veuillez vous reconnecter</Button> 
    </div>
  
    </div>
  
  )
}

export default PageNotFound