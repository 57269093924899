import React, { useState, useEffect, useReducer } from "react";
import { Button, Container, Table } from "react-bootstrap";
import EquipementService from "../../Service/EquipementService";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Pagination from "../Pagination/Pagination";
import { Paginate } from "../Utils/Paginate";
import EditDemandeEquip from "../Modals/ EditDemandeEquip";
import BanqueService from "../../Service/BanqueService";
import {
  FaFileCsv,
  FaFilePdf,
  FaTimesCircle,
  FaFolderOpen,
} from "react-icons/fa";
import generalService from "../../Service/generalService";
import { BiSortAZ } from "react-icons/bi";
import dateFormat from "dateformat";
import Checkbox from "@mui/material/Checkbox";
import { MDBTooltip } from "mdb-react-ui-kit";
import Badge from "../Badge/Badge";
import { IoIosSend } from "react-icons/io";
import ConsommationService from "../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveDemandesEquipInDivision,
  setDataDemandesEquipement,
  store,
  updateStatusOneDemande,
  updateStatusOneDemandeClient,
} from "../../redux/redux";
import FiltreComposant from "../ComposantsElementaire/FiltreComposant";
import Title from "../eng_composants/Title";
import ButtonIcon from "../eng_composants/ButtonIcon";
import AuthService from "../../Service/AuthService";

const ListEquipements = () => {
  const { etat } = useParams();
  const [demands, setDemands] = useState([]);
  const [banques, setBanques] = useState([]);
  const [bool, setBool] = useState(true);
  const [tabDemande, setTabDemande] = useState([]);
  const dispatch = useDispatch();
  const [filtre, setFiltrer] = useState("");
  const [search, setSearch] = React.useState("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const listDemande = useSelector(
    (state) => state.equipements.demandesEquipement
  );
  const demandesInDivision = useSelector(
    (state) => state.demandesDivision.demandesDivision
  );

  const titre = "LISTE DEMANDES EQUIPEMENT";
  const entete = [
    "N° Demande",
    "Nom",
    "Prénom(s)",
    "Téléphone",
    "Date Demande",
    "Description",
    "Apport Demande",
    "Valeur Bien",
    "Banque",
    "Durée",
    "Statut",
  ];

  useEffect(() => {
    BanqueService.getBanques().then((rest) => {
      setBanques(rest.data.filter((bank) => bank.status == "ACTIVE"));
    });

    if (AuthService.isAgent(AuthService.getCookie("role"))) {
      EquipementService.getOrderDivisionUser(AuthService.getCookie("user"))
        .then((res) => {
          dispatch(
            retrieveDemandesEquipInDivision(
              res.data
                .filter((order) => order.typeOrder == "EQUIPEMENT")
                .reverse()
            )
          );
        })
        .catch((err) => {
          throw err;
        });
    } else {
      EquipementService.getDemands().then((dmd) => {
        dispatch(
          setDataDemandesEquipement(
            dmd.data
              .filter((order) => order.typeOrder === "EQUIPEMENT")
              .reverse()
          )
        );
      });
    }
  }, [ignored]);

  const retrieveDemandesByStatus = (etat, tableData = []) => {
    if (etat === "equipement") {
      return tableData;
    } else {
      return tableData.filter((item) => item.status == etat);
    }
  };

  const expectedDemandesEquipData = (etat) => {
    if (AuthService.isAgent(AuthService.getCookie("role"))) {
      return retrieveDemandesByStatus(etat, demandesInDivision);
    } else {
      return retrieveDemandesByStatus(etat, listDemande);
    }
  };

  // CHANGE STATUS DEMANDE
  const changeDemandeStatus = (demandeID, status) => {
    EquipementService.updateStatusEquip(demandeID, status)
      .then(() => {
        dispatch(updateStatusOneDemandeClient({ demandeID, status }));
        ConsommationService.notifier(
          "success",
          `Demande  ${
            status === "ACCEPTED" ? "acceptée" : "réfusée"
          }  avec succès, Statut :${status}`,
          "erreur"
        );
        forceUpdate();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SEND EMAIL TO BANK
  const sendEmailToBank = (orderId, email) => {
    EquipementService.sendMail(orderId, email)
      .then(() => {
        dispatch(updateStatusOneDemande({ orderId, status: "SENT" }));
        dispatch(updateStatusOneDemandeClient({ orderId, status: "SENT" }));
        ConsommationService.notifier(
          "success",
          "Demande envoyée avec succèe",
          "erreur"
        );
        forceUpdate();
        setDisabled(false)
      })
      .catch((err) => {
        ConsommationService.notifier(
          "error",
          "Votre Demande a été enregistrée avec succèes",
          "Une erreur est survenue lors de l'envoi du mail, Veuillez réessayer !"
        );
        setDisabled(false)
        throw err;
      });
  };

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const page = Paginate(expectedDemandesEquipData(etat), currentPage, pageSize);

  //PAGE COURANTE
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const donnees = tabDemande.map((elt) => [
    elt.code,
    elt.customerDto.lastName,
    elt.customerDto.firstName,
    elt.customerDto.phoneNumber,
    dateFormat(elt.orderDate, "dd/mm/yyyy"),
    elt.description,
    format(elt.income) + " CFA",
    format(elt.propertyValue) + " CFA",
    elt.bankDto.name,
    elt.duration,
    elt.status,
  ]);

  const [myBool, setMyBool] = useState(true);

  function format(s) {
    return String(s).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ") + " CFA";
  }

  // FONCTION FILTER
  const functionFiltere = (criteria, filterValue) => {
    setSearch(filterValue);
    setFiltrer(criteria);
  };

  //TRIE PAR STATUS
  const trierTableByStatus = () => {
    generalService.toggle(myBool, setMyBool);
    const tab = [...store.getState().equipements.demandesEquipement];
    dispatch(
      setDataDemandesEquipement(generalService.trieTableByStatus(tab, myBool))
    );
  };

  // TRIE PAR DATE
  const trieTableByDate = (table, bool) => {
    if (bool) {
      table.sort(function (a, b) {
        return new Date(a.orderDate) - new Date(b.orderDate);
      });
    } else {
      table.sort(function (a, b) {
        return new Date(b.orderDate) - new Date(a.orderDate);
      });
    }
    return table;
  };

  // TRIER PAR DATE
  const trierListeByDate = () => {
    generalService.toggle(myBool, setMyBool);
    const tab = [...store.getState().equipements.demandesEquipement];
    dispatch(setDataDemandesEquipement(trieTableByDate(tab, myBool)));
  };

  // FILTRE LISTE DES DEMANDES
  let myArray = [];

  if (filtre === "") {
    myArray = { nodes: page };
  } else if (filtre === "Nom") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.customerDto.lastName.includes(search) ||
          item.customerDto.lastName.toLowerCase().includes(search)
      ),
    };
  } else if (filtre === "Prénom") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.customerDto.firstName.includes(search) ||
          item.customerDto.firstName.toLowerCase().includes(search)
      ),
    };
  } else if (filtre === "Banque") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.bankDto.name.includes(search) ||
          item.bankDto.name.toLowerCase().includes(search)
      ),
    };
  }

  const exportPdf = () => {
    EquipementService.exportPDF(titre, entete, donnees);
    setTabDemande([]);
  };

  const newDemande = () => {
    navigate("/client");
  };

  return (
    <>
      <NotificationContainer />
      <Container fluid>
        <Title texte={EquipementService.title(etat)} />

        <div className="row mb-3">
          <FiltreComposant
            functionFiltere={functionFiltere}
            labelCritere="Critère"
            labelFiltre="Filtre"
            listeCritere={["Nom", "Prénom", "Banque"]}
          />

          <div className="d-flex justify-content-end align-items-center col-6 ">
            <ButtonIcon
              disabled={false}
              action={newDemande}
              iconName={<FaFilePdf />}
              titre=" Nouvelle "
            />
            <ButtonIcon
              disabled={generalService.activateButton(tabDemande)}
              action={exportPdf}
              iconName={<FaFilePdf />}
              titre=" Exporter (PDF)"
            />
            <FaFileCsv
              disabled={generalService.activateButton(tabDemande)}
              data={demands}
              style={{
                fontSize: "1.5rem",
                backgroundColor: "white",
                color: "#2b4366",
              }}
            ></FaFileCsv>
            <button
              filename={"Liste demande d'equipement.csv"}
              style={{ backgroundColor: "white", border: "none" }}
            >
              <CSVLink
                style={{ backgroundColor: "white", color: "black" }}
                data={tabDemande}
              >
                {" "}
                Exporter (CSV)
              </CSVLink>
            </button>
          </div>
        </div>

        {/* </div> */}

        <Table striped bordered hover>
          <thead>
            <tr className="text-center">
              <th>
                <Checkbox
                  checked={generalService.valuesExistes(page, tabDemande)}
                  onClick={(e) => {
                    generalService.toggle(bool, setBool);
                    generalService.checkedAll(
                      e.target.checked,
                      page,
                      tabDemande,
                      setTabDemande
                    );
                  }}
                ></Checkbox>
              </th>
              <th className="align-middle">N° Demande</th>
              <th className="align-middle">N° Agent</th>

              <th className="align-middle">Nom</th>
              <th className="align-middle">Prénom(s)</th>
              <th className="align-middle">Apport</th>
              <th className="align-middle">Valeur Bien</th>
              <th className="align-middle">Banque</th>
              <th className="align-middle">Durée/Mois</th>
              <th className="align-middle">
                Statut
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title="Trier Statut (ordre alphabétique)"
                >
                  <button
                    onClick={() => {
                      trierTableByStatus();
                    }}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      fontSize: "3vh",
                    }}
                  >
                    <BiSortAZ></BiSortAZ>
                  </button>
                </MDBTooltip>
              </th>
              <th className="align-middle">Actions</th>
            </tr>
          </thead>
          <tbody>
            {myArray.nodes.map((demand) => (
              <tr key={demand.id}>
                <td className="align-middle text-center">
                  <Checkbox
                    checked={generalService.testingExistInTable(
                      demand,
                      tabDemande
                    )}
                    onClick={(e) => {
                      generalService.tabExport(
                        demand,
                        tabDemande,
                        e.target.checked,
                        setTabDemande
                      );
                    }}
                  ></Checkbox>
                </td>
                <td className="align-middle">{demand.code}</td>
                <td className="align-middle">
                  {demand.userDto != null ? demand.userDto.code : ""}
                </td>
                <td className="align-middle">{demand.customerDto.lastName}</td>
                <td className="align-middle">{demand.customerDto.firstName}</td>
                <td className="align-middle">{format(demand.amount)}</td>
                <td className="align-middle">{format(demand.propertyValue)}</td>
                <td className="align-middle">
                  {demand.bankDto !== null ? demand.bankDto.name : ""}
                </td>
                <td className="align-middle">{demand.duration}</td>
                <td className="align-middle text-center">
                  <Badge etat={demand.status} />
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-around gap-1">
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Editer Demande"
                    >
                      <Button
                        type="button"
                        className="btn btn-sm"
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                        }}
                        disabled={generalService.autorisateOperation(demand, [
                          "ACCEPTED",
                          "REFUSED",
                        ])}
                      >
                        <EditDemandeEquip
                          data={demand}
                          banks={banques}
                          onRefresh={forceUpdate}
                        />
                      </Button>
                    </MDBTooltip>
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Envoyer email"
                    >
                      <Button
                        type="button"
                        className="btn btn-sm"
                        disabled={disabled}
                        style={{
                          color: "#2b4366",
                          border: "#2b4366",
                          backgroundColor: "transparent",
                          fontSize: "1.5rem",
                        }}
                        onClick={() => {
                          sendEmailToBank(demand.id, demand.bankDto.email);
                          setDisabled(true);
                        }}
                      >
                        <IoIosSend />
                      </Button>
                    </MDBTooltip>
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Accepter Demande"
                      disabled
                    >
                      <Button
                        className="btn btn-sm"
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                          fontSize: "1.2rem",
                        }}
                        disabled={generalService.autorisateOperation(demand, [
                          "ACCEPTED",
                          "REFUSED",
                        ])}
                        onClick={() =>
                          changeDemandeStatus(demand.id, "ACCEPTED")
                        }
                      >
                        <BsFillCheckCircleFill />
                      </Button>
                    </MDBTooltip>
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Rejeter Demande"
                    >
                      <Button
                        className="btn btn-sm mx-2"
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                          fontSize: "1.2rem",
                        }}
                        disabled={generalService.autorisateOperation(demand, [
                          "ACCEPTED",
                          "REFUSED",
                        ])}
                        onClick={() =>
                          changeDemandeStatus(demand.id, "REFUSED")
                        }
                      >
                        <FaTimesCircle />
                      </Button>
                    </MDBTooltip>
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Documents"
                    >
                      <Link
                        to={`/demande/${demand.id}`}
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                          fontSize: "1.5rem",
                        }}
                      >
                        <FaFolderOpen />
                      </Link>
                    </MDBTooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          items={expectedDemandesEquipData(etat).length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  );
};

export default ListEquipements;
