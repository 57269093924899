import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../Service/UserService";
import SF_logo from "../../images/SF_logo.JPG";
import AuthService from "../../Service/AuthService";
import { BoutonComposant } from "../eng_composants/BoutonComposant";
import { Button } from "react-bootstrap";
import ConsommationService from "../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";

import styles from "./ForgetPassword.module.css";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [desabled, setDesabled] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [success, setSuccess] = useState({ isSuccess: false, message: "" });

  const [erreur, setErreur] = useState("");
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/home");
  };

  function myRedirect() {
    navigate("/");
  }

  const resetPassword = () => {
    AuthService.resetPassword(email)
      .then(() => {
        ConsommationService.notifier(
          "success",
          "Mot De Passe Réinitialisé. Consultez votre boite mail",
          "Mot De Passe Réinitialisé. Consultez votre boite mail"
        );
        setSuccess({
          isSuccess: true,
          message: "Mot De Passe Réinitialisé. Consultez votre boite mail",
        });
        AuthService.logout();
        setTimeout(() => {
          myRedirect();
        }, 2000);
      })
      .catch((rest) => {
        if (rest.response.status == 500) {
          setErreur("Email incorrecte");
        }
      });
  };

  return (
    <div className={styles.app}>
      <NotificationContainer />
      <div className={styles.loginForm}>
        <div className="d-flex justify-content-center mb-4">
          <img src={SF_logo} style={{ display: "flex", maxWidth: "40%" }} />
        </div>

        <div>
          <form>
            {erreur ? (
              <div class="alert alert-danger" role="alert">
                {erreur}
              </div>
            ) : (
              ""
            )}

            {success.isSuccess ? (
              <div className="alert alert-success" role="alert">
                {success.message}
              </div>
            ) : (
              ""
            )}
            <div className="mb-3 text-start">
              <label htmlFor="email" className="form-label fw-bold fs-6">
                Donner votre adresse mail
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="solutionflexi@gmail.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </div>
            <div className="d-flex justify-content-around">
              <Button
                disabled={(email === "" ? desabled : !desabled) || isClicked}
                type="button"
                style={{
                  backgroundColor: "#2b4366",
                  color: "white",
                  border: "none",
                }}
                onClick={() => {
                  setIsClicked(true);
                  resetPassword();
                }}
              >
                Réinitialiser
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
