
export const Router = {
    // pages
    Signin: { path: "/" },
    Signup: { path: "/user" },
    ForgetPassword: { path: "/forgetpassword" },
    ChangePassword: { path: "/password" },
    ListeUsers: { path: "/users" },
    Institution: { path: "/institution" },
    InstitutionById: { path: "/institution/:id" },
    Roles: { path: "/roles" },
    AuditListe: { path: "/audit" },
    Banks: { path: "/banks" },
    BanksById: { path: "/banks/:id" },
    Divisions: { path: "/divisions" },
    DivisionsById: { path: "/divisions/:id" },
    HomePage: { path: "home" },
    Statistique: { path: "/statistique" },
    Document: { path: "/document/:etat" },


    User: { path: "/user" },
    EquipementForm: { path: "/equip" },
    EquipementById: { path: "/equip/:id" },
    DemandeListes: { path: "/demands/:etat" },
    Clients: { path: "/clients" },
    clientForm: { path: "/client" },


    DetailDemande: { path: "/demande/:id" },
    DetailClient: { path: "/fiche/:id" },
    NotFoundPage: { path: "*" },

};