import React, { useState } from 'react';
import { FaSearch } from "react-icons/fa";
const SearchComponent = ({ documents,recherche }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filtrer les documents qui correspondent à la requête de recherche
    const results = documents.filter((document) =>
      document.type.toLowerCase().includes(query.toLowerCase())|| document.name.toLowerCase().includes(query.toLowerCase())
    );

    setSearchResults(results);
  };

  recherche(searchResults)
  return (
    <div className='d-flex'>
      <input
      style={{padding:"1rem"}}
        type="text"
        placeholder="Rechercher un document..."
        value={searchQuery}
        onChange={handleSearch}
      />
      {/* <FaSearch size={2}/> */}
    </div>
  );
};

export default SearchComponent;
