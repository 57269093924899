import axios from "axios";
import api from "./Api";
import AuthService from "./AuthService";
import ConsommationService from "./ConsommationService";
import Cookies from "js-cookie";

const URL_OFFERS = "/api/offers?page=0&size=1000&sort=";
const URL_OFFERS_POST = "/api/offers";
const URL_PRETS = "/api/loans/loan";
const URL_ALL_PRETS = "/api/loans";
const URL_LOAN_CUSTOMER = " /api/customers/loan/";
const SUSPEND_LAON = "/api/loans/suspend/";
const OPEN_LAON = "/api/loans/open/";
const LITIGATION_LAON = "/api/loans/suspend/";
const CLOSE_LAON = "/api/loans/close/";

const URL_LOAN_DETAILS = "/api/payments-loan/loan";
// const dispatch=useDispatch()

class OfferService {
  soldePret;

  createOffer = (offer) => {
    return api.post(URL_OFFERS_POST, offer, AuthService.getToken("token"));
  };

  getOffers = () => {
    return api.get(URL_OFFERS, AuthService.getToken("token"));
  };

  getPretForOneCustomer = (id) => {
    return api.get(URL_PRETS + "/" + id, AuthService.getToken("token"));
  };

  getALlPrets = () => {
    return api.get(URL_ALL_PRETS, AuthService.getToken("token"));
  };

  getOnePret = (id) => {
    return api.get(URL_ALL_PRETS + "/" + id, AuthService.getToken("token"));
  };
  getLoanCustomer(loanId) {
    return api.get(URL_LOAN_CUSTOMER + loanId, AuthService.getToken("token"));
  }

  litigateLoan = (id) => {
    return axios({
      method: "put",
      url:
        process.env.REACT_APP_TODOLIST_DATAFILE_URL +
        "/api/loans/" +
        id +
        "/status?status=LITIGATION",
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  suspendingLoan = (id) => {
    return axios({
      method: "put",
      url: process.env.REACT_APP_TODOLIST_DATAFILE_URL + SUSPEND_LAON + id,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  openLoan = (id) => {
    return axios({
      method: "put",
      url: process.env.REACT_APP_TODOLIST_DATAFILE_URL + OPEN_LAON + id,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  closeLoan = (id) => {
    return axios({
      method: "put",
      url: process.env.REACT_APP_TODOLIST_DATAFILE_URL + CLOSE_LAON + id,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  getLoanDetails = (id) => {
    return api.get(URL_LOAN_DETAILS + "/" + id, AuthService.getToken("token"));
  };

  getEcheance = (id) => {
    return api.get("/api/operations/loan/" + id, AuthService.getToken("token"));
  };

  getPayment = (id) => {
    return api.get("/api/operations/loan/" + id, AuthService.getToken("token"));
  };

  updateOffer(id, status) {
    api
      .put(
        "/api/offers/" + id + "/status?status=" + status,
        AuthService.getToken("token")
      )
      .then((objet) => {
        // window.location.reload();
      });
  }

  acceptedOffer = (id) => {
    return axios({
      method: "put",
      url:
        process.env.REACT_APP_TODOLIST_DATAFILE_URL +
        "/api/offers/accepted/" +
        id,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  refusedOffer = (id) => {
    return axios({
      method: "put",
      url:
        process.env.REACT_APP_TODOLIST_DATAFILE_URL +
        "/api/offers/refused/" +
        id,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  };
  calculation = (amount, fee, duration) => {
    return api.get(
      "/api/offers/calculation?amount=" +
        amount +
        "&duration=" +
        duration +
        "&fee=" +
        fee,
      AuthService.getToken("token")
    );
  };

  setStatusLoan = (table, etat) => {
    table.map((loan) => {
      switch (etat) {
        case "suspendre":
          this.suspendingLoan(loan.id).then(() => {
            ConsommationService.notifier(
              "success",
              "Prêt(s) Suspendu(s) avec succèes, Statut:SUSPEND",
              "erreur"
            );
            // forceUpdate()
          });
          break;
        case "fermer":
          this.closeLoan(loan.id).then(() => {
            ConsommationService.notifier(
              "success",
              "Prêt(s) Fermé(s) avec succèes, Statut:CLOSED",
              "erreur"
            );
            // forceUpdate()
          });
          break;
        case "ouvrir":
          this.openLoan(loan.id).then(() => {
            ConsommationService.notifier(
              "success",
              "Prêt(s) Ouvert(s) avec succèes, Statut:OPEN",
              "erreur"
            );
            // forceUpdate()
          });
          break;
        case "litige":
          this.litigateLoan(loan.id).then(() => {
            ConsommationService.notifier(
              "success",
              "Prêt(s) en Litige, Statut:LITIGATION",
              "erreur"
            );
            // forceUpdate()
          });
          break;
        default:
          return "Merci de verifier votre etat";
      }
    });
    table.length = 0;
  };

  title = (etat) => {
    switch (etat) {
      case "prets":
        return "LISTE PRETS";
        break;
      case "SUSPEND":
        return "LISTE PRETS SUSPENDUS";
        break;
      case "OPEN":
        return "LISTE PRETS OUVERTS";
        break;
      case "CLOSED":
        return "LISTE PRETS FERMÉS";
        break;
      case "COLLECTION":
        return "LISTE PRETS EN ATTENTES";
        break;
      case "LITIGATION":
        return "LISTE PRETS EN LITIGES";
        break;
      case "RECOVERY":
        return "LISTE PRETS EN RECOUVREMENT";
        break;
      case "DEPLAYED":
        return "LISTE PRETS EN RETARD";
        break;
      case "PAID":
        return "LISTE PRETS PAYÉS";
        break;
      case "PENDING":
        return "LISTE PRETS EN ATTENTES";
        break;
      case "offres":
        return "LISTE OFFRES";
        break;
      case "CREATED":
        return "LISTE NOUVELLES OFFRES";
        break;
      default:
      // code block
    }
  };
}
export default new OfferService();
