import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

import api from "./Api";
import AuthService from "./AuthService";
import Cookies from "js-cookie";
const EQUIPEMENT_DEMANDE_API_URL =
  "/api/orders?page=0&size=1000&sort=orderDate";
const DEMANDE_API_URL = "/api/orders";
const DEMANDE_CLIENT = "/api/customers/";

const limit = 2;

class EquipementService {
  // idClient=>Nouveau  Client
  idClient = {};

  toBool(string) {
    if (string === "true") {
      return true;
    } else if (string === "false") {
      return false;
    }
  }

  toString(bool) {
    if (bool === true) {
      return "true";
    } else if (bool === false) {
      return "false";
    }
  }

  handleNumChange = (event, fonctionName) => {
    fonctionName(event.target.value.slice(0, limit));
  };

  Capitalize(str) {
    return str.toUpperCase();
  }

  getDemands() {
    return api.get(EQUIPEMENT_DEMANDE_API_URL, AuthService.getToken("token"));
  }

  getOrderDivisionUser(idAgent) {
    return api.get(
      `/api/orders/${idAgent}/division`,
      AuthService.getToken("token")
    );
  }

  getDemandById(demandId) {
    return api.get(
      DEMANDE_API_URL + "/" + demandId,
      AuthService.getToken("token")
    );
  }

  createDemand(demand) {
    return api.post(DEMANDE_API_URL, demand, AuthService.getToken("token"));
  }

  updateDemandeEquipement(orderId, orderData) {
    return api.put(
      `${DEMANDE_API_URL}/${orderId}`,
      orderData,
      AuthService.getToken("token")
    );
  }

  updateStatusEquip(id, status) {
    return axios({
      method: "put",
      url:
        process.env.REACT_APP_TODOLIST_DATAFILE_URL +
        DEMANDE_API_URL +
        "/" +
        id +
        "/status?status=" +
        status,
      data: {},
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }).then((response) => {
      console.log(response);
    });
  }

  getClientDemande = (clientId) => {
    return api
      .get(DEMANDE_CLIENT + clientId, AuthService.getToken("token"))
      .then((rest) => {
        return rest;
        this.idClient = rest.data;
        console.log(this.idClient);
      });
  };

  exportPDF = (titre, entete, donnees) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "l"; // portrait or landscape

    const marginLeft = 60;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = titre;
    const headers = [entete];

    let data = donnees;
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("demande.pdf");
  };

  updateDemand(id, status) {
    return api.put(
      DEMANDE_API_URL + "/" + id + "/status?status=" + status,
      AuthService.getToken("token")
    );
  }

  sendMail = (orderId, emailBank) => {
    return api.post(
      `/api/send-contrat?order=${orderId}&to=${emailBank}`,
      {},
      AuthService.getToken("token")
    );
  };

  title = (etat) => {
    switch (etat) {
      case "equipement":
        return "LISTE DEMANDES";
        break;
      case "SENT":
        return "LISTE DEMANDES ENVOYÉES";
        break;
      case "ACCEPTED":
        return "LISTE DEMANDES ACCEPTÉES";
        break;
      case "REFUSED":
        return "LISTE DEMANDES REFUSÉES";
        break;
      case "CREATED":
        return "LISTE NOUVELLE DEMANDES";
        break;

      default:
      // code block
    }
  };
}

export default new EquipementService();
