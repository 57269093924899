import React, { useEffect, useReducer, useState } from "react";
import { Table } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { Paginate } from "../Utils/Paginate";
import Pagination from "../Pagination/Pagination";
import { MDBTooltip } from "mdb-react-ui-kit";
import DivisionModal from "../Modals/DivisionModal";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import EditDivision from "../Modals/EditDivision";
import Badge from "../Badge/Badge";
import FiltreComposant from "../ComposantsElementaire/FiltreComposant";
import { FaCheckCircle } from "react-icons/fa";
import { BsXCircleFill } from "react-icons/bs";
import { AiFillFileAdd } from "react-icons/ai";
import generalService from "../../Service/generalService";
import DivisionService from "../../Service/DivisionService";
import InstitutionService from "../../Service/InstitutionService";
import ButtonIcon from "../eng_composants/ButtonIcon";
import Title from "../eng_composants/Title";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions, updateStatusDivision } from "../../redux/redux";
import ConsommationService from "../../Service/ConsommationService";

function ListesDivisions() {
  const [search, setSearch] = React.useState("");
  const [filtre, setFiltrer] = useState("");
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 1);

  const [tabClient, setTabClient] = useState([]);
  const [institution, setInstitution] = useState([]);
  const [myBool, setMyBool] = useState(false);
  const dispatch = useDispatch();
  const listeDivision = useSelector((state) => state.divisions.divisions);

  useEffect(() => {
    DivisionService.getDivisions().then((div) => {
      dispatch(getDivisions(div.data.reverse()));
    });

    InstitutionService.getInstitution().then((rest) => {
      setInstitution(rest.data);
    });
  }, [ignored]);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const page = Paginate(listeDivision, currentPage, pageSize);

  const handleOpenModal = () => setOpenModal(true);

  function format(s) {
    return String(s).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ") + " CFA";
  }

  // FONCTION FILTER
  const functionFiltere = (criteria, filterValue) => {
    setSearch(filterValue);
    setFiltrer(criteria);
  };

  let data = null;
  if (filtre === "") {
    data = { nodes: page };
  } else if (filtre === "Nom") {
    data = {
      nodes: page.filter(
        (item) =>
          item.name.toLowerCase().includes(search) || item.name.includes(search)
      ),
    };
  } else if (filtre === "Adresse") {
    data = {
      nodes: page.filter(
        (item) =>
          item.location.toLowerCase().includes(search) ||
          item.location.includes(search)
      ),
    };
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const divisionStatus = (tab, etat) => {
    tab.map((bank) => {
      DivisionService.changeStatus(bank.id, etat).then(() => {
        if (etat == "ACTIVE") {
          dispatch(updateStatusDivision({ id: bank.id, status: "ACTIVE" }));
        } else {
          dispatch(updateStatusDivision({ id: bank.id, status: "INACTIVE" }));
        }

        forceUpdate();
      });
    });
    ConsommationService.notifier(
      "success",
      "Division(s)  " + etat + "  avec succès, Statut : " + etat,
      "erreur"
    );
    setTabClient([]);
  };

  const activer = () => {
    divisionStatus(tabClient, "ACTIVE");
  };

  const desactiver = () => {
    divisionStatus(tabClient, "INACTIVE");
  };
  return (
    <>
      <Title texte="LISTE BOUTIQUES" />

      <div className="row mb-3">
        <FiltreComposant
          functionFiltere={functionFiltere}
          labelCritere="Critère"
          labelFiltre="Filtre"
          listeCritere={["Nom", "Adresse"]}
        />

        <div className="d-flex justify-content-end align-items-center col-6">
          <DivisionModal
            isOpen={openModal}
            onClose={setOpenModal}
            onRefresh={forceUpdate}
            selectedData={institution}
          />
          <ButtonIcon
            disabled={false}
            action={handleOpenModal}
            iconName={<AiFillFileAdd />}
            titre="Nouvelle"
          />
          <ButtonIcon
            disabled={false}
            action={activer}
            iconName={<FaCheckCircle />}
            titre="Activer"
          />
          <ButtonIcon
            disabled={false}
            action={desactiver}
            iconName={<BsXCircleFill />}
            titre="Désactiver"
          />
        </div>
      </div>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th className="text-center">
              <Checkbox
                checked={generalService.valuesExistes(page, tabClient)}
                onClick={(e) => {
                  generalService.toggle(myBool, setMyBool);
                  generalService.checkedAll(
                    e.target.checked,
                    page,
                    tabClient,
                    setTabClient
                  );
                }}
              ></Checkbox>
            </th>
            <th className="align-middle">N° Boutique</th>
            <th className="align-middle">Nom</th>
            <th className="align-middle">Adresse</th>
            <th className="align-middle">Email</th>
            <th className="align-middle">Téléphone</th>
            <th className="align-middle">Budget</th>
            {/* <th className="align-middle">Intérêt(%)</th>
            <th className="align-middle">Retard Grâce</th> */}
            {/* <th className="align-middle">Recouvrement Grâce</th> */}
            {/* <th className="align-middle">N° Institution</th> */}
            <th className="align-middle">Institution</th>
            <th className="align-middle text-center">Statut</th>
            <th className="align-middle text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.nodes.map((div) => (
            <tr key={div.id}>
              <td className="text-center">
                <Checkbox
                  checked={generalService.testingExistInTable(div, tabClient)}
                  onClick={(e) => {
                    generalService.tabExport(
                      div,
                      tabClient,
                      e.target.checked,
                      setTabClient
                    );
                  }}
                ></Checkbox>
              </td>
              <td className="align-middle">{div.code}</td>
              <td className="align-middle">{div.name}</td>
              <td className="align-middle">{div.location}</td>
              <td className="align-middle">{div.email}</td>
              <td className="align-middle">
                {formatPhoneNumberIntl(div.phoneNumber)}
              </td>
              <td className="align-middle">{format(div.budget)}</td>
              {/* <td className="align-middle">{div.interestRate}</td>
              <td className="align-middle">{div.latenessGracePeriod}</td> */}
              {/* <td className="align-middle">{div.collectionGracePeriod}</td> */}
              {/* <td className="align-middle">{div.lender.code}</td> */}
              <td className="align-middle">{div.lender.name}</td>
              <td className="align-middle text-center">
                <Badge etat={div.status}></Badge>
              </td>
              <td className="align-middle text-center">
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title={"Editer Banque"}
                >
                  <EditDivision
                    data={div}
                    onRefresh={forceUpdate}
                    selectedData={institution}
                  />
                </MDBTooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        items={listeDivision.length}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export default ListesDivisions;
