import dateformat from "dateformat";

class generalService {
  // List des etats des clients a priver de damandes
  tabEtat = ["INACTIVE", "REJECTED", "BLOCKED", "PENDING"];

  // List des etats des Pret a priver de damandes
  tabEtatLoan = ["INACTIVE", "REJECTED", "BLOCKED", "PENDING"];

  // List des etats des Offre a priver de damandes
  tabEtatOffer = ["INACTIVE", "REJECTED", "BLOCKED", "PENDING"];

  documentClient = [];
  isVide = (tableau) => {
    return tableau.length == 0;
  };

  // Activation des operarions globales
  activateButton = (tab) => {
    return !!this.isVide(tab);
  };

  // Autorisation d'un operation
  autorisateOperation = (element, table = []) => {
    return table.indexOf(element.status) !== -1;
  };

  // Supprime un element dans un tableau
  deleteElement = (id, tab, setter) => {
    const tabCopie = tab.slice();
    tabCopie.filter((item) => item.id !== id);
    setter(tabCopie.filter((item) => item.id !== id));
  };

  sup = (objet, tab) => {
    for (let index = 0; index < tab.length; index++) {
      if (objet.id == tab[index]) {
        delete tab[index];
      }
    }
  };

  // Test si un element existe dans un tableau
  testingExistInTable = (element, tab) => {
    let inTable = false;
    for (let index = 0; index < tab.length; index++) {
      if (element.id == tab[index].id) {
        inTable = !inTable;
      } else {
        inTable = inTable;
      }
    }
    return inTable;
  };

  // Test si le role existe dans un tableau
  roleInTable = (element, tab) => {
    let inTable = false;
    for (let index = 0; index < tab.length; index++) {
      if (element == tab[index]) {
        inTable = !inTable;
      } else {
        inTable = inTable;
      }
    }
    return inTable;
  };

  // Ajoute un element dans un tableau
  addElement = (objet, tab, setter) => {
    const tabCopie = [...tab];
    if (this.testingExistInTable(objet, tabCopie) == false) {
      tabCopie.push(objet);
    } else {
      //    alert("cet objet a été deja selectionné");
    }
    setter(tabCopie);
  };
  // ************************************************

  // SUPPRIMER LES DUPLICATION DANS DEUX TABLEAU
  remove_duplicates(a, b) {
    b = b.filter(function (item) {
      for (let i = 0, len = a.length; i < len; i++) {
        if (a[i].id == item.id) {
          return false;
        }
      }
      return true;
    });
    return b;
  }
  // RECUPERATION DES ELEMENTS COMMUN
  duplicates(a, b) {
    b = b.filter(function (item) {
      for (let i = 0, len = a.length; i < len; i++) {
        if (a[i].id == item.id) {
          return true;
        }
      }
      return false;
    });
    return b;
  }

  // OPTION SELECTION MULTIPLE
  checkedAll = (checked, tab1, tab2, func) => {
    // let tabb
    if (checked == true) {
      tab1.forEach((element) => {
        if (!this.testingExistInTable(element, tab2)) {
          tab2.push(element);
        }
      });
      func(tab2);
    } else {
      func(this.remove_duplicates(tab1, tab2));
    }
  };

  // Teste pret Close
  checkedAllNotStatus = (checked, tab1, tab2, func, tableauStatus) => {
    // let tabb
    if (checked == true) {
      tab1.forEach((element) => {
        if (
          !this.testingExistInTable(element, tab2) &&
          this.autorisateOperation(element, tableauStatus) == false
        ) {
          tab2.push(element);
        }
        // console.log(tab2);
      });
      func(tab2);
    } else {
      func(this.remove_duplicates(tab1, tab2));
    }
  };

  // CHECKED OR NOT CHECKED OPTION SELECT ALL
  valuesExistes = (tab1, tab2) => {
    let val = false;
    // const taille=10
    if (tab1.length == 0 || this.duplicates(tab1, tab2).length < tab1.length) {
      val = val;
    } else {
      val = !val;
    }
    return val;
  };

  // Ajout ou suppression d'un element dans un tableau si la case cochée
  tabExport = (objet, tab, isChecked, setter) => {
    if (isChecked == true) {
      this.addElement(objet, tab, setter);
    } else if (isChecked == false) {
      this.deleteElement(objet.id, tab, setter);
    }
  };

  toggle = (myBool, setMyBool) => {
    setMyBool(!myBool);
  };

  trieTable = (table, bool) => {
    if (bool) {
      table.sort(function (a, b) {
        return new Date(a.orderDate) - new Date(b.orderDate);
      });
    } else {
      table.sort(function (a, b) {
        return new Date(b.orderDate) - new Date(a.orderDate);
      });
    }
    return table;
  };

  trieTableByStatus = (table, bool) => {
    if (bool) {
      table.sort(function (b, a) {
        return a.status.localeCompare(b.status);
      });
    } else {
      table.sort(function (a, b) {
        return a.status.localeCompare(b.status);
      });
    }
    return table;
  };

  filterTable = (table) => {
    return table.filter((element, i) => i === table.indexOf(element));
  };

  alertMessage(string, attribut, limit) {
    if (attribut.length == limit) {
      return string;
    }
  }

  alertMessageValeurBien(string, mt1, mt2) {
    if (mt1 > mt2) {
      return string;
    }
  }

  testMontantApport = (mt1, mt2) => {
    return mt1 > mt2;
  };

  handleNumChange = (event, fonctionName, limit) => {
    fonctionName(event.target.value.slice(0, limit));
  };

  // CALCUL MONTANT MENSUELI
  pmt(rate_per_period, number_of_payments, present_value, future_value, type) {
    if (rate_per_period != 0.0) {
      // Interest rate exists
      var q = Math.pow(1 + rate_per_period, number_of_payments);
      return (
        (rate_per_period * (future_value + q * present_value)) /
        ((-1 + q) * (1 + rate_per_period * type))
      );
    } else if (number_of_payments != 0.0) {
      // No interest rate, but number of payments exists
      return (future_value + present_value) / number_of_payments;
    }

    return 0;
  }

  // CALCUL AGE
  getAge(date) {
    let diff = Date.now() - Date.parse(date);
    let age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }

  // VERIFICATION S'IL A 18 ANS
  verifAge(age) {
    return age < 18;
  }

  // Verifier si deux tableau sont identique
  arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length == b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  //   Tableau d'id d'objets
  getTableId = (tab1) => {
    const myTable = [];
    for (const element of tab1) {
      myTable.push(element.id);
    }
    return myTable;
  };

  //   Tableau des MOntant d'objets
  getTableMontant = (tab1) => {
    const myTable = [];
    for (const element of tab1) {
      myTable.push(element.amount);
    }
    return myTable;
  };

  sommeTableau(tableau) {
    let somme = 0;
    for (let i = 0; i < tableau.length; i++) {
      somme += tableau[i];
    }
    return somme;
  }

  //Test chaine vide
  isChaineVide = (string) => {
    let vide = false;
    if (string == "") {
      vide = !vide;
    } else {
      vide = vide;
    }
    return vide;
  };

  firstStringUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  formateMontant = (montant) => {
    const rawValue = montant.replace(/\s+/g, "");
    const parsedValue = parseInt(rawValue.replace(/\D/g, ""));
    const formattedValue = isNaN(parsedValue)
      ? ""
      : parsedValue.toLocaleString();
    return formattedValue;
  };

  unformatSalaire(salaire) {
    // Supprimer les espaces existants dans la valeur formatée
    const rawValue = salaire.replace(/\s+/g, "");

    // Vérifier si la valeur est vide ou non
    if (rawValue === "") {
      return "";
    }
    // Convertir la valeur en nombre
    const numericValue = parseInt(rawValue, 10);
    return numericValue;
  }

  formatDate = (dateValue) => {
    const date = new Date(parseInt(dateValue) * 1000);

    const formatedDate = dateformat(date, "dd/mm/yyyy");
    return formatedDate;
  };

  formatAmount = (amount) => {
    return (
      String(amount).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ") + " CFA"
    );
  };

  checkUnicityEmail = (givenEmail, emailTable = []) => {
    if (emailTable.findIndex(givenEmail) != -1) {
      return "Email existe déja.";
    } else {
      return "";
    }
  };

  checkUnicityPhone = (givenPhone, phoneTable = []) => {
    for (const element of phoneTable) {
      if (element === givenPhone) {
        return "Numéro Téléphone existe déja.";
      } else {
        return "";
      }
    }
  };

  // Active button operations
  isActivatedButton = (selectedTableData = []) =>
    selectedTableData.length === 0;

  // check if element is checked
  isSelectedElement = (element, selectedTableData = []) =>
    selectedTableData.indexOf(element) !== -1;

  //  check if all element in page is checked
  isSelectedAll = (currentPageData = [], selectedTableData = []) =>
    currentPageData.length > 0 &&
    selectedTableData.length === currentPageData.length;

  isIndeterminate = (currentPageData = [], selectedTableData = []) =>
    selectedTableData.length > 0 &&
    selectedTableData.length < currentPageData.length;

  // select element
  selectedClick = (isChecked, element, tableData, onUpdateSelected) => {
    const selectedIndex = tableData.indexOf(element);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(tableData, element);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(tableData.slice(1));
    } else if (selectedIndex === tableData.length - 1) {
      newSelected = newSelected.concat(tableData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        tableData.slice(0, selectedIndex),
        tableData.slice(selectedIndex + 1)
      );
    }

    onUpdateSelected(newSelected);
  };

  // Check all elements in the current page
  selectedAllElementsInPage = (
    isChecked,
    currentPageData,
    selectedTableData,
    onUpdateSelected
  ) => {
    if (isChecked === true) {
      selectedTableData = currentPageData;
      onUpdateSelected(selectedTableData);
      return;
    }
    onUpdateSelected([]);
  };
}

export default new generalService();
