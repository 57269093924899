import React, { useState } from "react";
import { NotificationContainer } from "react-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import InputOutline from "../Inputs/InputOutline";
import { Form } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import DivisionService from "../../Service/DivisionService";
import ConsommationService from "../../Service/ConsommationService";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { updateDivision } from "../../redux/redux";
import InstitutionService from "../../Service/InstitutionService";

export default function EditDivision({ data, onRefresh, selectedData }) {
  const [show, setShow] = useState(false);
  const [lenderError, setLenderError] = useState({
    isError: false,
    message: "",
  });
  const [lender, setLender] = useState({
    id: data.lender.id,
    name: data.lender.name,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();

  function checkLenderRequired(lender = {}) {
    if (lender === null) {
      setLenderError({ isError: true, message: "Institution est requis." });
    } else {
      setLenderError({ isError: false, message: "" });
    }
  }

  function handleInstitutionChange(e) {
    setLender({
      id: e.target.value,
    });
    checkLenderRequired(lender);
  }

  const formik = useFormik({
    initialValues: {
      id: data.id,
      code: data.code,
      name: data.name,
      location: data.location,
      email: data.email,
      phoneNumber: data.phoneNumber,
      budget: data.budget,
      interestRate: data.interestRate,
      status: data.status,
      latenessGracePeriod: data.latenessGracePeriod,
      collectionGracePeriod: data.collectionGracePeriod,
      lender: {
        id: data.lender.id,
      },
    },
    onSubmit: (values) => {
      const divisionData = {
        ...values,
      };

      DivisionService.editDivision(divisionData, divisionData.id)
        .then(() => {
          dispatch(updateDivision(divisionData));
          ConsommationService.notifier(
            "success",
            "Division editée avec succèes",
            "erreur"
          );
          handleClose();
          onRefresh();
        })
        .catch((err) => {
          console.log(err);
          ConsommationService.notifier(
            "error",
            "Division enregistrée avec succèes",
            "Une erreur est survenue lors de la création de la division, Veuillez réessayer !"
          );
        });
    },
  });

  return (
    <>
      <NotificationContainer />

      <button
        style={{ backgroundColor: "transparent", border: "none" }}
        onClick={handleShow}
      >
        <FiEdit
          style={{
            backgroundColor: "transparent",
            fontSize: "1.5rem",
          }}
        ></FiEdit>
      </button>

      <MDBModal show={show} setShow={setShow} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>EDITER DIVISION</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={handleClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <FormControl fullWidth size="small">
                    <InputLabel htmlFor="institution">
                      Institution
                    </InputLabel>
                    <Select
                      className="text-start"
                      id="institution"
                      name="lender"
                      type="text"
                      value={lender.id}
                      label="Institution"
                      onChange={handleInstitutionChange}
                      error={Boolean(lenderError.isError)}
                      helperText={
                        Boolean(lenderError.isError) && lenderError.message
                      }
                    >
                      {selectedData.map((option) => (
                        <MenuItem key={option.value} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <InputOutline
                  name="name"
                  value={formik.values.name}
                  type="text"
                  func={formik.handleChange}
                  label="Nom"
                />
                <InputOutline
                  name="email"
                  value={formik.values.email}
                  type="text"
                  func={formik.handleChange}
                  label="Email"
                />
                <InputOutline
                  name="location"
                  value={formik.values.location}
                  type="text"
                  func={formik.handleChange}
                  label="Adresse"
                />
                <InputOutline
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  type="text"
                  func={formik.handleChange}
                  label="Téléphone"
                  limit={13}
                />
                <InputOutline
                  name="budget"
                  value={formik.values.budget}
                  type="number"
                  func={formik.handleChange}
                  label="Budget"
                  limit={9}
                />
                {/* <InputOutline
                  name="interestRate"
                  value={formik.values.interestRate}
                  type="number"
                  func={formik.handleChange}
                  limit={2}
                  label="Intérêt(%)"
                />
                <InputOutline
                  name="collectionGracePeriod"
                  value={formik.values.collectionGracePeriod}
                  type="number"
                  func={formik.handleChange}
                  limit={2}
                  label="Recouvrement Grâce"
                />
                <InputOutline
                  name="latenessGracePeriod"
                  value={formik.values.latenessGracePeriod}
                  type="number"
                  func={formik.handleChange}
                  limit={2}
                  label="Retard Grâce"
                /> */}

                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-dark m-1"
                    type="button"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="btn m-1"
                    style={{
                      backgroundColor: "#2b4366",
                      border: "#2b4366",
                      color: "white",
                    }}
                  >
                    Enregistrer
                  </button>
                </div>
              </Form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
