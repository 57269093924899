import React, { useEffect, useState } from "react";
import { MDBContainer, MDBNavbar } from "mdb-react-ui-kit";
import SF_logo from "../../images/SF_logo.JPG";

import "./NavBar.css";
import UserProfile from "../ComposantsElementaire/UserProfile";
import Crediz from "../eng_composants/Crediz";
import Conteur from "../eng_composants/Conteur";
import { store } from "../../redux/redux";
import { Link } from "react-router-dom";

export default function NavBar({ myArray }) {
  return (
    <header fixed className="mb-3">
      <MDBNavbar
        expand="lg"
        style={{ backgroundColor: "#b5e0f0", height: "3rem" }}
        light
        bgColor=""
        fixed="top"
      >
        <MDBContainer fluid>
          <Crediz name="SOLUTIONFLEXI" logo={SF_logo} url="/home" />
          <div
            style={{ backgroundColor: "#b5e0f0", width: "100%" }}
            className="p-1 d-flex justify-content-around align-items-center align-middle"
          >
            {myArray.map((item) => (
              <Link
                to={item.lien}
                style={{ textDecoration: "none", color: "#2b4366" }}
              >
                <Conteur titre={item.tache} nombre={item.nombre} />
              </Link>
            ))}
          </div>

          <UserProfile />
        </MDBContainer>
      </MDBNavbar>
    </header>
  );
}
