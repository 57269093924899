import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import InputOutline from "../Inputs/InputOutline";
import ConsommationService from "../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";
import { Form } from "react-bootstrap";
import BanqueService from "../../Service/BanqueService";
import { AiFillFileAdd } from "react-icons/ai";
import { addBank } from "../../redux/redux";
import ButtonIcon from "../eng_composants/ButtonIcon";

export default function BankModal() {
  const [varyingModal, setVaryingModal] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      location: "",
      code: "BANK",
    },
    onSubmit: async (values) => {
      const bankData = {
        ...values,
        status: "ACTIVE",
      };

      BanqueService.createBank(bankData)
        .then((res) => {
          dispatch(addBank(res.data));
          ConsommationService.notifier(
            "success",
            "Votre Banque a été enregistrée avec succèes",
            "erreur"
          );
          handleClose();
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Votre Banque a été enregistrée avec succèes",
            "Une erreur est survenue lors de la création de la Banque, Veuillez réessayer !"
          );
          console.log(err);
        });
    },
  });

  return (
    <>
      <div className="col-1">
        <NotificationContainer />
      </div>
      <ButtonIcon
        disabled={false}
        action={handleShow}
        iconName={<AiFillFileAdd />}
        titre=" Nouvelle"
      />
      <MDBModal show={open} setShow={setOpen} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>CREER BANQUE</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={handleClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Form onSubmit={formik.handleSubmit}>
                <InputOutline
                  name="name"
                  value={formik.values.name}
                  type="text"
                  func={formik.handleChange}
                  label="Nom Banque"
                />
                <InputOutline
                  name="email"
                  value={formik.values.email}
                  type="text"
                  func={formik.handleChange}
                  label="Email"
                />
                <InputOutline
                  name="location"
                  value={formik.values.location}
                  type="text"
                  func={formik.handleChange}
                  label="Adresse"
                />
                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-dark m-1"
                    type="button"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    disabled={!formik.isValid}
                    className="btn m-1"
                    style={{
                      backgroundColor: "#2b4366",
                      border: "#2b4366",
                      color: "white",
                    }}
                  >
                    Enregistrer
                  </button>
                </div>
              </Form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
