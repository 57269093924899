
import api from "./Api";
import AuthService from "./AuthService";

const BANQUE_API_URL = '/api/references/banks';
const OPERATEUR_URL = "/api/references/operators";
const DIVISION_API_URL = '/api/divisions';


class banqueService {
  message = "Banque enregistrée avec succèes";

  getBanques() {
    return api.get(BANQUE_API_URL, AuthService.getToken("token"));
  }


  getBanqueById(id) {
    return api.get(BANQUE_API_URL + "/"+ id, AuthService.getToken("token"));
  }
  createBank(bank) {
    return api.post(BANQUE_API_URL, bank, AuthService.getToken("token"));
  }

  editBank(objet, id) {
    return api
      .put(BANQUE_API_URL + "/" + id, objet, AuthService.getToken("token"))
      .then((objet) => {});
  }

  updateBankStatus(bank, status) {
    return api
      .put(
        BANQUE_API_URL + "/" + bank.id,
        {
          code: bank.code,
          location: bank.location,
          email: bank.email,
          name: bank.name,
          status: status,
        },
        AuthService.getToken("token")
      )
      .then((objet) => {
        console.log(objet.data);
      });
  }

  getAllOperators() {
    return api.get(OPERATEUR_URL, AuthService.getToken("token"));
  }

  createOperator(div) {
    return api.post(OPERATEUR_URL, div, AuthService.getToken("token"));
  }

  editOpeartor(objet, id) {
    return api
      .put(OPERATEUR_URL + "/" + id, objet, AuthService.getToken("token"))
      .then((objet) => {});
  }
}

export default new banqueService();
