import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import EquipementService from '../Service/EquipementService';
import generalService from '../Service/generalService';



function getMonthNumber(date) {
  return date.getMonth() + 1;
}


function getRequestsByMonth(requests, month) {
  const filteredRequests = requests.filter(request => {
    const requestMonth = new Date(request.orderDate
).getMonth() + 1;
    return requestMonth === month;
  });
  return filteredRequests;
}





const MoisChart = () => {
  const [donnees,setDonnees]=useState([])
  useEffect(()=>{
    EquipementService.getDemands().then((data)=>{
      setDonnees(data.data)
  
    })
  
  },[])

  console.log('====================================');
console.log();
console.log('====================================');


  const data = [
    { month: 'Janvier', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 1)) ) },
    { month: 'Février', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 2)) ) },
    { month: 'Mars', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 3)) ) },
    { month: 'Avril', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees,4 )) ) },
    { month: 'Mai', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 5)) ) },
    { month: 'Juin', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 6)) ) },
    { month: 'Juillet', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 7)) ) },
    { month: 'Août', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 8)) ) },
    { month: 'Septembre', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 9)) ) },
    { month: 'Octobre', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 10)) )},
    { month: 'Novembre', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 11)) ) },
    { month: 'Décembre', Montant: generalService.sommeTableau(generalService.getTableMontant(getRequestsByMonth(donnees, 12)) ) },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload.month}`}</p>
          <p className="label">{`Montant: ${payload[0].payload.Montant}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <BarChart
      width={600}
      height={300}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Legend />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="Montant" fill="#8884d8" label={{ position: 'top' }} />
    </BarChart>
  );
};

export default MoisChart;
