import { configureStore, createSlice } from "@reduxjs/toolkit";
// import bankReducer from "../references/bank/BankSlice";

const AuthenticatedUserSlice = createSlice({
  name: "auth-user",
  initialState: {
    authUser: {
      id: "",
      role: "",
      firstName: "",
      lastName: "",
      divisionDto: {
        id: "",
        code: "",
        name: "",
      },
    },
  },
  reducers: {
    saveCurrentUserData: (state, { payload }) => {
      if (payload !== null) {
        state.authUser = {
          id: payload.id,
          role: payload.role,
          firstName: payload.firstName,
          lastName: payload.lastName,
          divisionDto: {
            id: payload.divisionDto.id,
            name: payload.divisionDto.name,
            code: payload.divisionDto.code,
          },
        };
      }
    },
  },
});

// GESTION DES BANQUES
const gestionDesBanks = createSlice({
  name: "banque",
  initialState: {
    Banks: [],
  },
  reducers: {
    //Ces fonctions recevrons l'etat actuel et l'action a effectuer
    //Ajouter une bank
    addBank: (state, action) => {
      state.Banks.push(action.payload);
    },

    //Modifier une bank
    updateBank: (state, { payload }) => {
      state.Banks.map((bank) => {
        if (bank.id === payload.id) {
          return {
            ...bank,
            name: payload.name,
            location: payload.location,
            email: payload.email,
          };
        }
      });
    },

    // Recuperation des données
    setDataBank: (state, { payload }) => {
      state.Banks = payload;
    },
    updateStatusBank: (state, action) => {
      state.Banks = state.Banks.map((bank) => {
        if (bank.id === action.payload[1]) {
          return {
            ...bank,
            status: action.payload[0],
          };
        } else {
          return bank;
        }
      });
    },
  },
});

//GESTION DES DEMANDES
const gestionDesDemandes = createSlice({
  name: "demandeConsomation",
  initialState: {
    demandes: [],
  },
  reducers: {
    addDemande: (state, action) => {
      state.demandes.push(action.payload);
    },

    //Modifier une bank
    updateDemande: (state, action) => {
      state.demandes = state.demandes.map((demande) => {
        if (demande.id === action.payload[1]) {
          return {
            ...demande,
            amount: action.payload[0].amount,
            address: action.payload[0].address,
            sectorActivity: action.payload[0].sectorActivity,
          };
        } else {
          return demande;
        }
      });
    },

    // Recuperation des données
    setDataDemandes: (state, { payload }) => {
      state.demandes = payload;
    },

    updateStatusOneDemandeConso: (state, action) => {
      state.demandes = state.demandes.map((demande) => {
        if (demande.id === action.payload[1]) {
          return {
            ...demande,
            status: action.payload[0],
          };
        } else {
          return demande;
        }
      });
    },
  },
});

//GESTION DES DEMANDES EQUIPEMENTS
const gestionDesDemandesEquipement = createSlice({
  name: "demandesEquipement",
  initialState: {
    demandesEquipement: [],
  },
  reducers: {
    addDemandeEquipement: (state, action) => {
      state.demandesEquipement.push(action.payload);
    },

    //Modifier une bank
    updateDemandeEquipement: (state, { payload }) => {
      state.demandesEquipement.map((demande) => {
        if (demande.id === payload.id) {
          return {
            ...demande,
            description: payload.description,
            propertyValue: payload.propertyValue,
            amount: payload.amount,
            includeInsurance: payload.includeInsurance,
            duration: payload.duration,
            remboursement: payload.remboursement,
            contrat: payload.contrat,
            salary: payload.salary,
            currentCredit: payload.currentCredit,
            income: payload.income,
            employer: payload.employer,
            identity: payload.identity,
            bankDto: payload.bankDto,
            customerDto: payload.customerDto,
          };
        }
      });
    },

    // Recuperation des données
    setDataDemandesEquipement: (state, { payload }) => {
      state.demandesEquipement = payload;
    },

    //Update Statust
    updateStatusOneDemande: (state, { payload }) => {
      state.demandesEquipement.map((demande) => {
        if (demande.id === payload.id) {
          return {
            ...demande,
            status: payload.status,
          };
        }
      });
    },
  },
});

const demandesInDivisionSlice = createSlice({
  name: "demandesDivision",
  initialState: {
    demandesDivision: [],
  },
  reducers: {
    retrieveDemandesEquipInDivision: (state, { payload }) => {
      state.demandesDivision = payload;
    },
  },
});

//GESTION DES DEMANDES EQUIPEMENTS client
const gestionDesDemandesClient = createSlice({
  name: "demandesClient",
  initialState: {
    demandesEquipementClient: [],
  },
  reducers: {
    addDemandeEquipementClient: (state, action) => {
      state.demandesEquipementClient.push(action.payload);
    },

    //Modifier une bank
    updateDemandeEquipementClient: (state, { payload }) => {
      state.demandesEquipementClient.map((demande) => {
        if (demande.id === payload.id) {
          return {
            ...demande,
            description: payload.description,
            propertyValue: payload.propertyValue,
            amount: payload.amount,
            includeInsurance: payload.includeInsurance,
            duration: payload.duration,
            remboursement: payload.remboursement,
            contrat: payload.contrat,
            salary: payload.salary,
            currentCredit: payload.currentCredit,
            income: payload.income,
            employer: payload.employer,
            identity: payload.identity,
            bankDto: payload.bankDto,
            customerDto: payload.customerDto,
          };
        } else {
          return demande;
        }
      });
    },

    // Recuperation des données
    setDataDemandesEquipementClient: (state, { payload }) => {
      state.demandesEquipementClient = payload;
    },

    //Update Statust
    updateStatusOneDemandeClient: (state, { payload }) => {
      state.demandesEquipementClient.map((demande) => {
        if (demande.id === payload.id) {
          return {
            ...demande,
            status: payload.status,
          };
        }
      });
    },
  },
});

// GESTION DES CLIENTS
const gestionDesClients = createSlice({
  name: "customer",
  initialState: {
    customers: [],
  },

  reducers: {
    addCustomer: (state, action) => {
      state.customers.push(action.payload);
    },
    updateClient: (state, { payload }) => {
      state.customers.map((customer) => {
        if (customer.id === payload.id) {
          return {
            ...customer,
            firstName: payload.firstName,
            lastName: payload.lastName,
            phoneNumber: payload.phoneNumber,
            birthday: payload.birthday,
            code: payload.code,
            gender: payload.gender,
            email: payload.email,
            job: payload.job,
            jobDuration: payload.jobDuration,
            activitySector: payload.activitySector,
            activityLocation: payload.activityLocation,
            status: payload.status,
          };
        }
      });
    },

    updateStatusCustomer: (state, { payload }) => {
      state.customers.map((client) => {
        if (client.id === payload.id) {
          return {
            ...client,
            status: payload.status,
          };
        }
      });
    },
    getAllCustomers: (state, { payload }) => {
      state.customers = payload;
    },
  },
});

// GESTION DES DEMANDES ET DES PRETS D'UN CLIENT
const gestionOneCustomer = createSlice({
  name: "customer",
  initialState: {
    myEquipement: [],
    myConsommation: [],
    myPret: [],
  },
  reducers: {
    // Recuperation des demandes equipements
    getAllEquipementByClient: (state, { payload }) => {
      state.myEquipement = payload;
    },

    // Recuperation des demandes consomation
    getAllConsommationByClient: (state, { payload }) => {
      state.myConsommation = payload;
    },

    // Recuperation des Prets
    getAllPretByClient: (state, { payload }) => {
      state.myPret = payload;
    },
  },
});

//GESTION DES OFFRES
const gestionDesOffres = createSlice({
  name: "offre",
  initialState: {
    offres: [],
  },

  reducers: {
    addOffre: (state, action) => {
      state.offres.push(action.payload);
    },
    updateOffre: () => {},
    updateStatusOffre: (state, action) => {
      state.offres = state.offres.map((offre) => {
        if (offre.id === action.payload[1]) {
          return {
            ...offre,
            status: action.payload[0],
          };
        } else {
          return offre;
        }
      });
    },
    getAllOffres: (state, { payload }) => {
      state.offres = payload;
    },
  },
});

//GESTION DES OFFRES
const gestionDesPrets = createSlice({
  name: "pret",
  initialState: {
    prets: [],
  },

  reducers: {
    addPret: () => {},
    updatePret: (state, action) => {
      state.prets = state.prets.map((pret) => {
        if (pret.id === action.payload[1]) {
          return {
            ...pret,
            balance: action.payload[0],
          };
        } else {
          return pret;
        }
      });
    },

    updateStatusPret: (state, action) => {
      state.prets = state.prets.map((pret) => {
        if (pret.id === action.payload[1]) {
          return {
            ...pret,
            status: action.payload[0],
          };
        } else {
          return pret;
        }
      });
    },

    getAllPret: (state, { payload }) => {
      state.prets = payload;
    },
  },
});

// GESTION DES DOUMENTS
const gestionDesDocuments = createSlice({
  name: "documents",
  initialState: {
    documents: [],
  },
  reducers: {
    //Ces fonctions recevrons l'etat actuel et l'action a effectuer
    //Ajouter une bank
    addDocument: (state, action) => {
      state.documents.push(action.payload);
    },

    // Recuperation des données
    getAllDocuments: (state, { payload }) => {
      state.documents = payload;
    },

    updateStatusDocument: (state, { payload }) => {
      state.documents.map((doc) => {
        if (doc.id === payload.id) {
          return {
            ...doc,
            status: payload.status,
          };
        }
      });
    },
  },
});

//DOCUMENT DES CLIENTS
const DocumentsClient = createSlice({
  name: "documentsClient",
  initialState: {
    documentsClient: [],
  },
  reducers: {
    //Ces fonctions recevrons l'etat actuel et l'action a effectuer
    //Ajouter une bank
    saveDocument: (state, action) => {
      state.documentsClient.push(action.payload);
    },

    //Modifier une bank
    // updateBank: (state, action) => {
    //     state.Banks = state.Banks.map((bank) => {
    //         if (bank.id === action.payload[1]) {
    //             return {
    //                 ...bank,
    //                 code: action.payload[0].code,
    //                 name: action.payload[0].name,
    //                 location: action.payload[0].location,
    //                 email: action.payload[0].email,
    //                 deleted: action.payload[0].deleted
    //             }
    //         } else {
    //             return bank;
    //         }

    //     })
    // },

    // Recuperation des données
    getAllDoc: (state, { payload }) => {
      state.documentsClient = payload;
    },

    updateStatusDoc: (state, action) => {
      state.documentsClient = state.documentsClient.map((doc) => {
        if (doc.id === action.payload[1]) {
          return {
            ...doc,
            status: action.payload[0],
          };
        } else {
          return doc;
        }
      });
    },
  },
});

// GESTION DES Utilisateurs
const gestionDesUsers = createSlice({
  name: "users",
  initialState: {
    utilisateurs: [],
  },
  reducers: {
    //Ces fonctions recevrons l'etat actuel et l'action a effectuer
    //Ajouter une bank
    addUser: (state, action) => {
      state.utilisateurs.push(action.payload);
    },

    //Modifier une bank
    updateUtilisateur: (state, { payload }) => {
      state.utilisateurs.map((user) => {
        if (user.id === payload.id) {
          return {
            ...user,
            lastname: payload.lastname,
            firstname: payload.firstname,
            username: payload.username,
            phone: payload.phone,
            address: payload.address,
          };
        }
      });
    },

    // Recuperation des données
    getUsers: (state, { payload }) => {
      state.utilisateurs = payload;
    },
    updateStatusUser: (state, action) => {
      state.utilisateurs = state.utilisateurs.map((user) => {
        if (user.id === action.payload[1]) {
          return {
            ...user,
            status: action.payload[0],
          };
        } else {
          return user;
        }
      });
    },
  },
});

// GESTION DES Utilisateurs
const gestionDesDivision = createSlice({
  name: "divisions",
  initialState: {
    divisions: [],
  },
  reducers: {
    //Ces fonctions recevrons l'etat actuel et l'action a effectuer
    //Ajouter une bank
    addDivision: (state, action) => {
      state.divisions.push(action.payload);
    },

    updateDivision: (state, { payload }) => {
      state.divisions.map((division) => {
        if (division.id === payload.id) {
          return {
            ...division,
            name: payload.name,
            location: payload.location,
            email: payload.email,
            phoneNumber: payload.phoneNumber,
            budget: payload.budget,
            interestRate: payload.interestRate,
            status: payload.status,
            latenessGracePeriod: payload.latenessGracePeriod,
            collectionGracePeriod: payload.collectionGracePeriod,
          };
        }
      });
    },

    // Recuperation des données
    getDivisions: (state, { payload }) => {
      state.divisions = payload;
    },
    updateStatusDivision: (state, { payload }) => {
      state.divisions.map((bank) => {
        if (bank.id === payload.id) {
          return {
            ...bank,
            status: payload.status,
          };
        }
      });
    },
  },
});

//EXPORTATION DES FONCTION DES SPLICES
export const { saveCurrentUserData } = AuthenticatedUserSlice.actions;

export const { setDataBank, addBank, updateBank, updateStatusBank } =
  gestionDesBanks.actions;
export const {
  setDataDemandes,
  updateDemande,
  addDemande,
  updateStatusOneDemandeConso,
} = gestionDesDemandes.actions;
export const {
  setDataDemandesEquipement,
  addDemandeEquipement,
  updateDemandeEquipement,
  updateStatusOneDemande,
} = gestionDesDemandesEquipement.actions;
export const {
  setDataDemandesEquipementClient,
  addDemandeEquipementClient,
  updateDemandeEquipementClient,
  updateStatusOneDemandeClient,
} = gestionDesDemandesClient.actions;
export const {
  addCustomer,
  updateClient,
  updateStatusCustomer,
  getAllCustomers,
} = gestionDesClients.actions;
export const { addOffre, updateOffre, updateStatusOffre, getAllOffres } =
  gestionDesOffres.actions;
export const { addPret, updatePret, updateStatusPret, getAllPret } =
  gestionDesPrets.actions;
export const { addDocument, getAllDocuments, updateStatusDocument } =
  gestionDesDocuments.actions;
export const { saveDocument, getAllDoc, updateStatusDoc } =
  DocumentsClient.actions;
export const { addUser, getUsers, updateStatusUser, updateUtilisateur } =
  gestionDesUsers.actions;
export const {
  addDivision,
  getDivisions,
  updateDivision,
  updateStatusDivision,
} = gestionDesDivision.actions;
export const { retrieveDemandesEquipInDivision } =
  demandesInDivisionSlice.actions;

export const store = configureStore({
  reducer: {
    banques: gestionDesBanks.reducer,
    demandes: gestionDesDemandes.reducer,
    equipements: gestionDesDemandesEquipement.reducer,
    demandesClient: gestionDesDemandesClient.reducer,
    clients: gestionDesClients.reducer,
    offres: gestionDesOffres.reducer,
    prets: gestionDesPrets.reducer,
    documents: gestionDesDocuments.reducer,
    clientDoc: DocumentsClient.reducer,
    users: gestionDesUsers.reducer,
    divisions: gestionDesDivision.reducer,
    // banks: bankReducer,
    authUser: AuthenticatedUserSlice.reducer,
    demandesDivision: demandesInDivisionSlice.reducer,
  },
});
