class SexeCustomer {

     sexe=[
        {
          "lebel":"Homme",
          "value":"MASCULIN"
        },
        {
          "lebel":"Femme",
          "value":"FEMININ"
        }
      ]
    



}

export default new SexeCustomer();
