import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import React, { } from 'react';
import EquipementForm from './Components/Forms/Equipement/EquipementForm';
import UserList from './Components/Lists/ListUser';
import { Routes, Route } from 'react-router-dom';
import ClientForm from './Components/Forms/Client/ClientForm';
import FicheClient from './Components/ComposantsElementaire/FicheClient';
import SideMenu from './Components/ComposantsElementaire/SideMenu';
import BanksList from './Components/Lists/BanksList';
import ListesDivisions from './Components/Lists/ListeDivisions';
import { ProtectRoutes, ProtectRoutesUser } from './hooks/protectRoutes';
import UserForm from './Components/Forms/User/UserForm';
import ListeRoleUsers from './Components/Lists/ListRoleUsers';

import NotFoundPage from './Components/ComposantsElementaire/PageNotFound';

import FicheDemande from './Components/ComposantsElementaire/FicheDemande';
import LenderForm from './Components/Forms/Lender/LenderForm';
import ForgetPassword from './Components/Login/ForgetPassword';
import CookieManager from './Components/eng_composants/CookieManager';
import ListeRole from './Components/Lists/ListeRole';
import AuditeList from './Components/Lists/ListeAudite';
import DocumentsList from './Components/Lists/ListeAllDocument';
import TableauBord from './Reporting/Main';
import ResetPassword from './Components/Login/ResetPassword';
import { Router } from "./routes";
import CustomerForm from './Components/Forms/Client/CustomerForm';
import ListEquipements from './Components/Lists/ListEquipements';

const App = () => {

  return (

    <div className="App">
      <CookieManager />
      <Routes exact path="/">
        <Route exact path={Router.Signin.path} element={<Login />} />
        <Route
          exact
          path={Router.ForgetPassword.path}
          element={<ResetPassword />}
        />
        <Route
          exact
          path={Router.ChangePassword.path}
          element={<ForgetPassword />}
        />



        {/* LES ROUTES RESERVEES POUR LES ADMINS */}
        <Route element={<ProtectRoutesUser role="ADMIN" />}>

          <Route
            exact
            path={Router.ListeUsers.path}
            element={<SideMenu content={<ListeRoleUsers />} />}
          />
          <Route
            exact
            // Institution
            path={Router.Institution.path}
            element={<SideMenu content={<LenderForm />} />}
          />

          <Route
            exact

            path={Router.InstitutionById.path}
            element={<SideMenu content={<LenderForm />} />}
          />

          <Route
            exact
            path={Router.Roles.path}
            element={<SideMenu content={<ListeRole />} />}
          />

          <Route
            exact

            path={Router.AuditListe.path}
            element={<SideMenu content={<AuditeList />} />}
          />

          <Route
            exact
            path={Router.Banks.path}
            element={<SideMenu content={<BanksList />} />}
          />

          <Route
            exact

            path={Router.BanksById.path}
            element={<SideMenu content={<BanksList />} />}
          />

          <Route
            exact
            // 
            path={Router.Divisions.path}
            element={<SideMenu content={<ListesDivisions />} />}
          />
          <Route
            exact
            path={Router.DivisionsById.path}
            element={<SideMenu content={<ListesDivisions />} />}
          />

        </Route>


        <Route element={<ProtectRoutes />}>
          <Route path={Router.HomePage.path} element={<Home />} />
          <Route
            exact
            path={Router.Statistique.path}
            element={<SideMenu content={<TableauBord />} />}
          />





          <Route
            exact
            path={Router.Document.path}
            element={<SideMenu content={<DocumentsList />} />}
          />

          <Route
            exact
            path={Router.User.path}
            element={<SideMenu content={<UserForm />} />}
          />

          <Route
            exact
            path={Router.EquipementForm.path}
            element={<SideMenu content={<EquipementForm />} />}
          />
          <Route
            exact
            path={Router.EquipementById.path}
            element={<SideMenu content={<EquipementForm />} />}
          />


          <Route
            exact

            path={Router.DemandeListes.path}
            element={<SideMenu content={<ListEquipements />} />}
          />

          <Route
            exact

            path={Router.Clients.path}
            element={<SideMenu content={<UserList />} />}
          />
          <Route
            exact
            path={Router.clientForm.path}
            element={<SideMenu content={<ClientForm />} />}
          />
          <Route
            exact

            path={Router.DetailDemande.path}
            element={<SideMenu content={<FicheDemande />} />}
          />

          <Route
            exact
            path={Router.DetailClient.path}
            element={<SideMenu content={<FicheClient />} />}
          />




        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}
// }

export default App;
