import React, { useEffect, useState, useReducer } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Badge from "../Badge/Badge";
import EquipementService from "../../Service/EquipementService";
import EditDemandeEquip from "../Modals/ EditDemandeEquip";
import generalService from "../../Service/generalService";
import { Button } from "react-bootstrap";
import ConsommationService from "../../Service/ConsommationService";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDocuments,
  setDataDemandesEquipementClient,
  updateStatusOneDemande,
  updateStatusOneDemandeClient,
} from "../../redux/redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import {  FaTimesCircle } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import UserService from "../../Service/UserService";
import AuthService from "../../Service/AuthService";
import GestionDesDocuments from "../../Service/GestionDesDocuments";
import BanqueService from "../../Service/BanqueService";
import Cookies from "js-cookie";


function MyTab({ id }) {
  const [banks, setBanks] = useState([])
  const [ignored, forceUpdate] = useReducer((value) => value + 1, 1);
  
  const dispatch = useDispatch();

  const entete = [
    { title: "N° Demande", class: "align-middle" },
    { title: "N° Agent", class: "align-middle" },
    { title: "Nom", class: "align-middle" },
    { title: "Prenom", class: "align-middle" },
    { title: "Apport", class: "align-middle" },
    { title: "Valeur Bien", class: "align-middle" },
    { title: "Banque", class: "align-middle" },
    { title: "Durée/Mois", class: "align-middle" },
    { title: "Statut", class: "align-middle text-center" },
    { title: "Actions", class: "align-middle text-center" },
  ];

  const listDemandeEquip = useSelector(
    (state) => state.demandesClient.demandesEquipementClient
  );


  useEffect(() => {
    UserService.getDemandesForOneCustomer(id).then((rest) => {
      if (AuthService.isAgent(AuthService.getCookie("role"))) {
        dispatch(
          setDataDemandesEquipementClient(
            rest.data
              .filter((order) => {
                if (order.userDto.divisionDto != null) {
                  return (
                    order.userDto.id == Cookies.get('user') &&
                    order.userDto.divisionDto.id == Cookies.get('Secure_DIV')
                  );
                }
              })
              .reverse()
          )
        );
      } else {
        dispatch(setDataDemandesEquipementClient(rest.data.reverse()));
      }

      BanqueService.getBanques().then((rest) => {
        setBanks(rest.data.filter((bank) => bank.status == "ACTIVE"));
      });
    });

    GestionDesDocuments.getDocumentsCustomer(id).then((doc) => {
      console.log(doc.data);
      dispatch(getAllDocuments(doc.data));
    });
  }, [ignored, id]);

  function format(s) {
    return String(s).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ") + " CFA";
  }

  const sendEmailToBank = (orderId, email) => {
    EquipementService.sendMail(orderId, email)
      .then(() => {
        dispatch(updateStatusOneDemande({ orderId, status: "SENT" }));
        dispatch(updateStatusOneDemandeClient({ orderId, status: "SENT" }));
        ConsommationService.notifier(
          "success",
          "Demande envoyée avec succèe",
          "erreur"
        );
        forceUpdate();
      })
      .catch((err) => {
        ConsommationService.notifier(
          "error",
          "Votre Demande a été enregistrée avec succèes",
          "Une erreur est survenue lors de l'envoi du mail, Veuillez réessayer !"
        );
        throw err;
      });
  };

  const changeDemandeStatus = (demandeID, status) => {
    EquipementService.updateStatusEquip(demandeID, status)
      .then(() => {
        dispatch(updateStatusOneDemandeClient({ demandeID, status }));
        ConsommationService.notifier(
          "success",
          `Demande  ${
            status === "ACCEPTED" ? "acceptée" : "réfusée"
          }  avec succès, Statut :${status}`,
          "erreur"
        );
        forceUpdate();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="MyTab">
      <Tabs defaultIndex={0} style={{}}>
        <TabList>
          <Tab>
            <b>DEMANDES EQUIPEMENT</b>
            <Badge
              style={{ backgroundColor: "#2b4366" }}
              bg="#2b4366"
              etat={listDemandeEquip.length}
              className="m-2"
            >
              {listDemandeEquip.length}
            </Badge>{" "}
          </Tab>
          {/* <Tab>
            <b>DOCUMENTS</b>
          </Tab> */}
        </TabList>

        <TabPanel>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                {entete.map((item) => (
                  <th key={item} className={item.class} scope="col">
                    {item.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listDemandeEquip.map((objet) => (
                <tr key={objet.id}>
                  <td className="align-middle">{objet.code}</td>
                  <td className="align-middle">
                    {objet.userDto != null ? objet.userDto.code : ""}
                  </td>
                  <td className="align-middle">{objet.customerDto.lastName}</td>
                  <td className="align-middle">
                    {objet.customerDto.firstName}
                  </td>
                  <td className="align-middle">{format(objet.amount)}</td>
                  <td className="align-middle">
                    {format(objet.propertyValue)}
                  </td>
                  <td className="align-middle">
                    {objet.bankDto !== null ? objet.bankDto.name : ""}
                  </td>
                  <td className="align-middle">{objet.duration}</td>
                  <td className="align-middle text-center">
                    <Badge etat={objet.status} />
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-around gap-1">
                      <Button
                        type="button"
                        className="btn btn-sm"
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                        }}
                        disabled={generalService.autorisateOperation(objet, [
                          "ACCEPTED",
                          "REFUSED",
                        ])}
                      >
                        <EditDemandeEquip data={objet} banks={banks} onRefresh={forceUpdate} />
                      </Button>
                      <Button
                        id={objet.id}
                        className="btn btn-sm"
                        onClick={() => {
                          sendEmailToBank(objet.id, objet.bankDto.email);
                        }}
                        style={{
                          color: "#2b4366",
                          border: "#2b4366",
                          backgroundColor: "transparent",
                          fontSize: "1.5rem",
                        }}
                      >
                        <IoIosSend />
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-sm"
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                          fontSize: "1.2rem",
                        }}
                        disabled={generalService.autorisateOperation(objet, [
                          "ACCEPTED",
                          "REFUSED",
                        ])}
                        onClick={() =>
                          changeDemandeStatus(objet.id, "ACCEPTED")
                        }
                      >
                        <BsFillCheckCircleFill />
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-sm"
                        style={{
                          color: "#2b4366",
                          backgroundColor: "transparent",
                          border: "white",
                          fontSize: "1.2rem",
                        }}
                        disabled={generalService.autorisateOperation(objet, [
                          "ACCEPTED",
                          "REFUSED",
                        ])}
                        onClick={() => changeDemandeStatus(objet.id, "REFUSED")}
                      >
                        <FaTimesCircle />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default MyTab;
