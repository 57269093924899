import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string, number } from "yup";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";

import InputOutline from "../Inputs/InputOutline";

import ConsommationService from "../../Service/ConsommationService";
import { Form } from "react-bootstrap";

import UserService from "../../Service/UserService";
import { useDispatch } from "react-redux";
import { addUser } from "../../redux/redux";
import PhoneInputNumber from "../Inputs/PhoneInputNumber";

export default function UserModal({
  isOpen,
  onRefresh,
  onClose,
  data,
  phones,
  emails,
}) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [division, setDivision] = useState({ id: "", name: "" });
  const [phoneError, setPhoneError] = useState({
    isPhoneError: false,
    message: "",
  });
  const [emailError, setEmailError] = useState({
    isEmailError: false,
    message: "",
  });
  const [divisionError, setDivisionError] = useState({
    isError: false,
    message: "",
  });

  const dispatch = useDispatch();

  const toggleShow = () => onClose(false);

  function checkPhoneExist(phone, listPhones = []) {
    if (listPhones.includes(phone.trim())) {
      setPhoneError({ isPhoneError: true, message: "Téléphone existe déja." });
    } else {
      setPhoneError({ isPhoneError: false, message: "" });
    }
  }

  function checkEmailExist(email, listEmails = []) {
    if (listEmails.includes(email)) {
      setEmailError({ isEmailError: true, message: "Email existe déja." });
    } else {
      setEmailError({ isEmailError: false, message: "" });
    }
  }

  function checkDivisionRequired(division = {}) {
    if (division === null) {
      setDivisionError({
        isError: true,
        message: "Division est requis.",
      });
    } else {
      setDivisionError({
        isError: false,
        message: "",
      });
    }
  }

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    checkPhoneExist(value, phones);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    checkEmailExist(e.target.value, emails);
  };

  function handleDivisionChange(e) {
    setDivision({
      id: e.target.value,
    });
    checkDivisionRequired(division);
  }

  const validateSchema = object({
    firstname: string().min(3).max(50).required("Prenom est requis"),
    lastname: string().min(3).max(50).required("Prenom est requis"),
    address: string().max(50).required("Addresse est requis"),
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address: "",
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      const userData = {
        ...values,
        username: username,
        phone: phoneNumber,
        divisionDto: {
          id: division.id,
        },
        status: "ACTIVE",
        userType: "SYSTEM",
        role: "AGENT",
      };

      UserService.createUser(userData)
        .then(() => {
          dispatch(addUser(userData));
          toggleShow();
          ConsommationService.notifier(
            "success",
            "Utilisateur enregistré avec succèes",
            "erreur"
          );
          onRefresh();
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Utilisateur enregistré avec succèes",
            "Une erreur est survenue lors de la création d'utilisateur, Veuillez réessayer !"
          );
          throw err;
        });
    },
  });

  return (
    <MDBModal show={isOpen} setShow={onClose} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>CRÉER UTILISATEUR</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggleShow}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Form onSubmit={formik.handleSubmit}>
              <InputOutline
                type="text"
                name="firstname"
                value={formik.values.firstname}
                func={formik.handleChange}
                label="Prénom"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
                limit={50}
              />
              <InputOutline
                name="lastname"
                value={formik.values.lastname}
                type="text"
                func={formik.handleChange}
                label="Nom"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
                limit={50}
              />
              <InputOutline
                name="username"
                value={username}
                type="email"
                func={handleUsernameChange}
                label="Email"
                error={Boolean(emailError.isEmailError)}
                helperText={
                  Boolean(emailError.isEmailError) && emailError.message
                }
                limit={50}
              />

              <div className="mb-3">
                <PhoneInputNumber
                  name="phone"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={Boolean(phoneError.isPhoneError)}
                  helperText={
                    Boolean(phoneError.isPhoneError) && phoneError.message
                  }
                />
              </div>
              <InputOutline
                name="address"
                value={formik.values.address}
                type="text"
                func={formik.handleChange}
                label="Addresse"
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                limit={50}
              />
              <div className="mb-3">
                <FormControl required fullWidth size="small">
                  <InputLabel id="demo-simple-select-helper-label">
                    Boutique
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="division"
                    type="text"
                    label="Boutique"
                    onChange={handleDivisionChange}
                    value={division.id}
                    error={Boolean(divisionError.isError)}
                    helperText={
                      Boolean(divisionError.isError) && divisionError.message
                    }
                  >
                    {data.map((division) => (
                      <MenuItem key={division.id} value={division.id}>
                        {division.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-dark m-1"
                  type="button"
                  onClick={toggleShow}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="btn m-1"
                  style={{
                    backgroundColor: "#2b4366",
                    border: "#2b4366",
                    color: "white",
                  }}
                  // disabled={!formik.isValid}
                >
                  Enregistrer
                </button>
              </div>
            </Form>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
