import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Autocomplete, TextField } from "@mui/material";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";

import InputOutline from "../Inputs/InputOutline";

import ConsommationService from "../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";
import { Form } from "react-bootstrap";

import { FaUserEdit } from "react-icons/fa";

import UserService from "../../Service/UserService";
import { useDispatch } from "react-redux";
import { updateUtilisateur } from "../../redux/redux";
import PhoneInputNumber from "../Inputs/PhoneInputNumber";

export default function EditUserModal({ item, onRefresh, divisions = [] }) {
  const [varyingModal, setVaryingModal] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(item.phone);
  const [division, setDivision] = useState({
    id: item.divisionDto != null ? item.divisionDto.id : "",
    name: item.divisionDto != null ? item.divisionDto.name : "",
  });

  const dispatch = useDispatch();

  const handleDivisionChange = (e) => {
    setDivision({
      id: e.target.value,
    });
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const toggleShow = () => setVaryingModal(false);

  const formik = useFormik({
    initialValues: {
      id: item.id,
      code: item.code,
      lastname: item.lastname,
      firstname: item.firstname,
      username: item.username,
      role: item.role,
      phone: item.phone,
      address: item.address,
      status: item.status,
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      const userData = {
        ...values,
        divisionDto: {
          id: division.id,
        },
      };

      UserService.updateUser(userData, userData.id)
        .then(() => {
          toggleShow();
          dispatch(updateUtilisateur(userData));
          ConsommationService.notifier(
            "success",
            "Utilisateur modifiée avec succèes",
            "erreur"
          );
          onRefresh();
        })
        .catch((err) => {
          toggleShow();
          ConsommationService.notifier(
            "error",
            "Utilisateur modifiée avec succèes",
            "Une erreur est survenue lors de la modification de l'utilisateur, Veuillez réessayer !"
          );
          console.log(err);
        });
    },
  });

 

  return (
    <>
      <div className="col-6">
        <NotificationContainer />
      </div>

      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "black",
          height: "6vh",
          borderRadius: "5px",
        }}
        onClick={() => {
          setVaryingModal(!varyingModal);
        }}
      >
        <FaUserEdit
          style={{
            fontSize: "1.5rem",
            backgroundColor: "none",
            color: "#2b4366",
            transform: "translateY(-20%)",
          }}
        ></FaUserEdit>
      </button>

      <MDBModal show={varyingModal} setShow={setVaryingModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>ÉDITER UTILISATEUR</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Form onSubmit={formik.handleSubmit}>
                <InputOutline
                  type="text"
                  name="firstname"
                  value={formik.values.firstname}
                  func={formik.handleChange}
                  label="Prénom"
                  onBlur={formik.handleBlur}
                  limit={50}
                />
                <InputOutline
                  name="lastname"
                  value={formik.values.lastname}
                  type="text"
                  func={formik.handleChange}
                  label="Nom"
                  onBlur={formik.handleBlur}
                  limit={25}
                />
                <InputOutline
                  name="username"
                  value={formik.values.username}
                  type="email"
                  func={formik.handleChange}
                  label="Email"
                  onBlur={formik.handleBlur}
                />
                <div className="mb-3">
                  <PhoneInputNumber
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                </div>

                <InputOutline
                  name="address"
                  value={formik.values.address}
                  type="text"
                  func={formik.handleChange}
                  label="Addresse"
                  onBlur={formik.handleBlur}
                  limit={25}
                />
                <div className="mb-3">
                  <FormControl required fullWidth size="small">
                    <InputLabel htmlFor="boutique">Boutique</InputLabel>
                    <Select
                      id="boutique"
                      name="division"
                      type="text"
                      label="Boutique"
                      onChange={handleDivisionChange}
                      value={division.id}
                      
                    >
                      {divisions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="d-flex justify-content-around mt-3">
                  <button
                    className="btn btn-dark"
                    type="button"
                    onClick={toggleShow}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#2b4366",
                      border: "#2b4366",
                      color: "white",
                    }}
                  >
                    Enregistrer
                  </button>
                </div>
              </Form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
