import React from "react";
import { MDBBadge } from "mdb-react-ui-kit";

function Badge({ etat }) {
  if (
    etat == "ACTIVE" ||
    etat == "ACCEPTED" ||
    etat == "OPEN" ||
    etat == "CURRENT" ||
    etat == "activate"
  ) {
    // activate
    return (
      <>
        <h5>
          <MDBBadge
            style={{ backgroundColor: "#55AF86", display: "unset" }}
            color="#55AF86"
            className="mx-2 col-8"
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "PENDING" || etat == "ARCHIVED") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ backgroundColor: "#343a40", display: "unset" }}
            color="#343a40"
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "INACTIVE" || etat == "SUSPEND" || etat == "DESACTIVE") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ backgroundColor: "#F0AD4E", display: "unset" }}
            color="#F0AD4E"
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (
    etat == "BLOCKED" ||
    etat == "RECOVERY" ||
    etat == "REFUSED" ||
    etat == "EXPIRED"
  ) {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ backgroundColor: "#B81C11", display: "unset" }}
            color="#B81C11"
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "REJECTED" || etat == "CLOSED") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ display: "unset" }}
            color="secondary"
            light
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "CREATED" || etat == "RECEIVED" || etat == "PAID") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ display: "unset" }}
            color="primary"
            light
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "DEPLAYED") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ display: "unset", backgroundColor: "#fd7e14" }}
            color="#fd7e14"
            light
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "LITIGATION") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ display: "unset", backgroundColor: "#6610f2" }}
            color="#6610f2"
            light
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat == "OFFERED" || etat == "SENT") {
    return (
      <>
        <h5>
          <MDBBadge
            className="mx-2 col-8"
            style={{ display: "unset" }}
            color="info"
            light
          >
            {etat}
          </MDBBadge>
        </h5>
      </>
    );
  } else if (etat) {
    return (
      <>
        <b>
          <MDBBadge
            className="mx-2 col-8"
            style={{ display: "unset", backgroundColor: "#2b4366" }}
            color="#2b4366"
            light
          >
            {etat}
          </MDBBadge>
        </b>
      </>
    );
  }
}

export default Badge;
