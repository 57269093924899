import React from 'react'
import { useNavigate } from 'react-router'

export default function ({name,logo,url}) {
    const navigate=useNavigate()
  return (
    <>
    <div style={{cursor:"pointer"}} onClick={()=>{navigate(url)}} className='d-flex align-items-center'><img src={logo} style={{maxWidth:'10%'}}/><h4 style={{marginLeft:"1rem"}}>{name}</h4></div>
     </>
  )
}
