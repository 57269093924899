import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import EquipementService from '../Service/EquipementService';
import generalService from '../Service/generalService';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28',"#f7635c"];

const PieChartWithPercentage = () => {
  const [donnees,setDonnees]=useState([])
  useEffect(()=>{
    EquipementService.getDemands().then((data)=>{
      setDonnees(data.data)
  
    })
  
  },[])

  const data = [
    { name: 'Nouvelles', value: donnees.filter(don=>don.status=="CREATED").length },
    { name: 'Acceptées', value: donnees.filter(don=>don.status=="ACCEPTED").length },
    { name: 'Encoyées', value: donnees.filter(don=>don.status=="SENT").length },
    { name: 'Refusées', value: donnees.filter(don=>don.status=="REFUSED").length },
  
  ];
 
  const total = data.reduce((sum, { value }) => sum + value, 0);
  const formatPercentage = (value) => `${(value / total * 100).toFixed(2)}%`;

  return (
    <PieChart width={450} height={450}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={80}
        label={({ name, value }) => `${name} ${formatPercentage(value)}`}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend />
      <Tooltip formatter={(value) => formatPercentage(value)} />
    </PieChart>
  );
};

export default PieChartWithPercentage;
