import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../Service/AuthService";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { BoutonComposant } from "../eng_composants/BoutonComposant";
import { saveCurrentUserData } from "../../redux/redux";
import { useDispatch } from "react-redux";

import styles from "./Login.module.css";

export default function LoginForm() {
  const [cookies, setCookies, removeCookie] = useCookies();
  const [erreur, setErreur] = useState("");
  const [success, setSuccess] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function myRedirect() {
    navigate("/home");
  }

  const verifUser = (code) => {
    if (code == 401) {
      setErreur("Email ou mot de passe Incorrect");
    }
  };

  const postAuth = () => {
    console.log("connexion");
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Email is invalid")
          .required("Email Obligatoire"),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password Obligatoire"),
      })}
      onSubmit={(fields) => {
        AuthService.login(fields)
          .then((rest) => {
            let divisionId =
              rest.data.utilisateur.divisionDto !== null 
                ? rest.data.utilisateur.divisionDto.id
                : "";
            AuthService.saveInCookieStorage("token", rest.data.token, {
              secure: true,
            });
            AuthService.saveInCookieStorage(
              "prenom",
              rest.data.utilisateur.firstname,
              { secure: true, }
            );
            AuthService.saveInCookieStorage(
              "nom",
              rest.data.utilisateur.lastname,
              { secure: true}
            );
            AuthService.saveInCookieStorage(
              "role",
              rest.data.utilisateur.role,
              { secure: true }
            );
            AuthService.saveInCookieStorage("user", rest.data.utilisateur.id, {
              secure: true
            });
            AuthService.saveInCookieStorage("Secure_DIV", divisionId, {
              secure: true
            });
            setErreur("");
            setSuccess("Successfully Logged In");
            myRedirect();
          })
          .catch(err => {
            if (err.response.status == 500) {
              setErreur("Email ou mot de passe Incorrect");
            }
            throw err;
          });
      }}
      render={({ errors, status, touched }) => (
        <Form>
          {erreur ? (
            <div class="alert alert-danger" role="alert">
              {erreur}
            </div>
          ) : (
            ""
          )}

          {success ? (
            <div class="alert alert-success" role="alert">
              {success}
            </div>
          ) : (
            ""
          )}

          <div className="form-group text-start">
            <label htmlFor="email" className="mb-2">
              {" "}
              Email
            </label>
            <Field
              name="email"
              type="text"
              className={`form-control 
                ${errors.email && touched.email ? " is-invalid" : ""} ${
                styles.formInput
              }`}
            />
            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group text-start">
            <label htmlFor="password" className="mb-2 mt-2">
              Mot de Passe
            </label>
            <Field
              name="password"
              type="password"
              className={`form-control 
                ${errors.password && touched.password ? " is-invalid" : ""} ${
                styles.formInput
              }`}
            />
            <ErrorMessage
              name="password"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="my-3">
            <Link to="/forgetpassword" className="text-decoration-none">
              Mot de Passe oublié
            </Link>
          </div>
          <div className="form-group mt-3 d-flex justify-content-around">
            <BoutonComposant
              disabled={false}
              type="submit"
              couleur="#272c36"
              titre="CONNEXION"
              color="white"
              funcExcute={postAuth}
            />
          </div>
        </Form>
      )}
    />
  );
}

export { LoginForm };
