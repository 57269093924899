import React from 'react'
import { Badge } from 'react-bootstrap';

export default function ({titre,nombre}) {
  return (
    <div className='d-flex'>
        <h6>{titre}</h6>
       <Badge style={{backgroundColor:"#2b4366",marginLeft:"0.5rem",padding:"0.5rem"}}  bg="#2b4366" >
      {nombre}
      </Badge>{' '}
    
</div>
    
  )
}
