import React from "react";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import {
  defaultCountries,
  FlagEmoji,
  parseCountry,
  PhoneInput,
  usePhoneInput,
} from "react-international-phone";

const PhoneInputNumber = ({
  value,
  onChange,
  name,
  helperText,
  error,
  inputStyle,
}) => {
  const customFlags = [
    {
      iso2: "sn",
      src: "/flags/sn.svg",
    },
  ];

  const countries = defaultCountries.filter((c) => {
    const country = parseCountry(c);
    return customFlags.map((f) => f.iso2).includes(country.iso2);
  });

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "sn",
      value,
      countries: countries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });
  
  

  return (
    <TextField
      fullWidth
      size="small"
      color="primary"
      name={name}
      placeholder="Phone number"
      value={phone}
      onChange={handlePhoneValueChange}
      type="tel"
      required
      helperText={helperText}
      error={error}
      inputProps={{
        style: inputStyle,
      }}
    />
  );
};

export default PhoneInputNumber;
