import React, { useState, useEffect, useReducer } from "react";
import { parse, format, parseISO } from "date-fns";

import { Container } from "react-bootstrap";
import EditModal from "../Modals/EditModal";
import UserService from "../../Service/UserService";
import generalService from "../../Service/generalService";
import "./ListUser.css";
import { MDBTooltip } from "mdb-react-ui-kit";
import { BiSortAlt2, BiSortAZ } from "react-icons/bi";
import Badge from "../Badge/Badge";
import { FaUserCheck, FaFileInvoice, FaUserAltSlash } from "react-icons/fa";
import { NotificationContainer } from "react-notifications";
import "../Modals/EditModal.css";
import { MDBNavbarItem } from "mdb-react-ui-kit";
import { Paginate } from "../Utils/Paginate";
import Pagination from "../Pagination/Pagination";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import IconSwitch from "react-disable-icon";
import ConsommationService from "../../Service/ConsommationService";
import { FaHospitalUser } from "react-icons/fa";
import dateFormat from "dateformat";
import { Table } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomers,
  store,
  updateStatusCustomer,
} from "../../redux/redux";
import FiltreComposant from "../ComposantsElementaire/FiltreComposant";
import Title from "../eng_composants/Title";
import ButtonIcon from "../eng_composants/ButtonIcon";
import DivisionService from "../../Service/DivisionService";

const UserList = () => {
  const [search, setSearch] = React.useState("");
  const [criteria, setCriteria] = React.useState("nom");
  const [myBool, setMyBool] = useState(false);
  const [filtre, setFiltrer] = useState("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 1);
  const [divisions, setDivisions] = useState([]);
  const [tabClient, setTabClient] = useState([]);

  const dispatch = useDispatch();

  const listeCustomer = useSelector((state) => state.clients.customers);

  useEffect(() => {
    UserService.getAllClients()
      .then((res) => {
        dispatch(getAllCustomers(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    DivisionService.getDivisions().then((res) => setDivisions(res.data));
  }, [ignored]);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const page = Paginate(listeCustomer, currentPage, pageSize);

  let myArray = [];

  // FILTRE LISTE DES PRETES
  if (filtre === "") {
    myArray = { nodes: page };
  } else if (filtre === "Nom") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.lastName.includes(search) ||
          item.lastName.toLowerCase().includes(search)
      ),
    };
  } else if (filtre === "Prénom") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.firstName.includes(search) ||
          item.firstName.toLowerCase().includes(search)
      ),
    };
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();

  const navig = (str, id) => {
    navigate("/" + str + "/" + id);
  };

  const changeCustomerStatus = (
    customerData,
    onUpdateSelected,
    status = "ACTIVE"
  ) => {
    customerData.map((customer) => {
      UserService.changeStatuscustomer(customer.id, status)
        .then((cust) => {
          dispatch(updateStatusCustomer(cust));
          ConsommationService.notifier(
            "success",
            `Client(s)  ${
              cust.data.status === status ? "Activé(s)" : "Désactivé(s)"
            } avec succèes, Statut: ${status}`,
            "erreur"
          );
          forceUpdate();
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "erreur",
            "Une erreur est survenue lors de la creation du status du client, Veuillez réessayer !"
          );
          console.log(err);
        });
    });
    onUpdateSelected([]);
  };

  // const activer = () => {
  //   clientStatus(tabClient, "ACTIVE");
  //   setMyBool(false);
  // };

  // const desactiver = () => {
  //   clientStatus(tabClient, "INACTIVE");
  //   setMyBool(false);
  // };

  // FONCTION FILTER
  const functionFiltere = (criteria, filterValue) => {
    setSearch(filterValue);
    setFiltrer(criteria);
  };

  //TRIE PAR STATUS
  const trierTableByStatus = () => {
    generalService.toggle(myBool, setMyBool);
    const tab = [...store.getState().clients.customers];
    dispatch(getAllCustomers(generalService.trieTableByStatus(tab, myBool)));
  };

  // TRIE PAR DATE
  const trieTableByDate = (table, bool) => {
    if (bool) {
      table.sort(function (a, b) {
        return new Date(a.birthday) - new Date(b.birthday);
      });
    } else {
      table.sort(function (a, b) {
        return new Date(b.birthday) - new Date(a.birthday);
      });
    }
    return table;
  };

  // TRIER PAR DATE
  const trierListeByDate = () => {
    generalService.toggle(myBool, setMyBool);
    const tab = [...store.getState().clients.customers];
    dispatch(getAllCustomers(trieTableByDate(tab, myBool)));
  };

  return (
    <>
      <NotificationContainer />
      <Container fluid>
        <Title texte="LISTE CLIENTS" />

        <div className="row">
          <div className="col-md-10 d-flex ">
            <FiltreComposant
              functionFiltere={functionFiltere}
              labelCritere="Critère"
              labelFiltre="Filtre"
              listeCritere={["Nom", "Prénom"]}
            />
          </div>
          <div className="col-2 d-flex align-items-around">
            <ButtonIcon
              disabled={generalService.activateButton(tabClient)}
              action={() =>
                changeCustomerStatus(tabClient, setTabClient, "ACTIVE")
              }
              iconName={<FaUserCheck />}
              titre=" Activer"
            />
            <ButtonIcon
              disabled={generalService.activateButton(tabClient)}
              action={() =>
                changeCustomerStatus(tabClient, setTabClient, "INACTIVE")
              }
              iconName={<FaUserAltSlash />}
              titre="Désactiver"
            />
          </div>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr className="text-start">
              <th style={{ width: "5vh" }}>
                <Checkbox
                  checked={generalService.isSelectedAll(page, tabClient)}
                  onClick={(e) => {
                    generalService.selectedAllElementsInPage(
                      e.target.checked,
                      page,
                      tabClient,
                      setTabClient
                    );
                  }}
                />
              </th>
              <th className="align-middle">N° Client</th>
              <th className="align-middle">Nom</th>
              <th className="align-middle">Prénom(s)</th>
              <th className="align-middle">
                Date Naissance
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title="Trier Date(croissant/décroissant)"
                >
                  <button
                    onClick={() => {
                      trierListeByDate();
                    }}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      fontSize: "3vh",
                    }}
                  >
                    <BiSortAlt2></BiSortAlt2>
                  </button>
                </MDBTooltip>
              </th>
              <th className="align-middle">Téléphone</th>
              <th className="align-middle">Adresse</th>
              <th className="align-middle">Profession</th>
              <th className="align-middle text-center">
                Statut
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title="Trier Statut (ordre alphabétique)"
                >
                  <button
                    onClick={() => {
                      trierTableByStatus();
                    }}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      fontSize: "3vh",
                    }}
                  >
                    <BiSortAZ></BiSortAZ>
                  </button>
                </MDBTooltip>
              </th>
              <th className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {myArray.nodes.map((item) => (
              <tr key={item.id} className="text-start">
                <th scope="row">
                  <Checkbox
                    checked={generalService.isSelectedElement(item, tabClient)}
                    onClick={(e) => {
                      generalService.selectedClick(
                        e.target.checked,
                        item,
                        tabClient,
                        setTabClient
                      );
                    }}
                  />
                </th>
                <td className="align-middle">{item.code}</td>
                <td className="align-middle">{item.lastName}</td>
                <td className="align-middle">{item.firstName}</td>
                <td className="align-middle">
                  {generalService.formatDate(item.birthday)}
                </td>
                <td className="align-middle">
                  {" "}
                  {item.phoneNumber && formatPhoneNumberIntl(item.phoneNumber)}
                </td>
                <td className="align-middle">{item.activityLocation}</td>
                <td className="align-middle">{item.job}</td>

                <td className="align-middle text-center">
                  <Badge etat={item.status} />
                </td>
                <td className="align-middle">
                  <div className="d-flex justify-content-center">
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Editer Client"
                    >
                      <EditModal
                        data={item}
                        divisions={divisions}
                        style={{ color: "black" }}
                        onRefresh={forceUpdate}
                      />
                    </MDBTooltip>

                    <MDBNavbarItem className="liste m-2">
                      <button
                        onClick={() => {
                          navig("equip", item.id);
                        }}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        disabled={generalService.autorisateOperation(
                          item,
                          generalService.tabEtat
                        )}
                      >
                        <MDBTooltip
                          tag="a"
                          wrapperProps={{ color: "#2b4366" }}
                          title={"Demande Equipement"}
                        >
                          <IconSwitch
                            Icon={
                              <FaFileInvoice
                                style={{
                                  color: "",
                                  border: "#2b4366",
                                  fontSize: "1.2rem",
                                }}
                              ></FaFileInvoice>
                            }
                          />
                        </MDBTooltip>
                      </button>
                    </MDBNavbarItem>

                    <MDBNavbarItem className="liste m-2">
                      <button
                        onClick={() => {
                          navig("fiche", item.id);
                        }}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <MDBTooltip
                          tag="a"
                          wrapperProps={{ color: "#2b4366" }}
                          title={"Fiche Client"}
                        >
                          <FaHospitalUser
                            style={{
                              color: "",
                              border: "#2b4366",
                              fontSize: "1.2rem",
                            }}
                          ></FaHospitalUser>
                        </MDBTooltip>
                      </button>
                    </MDBNavbarItem>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          items={listeCustomer.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  );
};

export default UserList;
