import React from "react";
import { Link } from "react-router-dom";
import { Card, Container, Collapse } from "react-bootstrap";

export default function CardHome({ titre, images, textStyle }) {
  return (
    <div class="card">
      <Card className="text-center">
        <Card.Header>
          <b>
            <h3>{titre}</h3>
          </b>
        </Card.Header>
        <Card.Body>
          <Card.Title></Card.Title>

          <div className="d-flex justify-content-around align-items-center">
            {images.map((img) => (
              <Link
                key={img}
                style={{ textDecoration: "none", color: " #575757" }}
                to={img.lien}
              >
                <img
                  src={img.name}
                  width="150"
                  style={{ border: "2px", borderRadius: "10px" }}
                />
                <h4 style={textStyle}>{img.title}</h4>
              </Link>
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
