import Calendar from "../../images/Calendar.png";
import Client from "../../images/Client.png";
import Element from "../../images/Element.png";
import Dollar from "../../images/Dollar.png";
import Box from "../../images/Box.png";
import Notes from "../../images/Notes.png";
import Paiement from "../../images/Paiement.png";
import Operator from "../../images/Operator.png";
import Frais from "../../images/Frais.png";
import Equipement from "../../images/Equipement.png";
import Signature from "../../images/Signature.png";
import Parametre from "../../images/Parametre.png";
import Loan from "../../images/Loan.png";
import Achat from "../../images/Achat.png";
import Contact from "../../images/Contact.png";
import Compta from "../../images/Compta.png";
import Qualite from "../../images/Qualite.png";
import Document from "../../images/Document.png";
import Livre from "../../images/Livre.png";
import App from "../../images/App.png";
import Web from "../../images/Web.png";
import Stock from "../../images/Stock.png";
import Cmr from "../../images/Cmr.png";
import User from "../../images/User.png";
import Search from "../../images/Search.png";
import Dashboard from "../../images/Dashboard.png";
class HomeService {

 demandes=[
        {
          "name": Signature,
          "lien":"/client",
          "title":"Demande"
        },
        {
          "name": Client,
          "lien":"/clients",
          "title":"Clients"
        },
          {
            "name":Parametre,
            "lien":"/demands/equipement",
            "title":"Equipements"
          }
      ]
    
      prets=[
        {
          "name": Client,
          "lien":"/clients",
          "title":"Clients"
        },
        {
            "name": Notes,
            "lien":"/pret/prets",
            "title":"Prêts"
          },
          {
            "name":Paiement,
            "lien":"/demands-equipement",
            "title":"Virement"
          }
      ]

      comptabilite=[
        {
          "name": Loan,
          "lien":"/client",
          "title":"Compte Épargne"
        },
        {
            "name": Frais,
            "lien":"/demands",
            "title":"Compte Interne"
          },
          {
            "name":Achat,
            "lien":"/demands-equipement",
            "title":"Compte Client"
          }
      ]

      risques=[
        {
          "name": Contact,
          "lien":"/client",
          "title":"Évaluation"
        },
        {
            "name": Compta,
            "lien":"/demands",
            "title":"Simuler Prêt"
          },
          {
            "name":Qualite,
            "lien":"/demands-equipement",
            "title":"Gestion"
          }
      ]

      BI=[
        {
          "name": Dashboard,
          "lien":"/client",
          "title":"Dashboard"
        },
        {
            "name": Document,
            "lien":"/demands",
            "title":"Portfolio"
          },
          {
            "name":Livre,
            "lien":"/demands-equipement",
            "title":"Reports"
          }
      ]

      tiers=[
        {
          "name": Equipement,
          "lien":"/banks",
          "title":"Banques"
        },
        {
            "name": Operator,
            "lien":"/divisions",
            "title":"Boutiques"
          },
          {
            "name":App,
            "lien":"/institution/1",
            "title":"Institutions"
          }
      ]

      fichiers=[
        {
          "name": Web,
          "lien":"/document/documents",
          "title":"Rechercher"
        },
        {
            "name": Stock,
            "lien":"/document/ARCHIVED",
            "title":"Archiver"
          },
          {
            "name":Dashboard,
            "lien":"/statistique",
            "title":"Statistique"
          }
      ]

      administration=[
        {
          "name": User,
          "lien":"/users",
          "title":"Utilisateurs"
        },
        {
            "name": Element,
            "lien":"/roles",
            "title":"Rôles"
          },
          {
            "name":Search,
            "lien":"/audit",
            "title":"Audit"
          }
      ]

}

export default new HomeService();
