import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "../../Service/UserService";
import { Form, Col } from "react-bootstrap";
import { Row } from "mdbreact";
import ClientCard from "./ClientCard";
import "./myStyles.css";
import Tabs from "./MyTab";
import dateFormat from "dateformat";
import { NotificationContainer } from "react-notifications";
import { MDBTooltip } from "mdb-react-ui-kit";
import { FaSave, FaFileInvoice } from "react-icons/fa";
import PreventTransitionPrompt from "./PreventTransitionPrompt";
import InputPrompt from "../Inputs/InputPrompt";
import Title from "../eng_composants/Title";
import ConsommationService from "../../Service/ConsommationService";
import generalService from "../../Service/generalService";
import { getAllCustomers, updateClient } from "../../redux/redux";
import { BsArrowRightCircle } from "react-icons/bs";

function FicheClient() {
  const [clientId, setClientId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [job, setJob] = useState("");
  const [jobDuration, setJobDuration] = useState("");
  const [activitySector, setActivitySector] = useState("");
  const [activityLocation, setActivityLocation] = useState("");
  const [status, setStatus] = useState("");
  const [code, setCode] = useState("");
  const [typeCustomer, setTypeCustomer] = useState("");
  let [isBlocking, setIsBlocking] = useState(false);
  const [isNext, setIsNext] = useState(true);

  const { id } = useParams();

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const navig = (str, id) => {
    navigation("/" + str + "/" + id);
  };

  const listeCustomer = useSelector((state) => state.clients.customers);

  useEffect(() => {
    UserService.getAllClients()
      .then((res) => {
        dispatch(getAllCustomers(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    handleSelectNextCustomer(id);
  }, [id]);

  function handleSectorActivityChange(e) {
    setActivitySector(e.target.value);
  }

  function handleGenderChange(e) {
    setGender(e.target.value);
  }

  const client = {
    id: clientId,
    lastName: name,
    firstName: surname,
    gender: gender,
    email: email,
    birthday: birthday,
    phoneNumber: phoneNumber,
    job: job,
    jobDuration: jobDuration,
    activitySector: activitySector,
    activityLocation: activityLocation,
    status: status,
    code: code,
    typeCustomer: typeCustomer,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const clientData = {
      ...client,
      birthday: new Date(client.birthday).toISOString(),
    };
    UserService.editClient(clientData)
      .then(() => {
        dispatch(updateClient(clientData));
        ConsommationService.notifier(
          "success",
          "Informations Client enregistrées avec succèes",
          "erreur"
        );
      })
      .catch((err) => {
        throw err;
      });
  };

  const currentIndex = parseInt(id, 10);
  const currentIndexInArray = listeCustomer.findIndex(
    (customer) => customer.id === currentIndex
  );

  const nextIndex = (currentIndexInArray + 1) % listeCustomer.length;
  const nextCustomer = listeCustomer[nextIndex];

  const handleSelectNextCustomer = (customerId) => {
    UserService.getClientById(customerId).then((res) => {
      setClientId(res.data.id);
      setCode(res.data.code);
      setName(res.data.lastName);
      setSurname(res.data.firstName);
      setGender(res.data.gender.toLowerCase());
      setEmail(res.data.email);
      setBirthday(
        dateFormat(new Date(parseInt(res.data.birthday) * 1000), "yyyy-mm-dd")
      );
      setPhoneNumber(res.data.phoneNumber);
      setJob(res.data.job);
      setJobDuration(res.data.jobDuration);
      setActivitySector(res.data.activitySector);
      setActivityLocation(res.data.activityLocation);
      setCode(res.data.code);
      setTypeCustomer(res.data.typeCustomer);
      setStatus(res.data.status);
    });
  };

  function handleNextCustomer() {
    let newId;

    if (nextIndex === 0) {
      // If it's the last customer, go back to the first customer
      newId = listeCustomer[0].id;
    } else {
      newId = nextCustomer.id;
    }

    navigation("/fiche/" + newId);
  }

  const activitySectorsTables = [
    { key: "administration", value: "Administration" },
    { key: "agroalimentaire", value: "Agroalimentaire et Agriculture" },
    { key: "audit", value: "Audit et Comptabilité" },
    { key: "finance", value: "Banque - Finance - Assurance" },
    { key: "btp", value: "Construction - BTP" },
    { key: "economie", value: "Banque - Finance - Assurance" },
    { key: "education", value: "Education - Formation" },
    { key: "industrie", value: "Industrie" },
    { key: "tech", value: "Technologies de l'Information" },
    { key: "RH", value: "Resources Humaines" },
    { key: "sante", value: "Santé - Social" },
    { key: "transport", value: "Transport - Logistique" },
  ];

  const genders = [
    { key: "masculin", value: "Masculin" },
    { key: "feminin", value: "Féminin" },
  ];

  const tooltipText = isNext ? "Client Suivant" : "Client Précédant";

  return (
    <div className="fiche">
      <PreventTransitionPrompt
        when={isBlocking}
        title="Please Save"
        message={<strong>Please save your changes.</strong>}
      />
      <NotificationContainer />
      <div>
        <div className="d-flex justify-content-around align-items-center">
          <ClientCard
            surname={surname}
            name={name}
            activte={activitySector}
            id={code}
            sexe={gender}
          />
          <div className="d-flex gap-2">
            <button
              onClick={() => {
                navig("equip", clientId);
              }}
              disabled={generalService.autorisateOperation(
                client,
                generalService.tabEtat
              )}
              style={{ backgroundColor: "white", border: "none" }}
            >
              <MDBTooltip
                tag="a"
                wrapperProps={{ color: "#2b4366" }}
                title={"Demande Equipement"}
              >
                {/* <IconSwitch
                    Icon={
                      
                    }
                  /> */}
                <FaFileInvoice
                  style={{
                    color: "#2b4366",
                    border: "#2b4366",
                    fontSize: "1.8rem",
                  }}
                />
              </MDBTooltip>
            </button>
            <button
              type="submit"
              style={{ backgroundColor: "white", border: "none" }}
              onClick={handleSubmit}
            >
              <MDBTooltip
                tag="a"
                wrapperProps={{ color: "#2b4366" }}
                title={"Enregistrer"}
              >
                {/* <IconSwitch
                    Icon={
                      
                    }
                  /> */}
                <FaSave
                  style={{
                    color: "#2b4366",
                    border: "#2b4366",
                    fontSize: "1.8rem",
                  }}
                />
              </MDBTooltip>
            </button>
            <button
              style={{ backgroundColor: "white", border: "none" }}
              onClick={handleNextCustomer}
            >
              <MDBTooltip
                tag="a"
                wrapperProps={{ color: "#2b4366" }}
                title={tooltipText}
              >
                <BsArrowRightCircle
                  style={{
                    color: "#2b4366",
                    border: "#2b4366",
                    fontSize: "1.8rem",
                  }}
                />
              </MDBTooltip>
            </button>
          </div>
        </div>
        <div className="text-center">
          <Title texte="INFORMATIONS CLIENT" />
        </div>
        <Form className="mt-5" onSubmit={handleSubmit}>
          <Form.Group className="mt-5 col-7" style={{ display: "contents" }}>
            <Row className="row d-flex justify-content-evenly">
              <Col className="col-4">
                <InputPrompt
                  value={name}
                  label="Nom"
                  func={setName}
                  isRequired="true"
                  // block={setIsBlocking}
                />
                <InputPrompt
                  value={surname}
                  label="Prénom"
                  func={setSurname}
                  isRequired="true"
                  // block={setIsBlocking}
                />
                <FormControl className="mb-3" fullWidth size="small">
                  <InputLabel id="gender">Sexe</InputLabel>
                  <Select
                    name="gender"
                    type="text"
                    labelId="gender"
                    label="Sexe"
                    onChange={handleGenderChange}
                    value={gender}
                  >
                    {genders.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InputPrompt
                  value={email}
                  label="Email"
                  func={setEmail}
                  isRequired="true"
                  // block={setIsBlocking}
                />
                <InputPrompt
                  type="date"
                  value={birthday}
                  label="Date Naissance"
                  func={setBirthday}
                  isRequired="true"
                  // block={setIsBlocking}
                />
              </Col>
              <Col className="col-4">
                <InputPrompt
                  value={phoneNumber}
                  label="Téléphone"
                  func={setPhoneNumber}
                  isRequired="true"
                  // block={setIsBlocking}
                />
                <InputPrompt
                  value={job}
                  label="Profession"
                  func={setJob}
                  isRequired="true"
                  // block={setIsBlocking}
                />
                <InputPrompt
                  value={jobDuration}
                  label="Durée Profession (optionnel)"
                  func={setJobDuration}
                  // block={setIsBlocking}
                />
                <div className="mb-3">
                  <FormControl fullWidth size="small">
                    <InputLabel id="activitySector">
                      Secteur activité
                    </InputLabel>
                    <Select
                      name="activitySector"
                      type="text"
                      labelId="activitySector"
                      label="Secteur d'activité"
                      onChange={handleSectorActivityChange}
                      value={activitySector}
                    >
                      {activitySectorsTables.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <InputPrompt
                  value={activityLocation}
                  label="Adresse activité"
                  func={setActivityLocation}
                  isRequired="true"
                  // block={setIsBlocking}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
        <div className="mt-4">
          <Tabs id={id} />
        </div>
      </div>
    </div>
  );
}

export default FicheClient;
