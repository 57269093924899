import React from 'react';
import { MDBContainer, MDBNavbar} from 'mdb-react-ui-kit';
import SF_logo from '../../images/SF_logo.JPG';
import './NavBar.css';
import UserProfile from "../ComposantsElementaire/UserProfile.js"
import Crediz from '../eng_composants/Crediz';
export default function NavBarHome() {
   
    return (
        <header fixed className='mb-3'>
            <MDBNavbar expand='lg' style={{backgroundColor:"white"}} light bgColor='' fixed="top">
                <MDBContainer fluid>
                <Crediz name="SOLUTIONFLEXI" logo={SF_logo} url="/home"/>
                <button style={{  backgroundColor:"transparent"}} type="button"><UserProfile/></button>
                </MDBContainer>
            </MDBNavbar>
            
        </header>
        
    );
}