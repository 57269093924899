import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import {
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
} from "@mui/material";
import { Textarea, FormLabel } from "@mui/joy";
import { FiEdit } from "react-icons/fi";
import { Button, Form, Modal } from "react-bootstrap";
import InputOutline from "../Inputs/InputOutline";
import { updateDemandeEquipement } from "../../redux/redux";
import EquipementService from "../../Service/EquipementService";
import AuthService from "../../Service/AuthService";
import ConsommationService from "../../Service/ConsommationService";

function EditDemandeEquip({ data, onRefresh, banks = [] }) {
  const [show, setShow] = useState(false);
  const [bankId, setBankId] = useState(
    data.bankDto != null ? data.bankDto.id : ""
  );
  const [bank, setBank] = useState({
    id: data.bankDto != null ? data.bankDto.id : "",
    name: data.bankDto != null ? data.bankDto.name : "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  console.log(data)

  const handleBankChange = (e) => {
    setBank({
      id: e.target.value,
    });
  };

  const formik = useFormik({
    initialValues: {
      id: data.id,
      code: data.code,
      description: data.description,
      sectorActivity: data.sectorActivity,
      amount: data.amount,
      includeInsurance: data.includeInsurance,
      remboursement: data.remboursement,
      contrat: data.contrat,
      operator: data.operator,
      identity: data.identity,
      employer: data.employer,
      salary: data.salary,
      income: data.income,
      currentCredit: data.currentCredit,
      propertyValue: data.propertyValue,
      duration: data.duration,
      orderDate: data.orderDate,
      division: data.division,
      status: data.status,
    },
    onSubmit: (values) => {
      const orderEquipData = {
        ...values,

        bankDto: {
          id: bank.id,
        },
        lendersDto: [
          {
            id: 1,
          },
        ],
        userDto: {
          id: Number(AuthService.getCookie("user")),
        },
        customerDto: {
          id: data.customerDto.id,
        },
        typeOrder: "EQUIPEMENT",
        application: "WEB",
      };

      console.log(orderEquipData);
      EquipementService.updateDemandeEquipement(
        orderEquipData.id,
        orderEquipData
      )
        .then((res) => {
          dispatch(updateDemandeEquipement(res.data));
          ConsommationService.notifier(
            "success",
            "Votre Demande a été modifiée avec succès",
            "erreur"
          );
          handleClose();
          onRefresh();
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Votre Demande a été enregistrée avec succès",
            "Une erreur est survenue lors de la modification de votre demande, Veuillez réessayer !"
          );
          console.log(err);
        });
    },
  });

  const loanDurationTables = [
    { key: 3, value: "3 mois" },
    { key: 6, value: "6 mois" },
    { key: 12, value: "12 mois" },
  ];

  const contratTypeTables = ["CDD", "CDI", "STAGE", "PRESTATION"];

  return (
    <>
      <Button
        onClick={() => {
          handleShow();
        }}
        style={{
          color: "#2b4366",
          border: "#2b4366",
          backgroundColor: "transparent",
        }}
      >
        <FiEdit style={{ fontSize: "1.5rem" }} />
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">EDITER DEMANDE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mt-2 mb-3">
            <h3>INFORMATIONS DEMANDE</h3>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row col-md-12">
              <div className="col-md-6">
                <div className="mb-4">
                  <FormControl fullWidth>
                    <FormLabel sx={{ fontSize: "16px" }}>
                      Description Article
                    </FormLabel>
                    <Textarea
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      placeholder="Description"
                      minRows={4}
                      maxRows={4}
                    />
                  </FormControl>
                </div>
                <div className="mb-4">
                  <InputOutline
                    name="employer"
                    label="Employeur"
                    type="text"
                    value={formik.values.employer}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-4">
                  <InputOutline
                    name="propertyValue"
                    label="Valeur Bien"
                    type="text"
                    value={formik.values.propertyValue}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <InputOutline
                    name="amount"
                    label="Montant Apport Personnel"
                    type="text"
                    value={formik.values.amount}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Durée Prêt
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="duration"
                      value={formik.values.duration}
                      label="Secteur d'activité"
                      onChange={formik.handleChange}
                    >
                      {loanDurationTables.map((loanTime) => (
                        <MenuItem key={loanTime.key} value={loanTime.key}>
                          {loanTime.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="mb-4">
                  <FormControl>
                    <FormLabel
                      sx={{ fontSize: "16px" }}
                      id="demo-radio-buttons-group-label"
                    >
                      Voulez vous inclure les frais d'assurance ?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="includeInsurance"
                      defaultChecked={Boolean(formik.values.includeInsurance)}
                      value={formik.values.includeInsurance}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Oui"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Non"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4 mt-4">
                  <FormControl fullWidth size="small">
                    <InputLabel className="mb-2">
                      Type Contrat Travail
                    </InputLabel>
                    <Select
                      className="mb-1"
                      size="small"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="contrat"
                      value={formik.values.contrat}
                      label="Veuillez choisir un type de contrat"
                      onChange={formik.handleChange}
                    >
                      {contratTypeTables.map((contratType) => (
                        <MenuItem key={contratType} value={contratType}>
                          {contratType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="mb-4">
                  <InputOutline
                    name="identity"
                    label="Numéro Pièce d'identité"
                    type="text"
                    value={formik.values.identity}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-4">
                  <InputOutline
                    name="salary"
                    label="Montant Salaire"
                    type="text"
                    value={formik.values.salary}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-4">
                  <InputOutline
                    name="income"
                    label="Revenu supplémentaire"
                    type="text"
                    value={formik.values.income}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-4">
                  <InputOutline
                    name="currentCredit"
                    label="Crédit mensuel en cours"
                    type="text"
                    value={formik.values.currentCredit}
                    func={formik.handleChange}
                  />
                </div>
                <div className="mb-4">
                  <FormControl fullWidth size="small">
                    <InputLabel>Banque Principale</InputLabel>
                    <Select
                      size="small"
                      labelId="demo-select-small-label"
                      name="bank"
                      value={bank.id}
                      label="Secteur d'activité"
                      onChange={handleBankChange}
                    >
                      {banks.map((bank) => (
                        <MenuItem key={bank.id} value={bank.id} selected>
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl>
                    <FormLabel
                      sx={{ fontSize: "16px" }}
                      id="demo-radio-buttons-group-label"
                    >
                      Fréquence remboursement
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="remboursement"
                      value={formik.values.remboursement}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="MENSUALITE_EGALE"
                        control={<Radio />}
                        label="Mensualités égales"
                      />
                      <FormControlLabel
                        value="PLAN_DIFFERE"
                        control={<Radio />}
                        label="Plans differés"
                      />
                      <FormControlLabel
                        value="AUTRE"
                        control={<Radio />}
                        label="Autres"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-4">
              <Button type="button" variant="dark" onClick={handleClose}>
                Annuler
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#2b4366",
                  border: "#2b4366",
                  marginRight: "2rem",
                }}
              >
                Enregistrer
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditDemandeEquip;
