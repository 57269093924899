import React from "react";
export default function ButtonIcon({ disabled, action, iconName, titre }) {
  return (
    <>
      <button
        className="d-flex align-items-center"
        disabled={disabled}
        type="button"
        style={{ border: "none", backgroundColor: "white" }}
        onClick={action}
      >
        <div
          style={{
            marginRight: "0.4rem",
            color: "#2b4366",
            border: "#2b4366",
            fontSize: "1.4rem",
          }}
        >
          {iconName}
        </div>
        {titre}
      </button>
    </>
  );
}
