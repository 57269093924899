import React, { useState, useEffect, useReducer } from "react";
import { Container } from "react-bootstrap";
import UserService from "../../Service/UserService";
import generalService from "../../Service/generalService";
import "./ListUser.css";
import { MDBTooltip } from "mdb-react-ui-kit";
import Badge from "../Badge/Badge";
import { BiSortAZ } from "react-icons/bi";
import { FaUserCheck, FaUserAltSlash, FaUserPlus } from "react-icons/fa";
import { NotificationContainer } from "react-notifications";
import "../Modals/EditModal.css";
import { Paginate } from "../Utils/Paginate";
import Pagination from "../Pagination/Pagination";
import Checkbox from "@mui/material/Checkbox";
import ConsommationService from "../../Service/ConsommationService";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions, getUsers, updateStatusUser } from "../../redux/redux";
import FiltreComposant from "../ComposantsElementaire/FiltreComposant";
import UserModal from "../Modals/UserModal";
import EditUserModal from "../Modals/EditUserModal";
import Title from "../eng_composants/Title";
import ButtonIcon from "../eng_composants/ButtonIcon";
import DivisionService from "../../Service/DivisionService";

const UserList = () => {
  const [search, setSearch] = React.useState("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [myBool, setMyBool] = useState(false);
  const [filtre, setFiltrer] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tabClient, setTabClient] = useState([]);

  const dispatch = useDispatch();
  const listeUser = useSelector((state) => state.users.utilisateurs);
  const listeDivision = useSelector((state) => state.divisions.divisions);

  let listPhoneUsers = listeUser.map((user) => user.phone);
  let listUsernameUsers = listeUser.map((user) => user.username);
 

  useEffect(() => {
    UserService.getAllUsers()
      .then((res) => {
        dispatch(getUsers(res.data));
      })
      .catch((err) => {
        console.log(err);
      });

    DivisionService.getDivisions().then((div) => {
      dispatch(getDivisions(div.data));
    });
  }, [ignored]);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const page = Paginate(listeUser, currentPage, pageSize);

  let myArray = [];
  // FILTRE LISTE DES PRETES
  if (filtre === "") {
    myArray = { nodes: page };
  } else if (filtre === "Nom") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.lastname.includes(search) ||
          item.lastname.toLowerCase().includes(search)
      ),
    };
  } else if (filtre === "Prénom") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.firstname.includes(search) ||
          item.firstname.toLowerCase().includes(search)
      ),
    };
  }

  // TRIE PAR BOUTIQUE
  const sortDivisionColumn = (isSorted, sortedData = []) => {
    sortedData.sort((div1, div2) => {
      if (isSorted) {
        if (div1.divisionDto.name < div2.divisionDto.name) {
          return -1;
        }
        if (div1.divisionDto.name > div2.divisionDto.name) {
          return 1;
        }
        return 0;
      } else {
        if (div1.divisionDto.name > div2.divisionDto.name) {
          return -1;
        }
        if (div1.divisionDto.name < div2.divisionDto.name) {
          return 1;
        }
        return 0;
      }
    });
    return sortedData;
  };

  function handleSortDivision() {
    generalService.toggle(myBool, setMyBool);
    dispatch(getUsers(sortDivisionColumn(myBool, page)));
  }

  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const userStatus = (TableauClient, etat) => {
    TableauClient.map((client) => {
      if (client.id) {
        UserService.updateStatusUser(client.id, etat).then(() => {
          if (etat == "desactivate") {
            dispatch(updateStatusUser(["DESACTIVE", client.id]));
          } else {
            dispatch(updateStatusUser(["ACTIVE", client.id]));
          }
        });
      }
    });
    ConsommationService.notifier(
      "success",
      "Utilisateur(s)  " + etat + "  avec succès, Statut : " + etat,
      "erreur"
    );
  };

  // FONCTION FILTER
  const functionFiltere = (criteria, filterValue) => {
    setSearch(filterValue);
    setFiltrer(criteria);
  };

  const activer = () => {
    userStatus(tabClient, "activate");
    setMyBool(false);
  };

  const desactiver = () => {
    userStatus(tabClient, "desactivate");
    setMyBool(false);
  };

  return (
    <>
      <NotificationContainer />
      <Container fluid>
        <Title texte="LISTE UTILISATEURS" />
        <div className="row mb-3">
          <FiltreComposant
            functionFiltere={functionFiltere}
            labelCritere="Critère"
            labelFiltre="Filtre"
            listeCritere={["Prénom", "Nom"]}
          />
          <div className="d-flex justify-content-end align-items-center col-6">
            <UserModal
              onRefresh={forceUpdate}
              isOpen={openModal}
              onClose={setOpenModal}
              data={listeDivision}
              phones={listPhoneUsers}
              emails={listUsernameUsers}
            />
            <ButtonIcon
              disabled={false}
              action={handleOpenModal}
              iconName={<FaUserPlus />}
              titre="Nouveau"
            />
            <ButtonIcon
              disabled={generalService.activateButton(tabClient)}
              action={activer}
              iconName={<FaUserCheck />}
              titre="Activer"
            />
            <ButtonIcon
              disabled={generalService.activateButton(tabClient)}
              action={desactiver}
              iconName={<FaUserAltSlash />}
              titre="Désactiver"
            />
          </div>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr className="text-start">
              <th style={{ width: "5vh" }}>
                <Checkbox
                  checked={generalService.valuesExistes(page, tabClient)}
                  onClick={(e) => {
                    generalService.toggle(myBool, setMyBool);
                    generalService.checkedAll(
                      e.target.checked,
                      page,
                      tabClient,
                      setTabClient
                    );
                  }}
                />
              </th>
              <th className="align-middle">N° Utilisateur</th>
              <th className="align-middle">Prénom(s)</th>
              <th className="align-middle">Nom</th>
              <th className="align-middle">Email</th>
              <th className="align-middle">Téléphone</th>
              <th className="align-middle">Adresse</th>
              <th className="align-middle">Rôle</th>
              <th className="align-middle">
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title="Trier boutique"
                >
                  <button
                    onClick={handleSortDivision}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}
                  >
                    Boutique <BiSortAZ className="fs-4" />
                  </button>
                </MDBTooltip>
              </th>
              <th className="align-middle text-center">Statut</th>
              <th className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {myArray.nodes.map((item) => (
              <tr key={item.id ? item.id : "unknown"} className="text-start">
                <td className="align-middle">
                  <Checkbox
                    checked={generalService.testingExistInTable(
                      item,
                      tabClient
                    )}
                    onClick={(e) => {
                      generalService.tabExport(
                        item,
                        tabClient,
                        e.target.checked,
                        setTabClient
                      );
                    }}
                  />
                </td>
                <td className="align-middle">{item.code}</td>
                <td className="align-middle">{item.firstname}</td>
                <td className="align-middle">{item.lastname}</td>
                <td className="align-middle">{item.username}</td>
                <td className="align-middle">{item.phone}</td>
                <td className="align-middle">{item.address}</td>

                <td className="align-middle">{item.role}</td>
                <td className="align-middle">
                  {item.divisionDto == null ? "" : item.divisionDto.name}
                </td>

                <td className="align-middle text-center">
                  <Badge etat={item.status}></Badge>
                </td>
                <td className="align-middle">
                  <div className="d-flex justify-content-center">
                    <MDBTooltip
                      tag="a"
                      wrapperProps={{ color: "#2b4366" }}
                      title="Éditer User"
                    >
                      <EditUserModal
                        onRefresh={forceUpdate}
                        item={item}
                        divisions={listeDivision}
                      />
                    </MDBTooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          items={listeUser.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  );
};

export default UserList;
