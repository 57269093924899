import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FaFileMedical } from "react-icons/fa";
import GestionDesDocuments from "../../Service/GestionDesDocuments";
import { useDispatch } from "react-redux";
import { addDocument } from "../../redux/redux";
import UploadFile from "../Inputs/UploadFile";
import ConsommationService from "../../Service/ConsommationService";



export default function DocumentOrder({ orderId, onRefresh }) {
  const [show, setShow] = useState(false);
  const [demande, setDemande] = useState(orderId);
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [typeDoc, setTypeDoc] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const myFile = (file) => {
    setFile(file);
  };
  const document = {
    order: demande,
    name: nom,
    description: description,
    file: file,
    type: typeDoc,
    status: "CURRENT",
  };

  const addDoc = (document) => {
    GestionDesDocuments.AddDocument(document)
      .then((rest) => {
        dispatch(addDocument(rest.data));
        ConsommationService.notifier(
          "success",
          "Document ajouté avec succés.",
          "Une erreur est survenu lors de l'ajout du document."
        );
        handleClose();
        onRefresh();
      })
      .then(() => {
        setDescription("");
        setNom("");
        setTypeDoc("");
      })
      .catch((err) => {
        ConsommationService.notifier(
          "error",
          "Une erreur est survenu lors de l'ajout du document.",
          "Document ajouté avec succés."
        );
      });
  };

  const Tabtype = [
    {
      label: "Passport",
      value: "PASSPORT",
    },
    {
      label: "Piéce identité",
      value: "IDENTITY_CARD",
    },
    {
      label: "Bulletin salaire",
      value: " SALARY_BULLETIN",
    },
    {
      label: "Permis conduire",
      value: "DRIVING_LICENSE",
    },
    {
      label: "Certificat de Residence",
      value: "RESIDENT_CERTIFICATE",
    },
    {
      label: "Certificat de Docmicile",
      value: "DOMICILE_CERTIFICATE",
    },
    {
      label: "Titre foncier",
      value: "LAND_TITLE",
    },
    {
      label: "Baille",
      value: "BAIL",
    },
    {
      label: "Permis de résidence",
      value: "RESIDENCE_PERMIT",
    },
    {
      label: "Autres",
      value: "OTHER",
    },
  ];
  return (
    <>
      <Button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "black",
          height: "6vh",
          borderRadius: "5px",
        }}
        variant="primary"
        onClick={handleShow}
      >
        <FaFileMedical /> Attacher document
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>AJOUT DOCUMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                placeholder="Nom du document"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description Du Document</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Type Document
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setTypeDoc(e.target.value)}
              >
                <option selected disabled>
                  Type Document
                </option>
                {Tabtype.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Document
              </label>
              <UploadFile myFile={myFile} />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <Button
            variant="secondary"
            style={{ backgroundColor: "black", border: "#2b4366" }}
            onClick={handleClose}
          >
            Annuler
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#2b4366", border: "#2b4366" }}
            onClick={() => {
              addDoc(document);
            }}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<Example />);
