import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'



function phoneNumberInput() {

 const [value,setValue]=useState('')
 console.log();
 const verifDate=(value)=>{
  if (isValidPhoneNumber(value)==false) {
    return true
  }else{
    return
      return false
    
  }
  
 }
  return (
   <>
<PhoneInput
  international
  countryCallingCodeEditable={false}
  defaultCountry="SN"
  name="phoneNumber"
  id="phoneNumber"
  type="text"
  value={value}
  style={{fontSize:'1rem'}}
  maxlength="17"
  onChange={(value)=>{setValue(value);}}
  />
  valid: {value&& isValidPhoneNumber(value)?"ok":"not ok"}
   </>
  )
}

export default phoneNumberInput