import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Badge from "../Badge/Badge";
import Checkbox from "@mui/material/Checkbox";
import { Paginate } from "../Utils/Paginate";
import Pagination from "../Pagination/Pagination";
import { MDBTooltip } from "mdb-react-ui-kit";
import generalService from "../../Service/generalService";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocuments, updateStatusDocument } from "../../redux/redux";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import ConsommationService from "../../Service/ConsommationService";
import Title from "../eng_composants/Title";
import ButtonIcon from "../eng_composants/ButtonIcon";
import GestionDesDocuments from "../../Service/GestionDesDocuments";
import SearchBar from "../Inputs/searchBarFiltter";
import { FaCloudDownloadAlt, FaArchive } from "react-icons/fa";
import AWS from 'aws-sdk';
import { saveAs } from 'file-saver';
import { useParams } from "react-router";



function DocumentsList() {
  const [search, setSearch] = React.useState("");
  const [myBool, setMyBool] = useState(false);
  const [tabBank, setTabBank] = useState([]);
  const dispatch = useDispatch();
  const listeDocuments = useSelector((state) => state.documents.documents);
  const [filtre, setFiltrer] = useState("");
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
  const { etat } = useParams()

  const listSelonStatus = (etat) => {
    if (etat === "documents") {
      return listeDocuments;
    } else {
      return listeDocuments.filter((item) => item.status == etat);
    }
  };



  useEffect(() => {
    GestionDesDocuments.getAllDocument().then((documents) => {
      dispatch(getAllDocuments(documents.data));
    })

  }, []);

  const downloadFileFromS3 = async (keyName, fileName) => {
    // Créez une instance AWS S3 en utilisant les informations d'identification appropriées
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_KEY
    });
    // Récupérez le contenu du fichier depuis S3
    const s3Object = await s3.getObject({ Bucket: BUCKET_NAME, Key: keyName }).promise();
    const fileContent = s3Object.Body;
    // Créez un objet File à partir du contenu du fichier et du nom de fichier spécifié
    const file = new File([fileContent], fileName, { type: s3Object.ContentType });
    const fileUrl = URL.createObjectURL(file);
    saveAs(fileUrl, file.name);
  };



  //Page courant
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };




  // CURRENT, EXPIRED, CANCELED, ARCHIVED
  const documentStatus = (tabDivision, etat) => {
    tabDivision.map((bank) => {
      GestionDesDocuments.changeStatusDocument(bank.id, etat).then(() => {
        switch (etat) {
          case "CURRENT":
            // ConsommationService.notifier(
            //   "success",
            //   "Document(s) Activé(s) avec succès, Statut:CURRENT",
            //   "erreur"
            // );
            dispatch(updateStatusDocument(["CURRENT", bank.id]));
            // tabDivision.length = 0;
            break;
          case "EXPIRED":
            dispatch(updateStatusDocument(["EXPIRED", bank.id]));
            // ConsommationService.notifier(
            //   "success",
            //   "Document(s) Expiré(s) avec succès, Statut:EXPIRED",
            //   "erreur"
            // );
            // tabDivision.length = 0;
            break;
          case "ARCHIVED":
            dispatch(updateStatusDocument(["ARCHIVED", bank.id]));
            // ConsommationService.notifier(
            //   "success",
            //   "Document(s) Désactivé(s) avec succès, Statut:INACTIVE",
            //   "erreur"
            // );
            // tabDivision.length = 0;
            break;
          default:
            // code block to execute if variable does not match any of the values
            break;
        }

      });
    });
    ConsommationService.notifier(
      "success",
      "Document(s)  " + etat + "  avec succès, Statut : " + etat,
      "erreur"
    );
    tabDivision.length = 0;
  };

  const expirer = () => {
    documentStatus(tabBank, "EXPIRED");
    setMyBool(false);
    tabBank.length = 0

  }
  const actualiser = () => {
    documentStatus(tabBank, "CURRENT");
    setMyBool(false);
    tabBank.length = 0

  }

  const archiver = () => {
    documentStatus(tabBank, "ARCHIVED");
    setMyBool(false);
    tabBank.length = 0
  }
  const recherche = (value) => {
    setTabafterSearch(value)

    // console.log(value);

  }



  const afficheTab = () => {
    return tabafterSearch.length == 0 ? listSelonStatus(etat) : tabafterSearch
  }

  //Pagination
  const [tabafterSearch, setTabafterSearch] = useState([])
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const page = Paginate(afficheTab(), currentPage, pageSize);




  return (
    <>
      <Title texte={GestionDesDocuments.title(etat)} />


      <div className="col-12 d-flex flex-row justify-content-between">


        <div className="d-flex col-2 align-self-center">
          <SearchBar documents={listSelonStatus(etat)} recherche={recherche} />
        </div>
        <div className="d-flex col-3.4 align-self-center" >
          <ButtonIcon disabled={generalService.activateButton(tabBank)} action={actualiser} iconName={<BsFillCheckCircleFill />} titre="Actualiser" />
          <ButtonIcon disabled={generalService.activateButton(tabBank)} action={expirer} iconName={<BsFillXCircleFill />} titre="Expirer" />
          <ButtonIcon disabled={generalService.activateButton(tabBank)} action={archiver} iconName={<FaArchive />} titre="Archiver" />
        </div>
      </div>

      <Table striped bordered hover className="mt-4">
        <thead >
          <tr >
            <th className="text-center">
              <Checkbox
                checked={generalService.valuesExistes(page, tabBank)}
                onClick={(e) => {
                  generalService.toggle(myBool, setMyBool);
                  generalService.checkedAll(
                    e.target.checked,
                    page,
                    tabBank,
                    setTabBank
                  );
                }}
              />
            </th>
            <th className="align-middle text-center">N°Demande</th>
            <th className="align-middle">Nom Document</th>
            <th className="align-middle">Description Document</th>
            <th className="align-middle">Type Document</th>
            <th className="align-middle  text-center">Statut</th>
            <th className="align-middle text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {page.map((bank) => (
            <tr key={bank.id}>
              <td className="text-center">
                <Checkbox
                  checked={generalService.testingExistInTable(bank, tabBank)}
                  onClick={(e) => {
                    generalService.tabExport(
                      bank,
                      tabBank,
                      e.target.checked,
                      setTabBank
                    );
                  }}
                />
              </td>
              <td className="align-middle">{bank.orderDto.code}</td>
              <td className="align-middle">{bank.name}</td>
              <td className="align-middle">{bank.description}</td>
              <td className="align-middle">{bank.type}</td>
              <td className="align-middle text-center"><Badge etat={bank.status}></Badge></td>
              <td className="align-middle text-center">
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title={"Télécharger"}>
                  <FaCloudDownloadAlt onClick={() => { downloadFileFromS3(bank.path.substring(48, 100), bank.path.substring(48, 100)) }} style={{ fontSize: "1.3rem", }} />
                </MDBTooltip>
              </td>

            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        items={listSelonStatus(etat).length}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export default DocumentsList;
