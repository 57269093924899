import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Legend, Cell } from 'recharts';
import EquipementService from '../Service/EquipementService';
import generalService from '../Service/generalService';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1919'];

const Chart = () => {
  const [donnees,setDonnees]=useState([])
  useEffect(()=>{
    EquipementService.getDemands().then((data)=>{
      setDonnees(data.data)
  
    })
  
  },[])


  const data = [
    { name: 'Nouvelles', value:generalService.sommeTableau(generalService.getTableMontant(donnees.filter(don=>don.status=="CREATED")))},
    { name: 'Acceptées', value:generalService.sommeTableau(generalService.getTableMontant(donnees.filter(don=>don.status=="ACCEPTED"))) },
    { name: 'Encoyées', value: generalService.sommeTableau(generalService.getTableMontant(donnees.filter(don=>don.status=="SENT")))},
    { name: 'Refusées', value: generalService.sommeTableau(generalService.getTableMontant(donnees.filter(don=>don.status=="REFUSED")))},
  
  ];
  
  return (
    <PieChart width={400} height={400}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#8884d8"
        label
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend />
    </PieChart>
  );
};

export default Chart;
