import React, { useState } from 'react';
import {XYPlot, XAxis, YAxis, VerticalBarSeries, VerticalGridLines, HorizontalGridLines} from 'react-vis';

const BarChart = ({ data }) => {
  const [active, setActive] = useState(null);

  return (
    <div style={{width: '500px', height: '300px'}}>
      <XYPlot xType="ordinal" width={500} height={300}>
        
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        {active && (
        <div style={{background: 'white', padding: 10}}>
          <div>{active.x}: {active.y}</div>
        </div>
      )}
        <VerticalBarSeries
          data={data}
          onValueMouseOver={(d) => setActive(d)}
          onValueMouseOut={() => setActive(null)}
          label={(d) => d.y}
        />
      </XYPlot>
  
    </div>
  );
};

export default BarChart;
