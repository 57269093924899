import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../Service/AuthService';
import generalService from '../../Service/generalService';
export default function UserProfile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate()



  const deconnexion = (e) => {
    AuthService.logout()
    navigate("/")

  }


  let intials = AuthService.getCookie("prenom").charAt(0) + "" + AuthService.getCookie("nom").charAt(0);
  let nomComplet = generalService.firstStringUpperCase(AuthService.getCookie("prenom")) + "\t" + generalService.firstStringUpperCase(AuthService.getCookie("nom"))

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

        <Tooltip title={AuthService.getCookie("prenom") + "" + AuthService.getCookie("nom")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 40, height: 40 }} style={{ backgroundColor: "#1daee7" }}>{intials.toUpperCase()}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> {nomComplet}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> {AuthService.getCookie("role")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { handleClose(); navigate("/password") }}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon >
          Modifier Mot de Passe
        </MenuItem>
        <MenuItem onClick={() => { handleClose() }}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Paramètres
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); deconnexion() }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Se Déconnecter
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}