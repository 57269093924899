import * as React from "react";
import TextField from "@mui/material/TextField";
import generalService from "../../Service/generalService";

export default function InputPrompt({
  value,
  name,
  func,
  label,
  disabled,
  limit,
  type,
  block,
  isRequired
}) {
  return (
    <div className="mb-3">
      <TextField
        fullWidth
        size="small"
        name={name}
        type={type}
        disabled={disabled}
        variant="outlined"
        required={isRequired}
        label={label}
        value={value}
        onChange={(e) => {
          func(e.target.value);
          generalService.handleNumChange(e, func, limit);
          block(
            e.target.value.length > value.length ||
              e.target.value.length < value.length
          );
        }}
      />
    </div>
  );
}
