import React, { useState, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { parseISO, parseJSON } from "date-fns";
import { object, string, date } from "yup";
import * as Yup from "yup";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import PhoneNumberInput from "../../Inputs/PhoneNumberInput";
import dateFormat from "dateformat";
import Title from "../../eng_composants/Title";
import UserService from "../../../Service/UserService";
import { addCustomer } from "../../../redux/redux";

const CustomerForm = () => {
  const [checkValue, setCheckValue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tetsAge = (age) => {
    if (age < 18) {
      return (
        <small className="text-danger">
          Le client doit avoir au moins 18 ans
        </small>
      );
    } else {
      return <small></small>;
    }
  };

  const validationSchema = object({
    firstName: string().required("Le prenom est obligatoire"),
    lastName: string().required("Le nom est obligatoire"),
    email: string().email("email invalide").required("L'email est obligatoire"),
    gender: string().required("Le sexe est obligatoire"),
    birthday: date().required("Date de naissance est obligatoire"),
    job: string().required("La profession est obligatoire"),
    phoneNumber: string().required("Le numero de telephone est obligatoire"),
    jobDuration: string().required("La durée de travail est obligatoire"),
    activitySector: string().required("Le secteur d'activité est obligatoire"),
    activityLocation: string().required("Le lieu de travail est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      gender: "",
      birthday: "",
      phoneNumber: "",
      job: "",
      jobDuration: "",
      activitySector: "",
      activityLocation: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const customerData = {
        ...values,
        birthday: dateFormat(values.birthday, "isoDateTime"),
        status: "ACTIVE",
        typeCustomer: "WEB",
      };

      console.log(customerData)
      // UserService.createClient(customerData)
      //   .then((client) => {
      //     dispatch(addCustomer(customerData));
      //     navigate("/equip/" + client.data.id);
      //   })

        // CustomerService.addCustomer(customerData)
        //   .then((customer) => {
        //     setCustomerId(customer.data.id);
        //     addCustomer(customer.data);
        //     setSubmitting(false);
        // if (typeOrder) {
        //   navigate(`/consommations/new/` + customer.data.id, {
        //     replace: true,
        //   });
        // }
        // navigate(`/equipements/new/` + customer.data.id, { replace: true });

        // .catch((err) => {
        //   console.log(err);
        // });
    },
  });

  // const afficheText = (value) => {
  //   if (isValidPhoneNumber(`${value}`)) {
  //     return <span className="text-success">numéro valide</span>;
  //   } else {
  //     return <span className="text-warning">numéro incomplet</span>;
  //   }
  // };

  const monthTables = [
    "1 mois",
    "2 mois",
    "3 mois",
    "4 mois",
    "5 mois",
    "6 mois",
    "7 mois",
    "8 mois",
    "9 mois",
    "10 mois",
    "11 mois",
    "12 mois",
  ];

  const yearsTables = [
    "1 an",
    "2 ans",
    "3 ans",
    "4 ans",
    "5 ans",
    "6 ans",
    "7 ans",
    "8 ans",
    "9 ans",
    "10 ans",
    "11 ans",
    "12 ans",
  ];

  const activitySectorsTables = [
    { key: "administration", value: "Administration" },
    { key: "administration", value: "Agroalimentaire et Agriculture" },
    { key: "administration", value: "Audit et Comptabilité" },
    { key: "finance", value: "Banque - Finance - Assurance" },
    { key: "btp", value: "Construction - BTP" },
    { key: "economie", value: "Banque - Finance - Assurance" },
    { key: "education", value: "Education - Formation" },
    { key: "industrie", value: "Industrie" },
    { key: "tech", value: "Technologies de l'Information" },
    { key: "RH", value: "Resources Humaines" },
    { key: "sante", value: "Santé - Social" },
    { key: "transport", value: "Transport - Logistique" },
  ];

  return (
    <>
      <div className="d-flex justify-content-center mt-3">
        <Title texte="INFORMATIONS CLIENT" />
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <Form onSubmit={formik.handleSubmit}>
              <div class="row col-md-12">
                <div className="col-md-6">
                  <div className="mb-4">
                    <TextField
                      fullWidth
                      label="Prénom"
                      name="firstName"
                      value={formik.values.firstName}
                      id="outlined-size-small"
                      size="small"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      fullWidth
                      label="Nom"
                      name="lastName"
                      value={formik.values.lastName}
                      id="outlined-size-small"
                      size="small"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={formik.values.email}
                      id="outlined-size-small"
                      size="small"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="mb-4">
                    <FormControl size="small" fullWidth>
                      <InputLabel id="sexe">Sexe</InputLabel>
                      <Select
                        labelId="sexe"
                        id="sexe"
                        name="gender"
                        value={formik.values.gender}
                        label="Sexe"
                        onChange={formik.handleChange}
                      >
                        {[
                          { key: 1, value: "MASCULIN" },
                          { key: 2, value: "FEMININ" },
                        ].map((gender) => (
                          <MenuItem key={gender.key} value={gender.value}>
                            {gender.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mb-4">
                    <TextField
                      fullWidth
                      type="date"
                      // label="Date de Naissance"
                      variant="outlined"
                      name="birthday"
                      value={formik.values.birthday}
                      size="small"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.birthday &&
                        Boolean(formik.errors.birthday)
                      }
                      helperText={
                        formik.touched.birthday && formik.errors.birthday
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-4">
                    <PhoneNumberInput
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="mb-4">
                    <TextField
                      fullWidth
                      label="Profession"
                      name="job"
                      value={formik.values.job}
                      size="small"
                      onChange={formik.handleChange}
                      error={formik.touched.job && Boolean(formik.errors.job)}
                      helperText={formik.touched.job && formik.errors.job}
                    />
                  </div>

                  <div className="mb-4">
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-select-small-label">
                        Secteur d'activité
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="activitySector"
                        value={formik.values.activitySector}
                        label="Secteur d'activité"
                        onChange={formik.handleChange}
                      >
                        {activitySectorsTables.map((activity) => (
                          <MenuItem key={activity.key} value={activity.key}>
                            {activity.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mb-3">
                    <TextField
                      fullWidth
                      label="Localité de l'activité"
                      name="activityLocation"
                      value={formik.values.activityLocation}
                      size="small"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.activityLocation &&
                        Boolean(formik.errors.activityLocation)
                      }
                      helperText={
                        formik.touched.activityLocation &&
                        formik.errors.activityLocation
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-around mb-4">
                    <FormControl fullWidth size="small">
                      <div className="d-flex align-items-center  row-cols-12">
                        <div className="d-flex align-items-center col-4">
                          <p>Durée de travail</p>
                        </div>
                        <div className="d-flex align-items-center col-4">
                          <RadioGroup
                            row
                            aria-labelledby="select-label"
                            name="controlled-radio-buttons-group"
                            onChange={(event) =>
                              setCheckValue(event.target.defaultValue)
                            }
                          >
                            <FormControlLabel
                              value="mois"
                              control={<Radio />}
                              label="Mois"
                            />
                            <FormControlLabel
                              value="annee"
                              control={<Radio />}
                              label="Annee"
                            />
                          </RadioGroup>
                        </div>
                        <div className="col-4">
                          <Select
                            sx={{ width: "130px" }}
                            size="small"
                            labelId="select-label"
                            id="select"
                            name="jobDuration"
                            value={formik.values.jobDuration}
                            onChange={formik.handleChange}
                          >
                            {checkValue === "mois"
                              ? monthTables.map((element) => (
                                  <MenuItem key={element} value={element}>
                                    {element}
                                  </MenuItem>
                                ))
                              : yearsTables.map((element) => (
                                  <MenuItem key={element} value={element}>
                                    {element}
                                  </MenuItem>
                                ))}
                          </Select>
                        </div>
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-4 gap-4">
                  <Link to="/demandes">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "black" }}
                    >
                      Annuler
                    </Button>
                  </Link>

                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#2b4366" }}
                    // onClick={() => setTypeOrder(true)}
                    className={
                      formik.isValid == true && "is-valid text-secondary"
                    }
                    disabled={formik.isValid}
                  >
                    Demande Equipement
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerForm;
