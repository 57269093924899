import Cookies from "js-cookie";
import api from "./Api";
import AuthService from "./AuthService";

// const URL="/api/references/organizations"
const URL="/api/references/lenders"
const URL_LOGO="/api/documents/lender/logo"

class InstitutionService {

  addLogo(logo) {
    const formData = new FormData();
    formData.append("lender",logo.lender)
    formData.append("file",logo.file)

    const config = {
     headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${Cookies.get('token')}`,
        }
            }

    return  api.post(URL_LOGO,formData,config)
    //   return  post(url,formData,config)

}

  
  
  getInstitution() {
    return api.get(URL,AuthService.getToken("token"));
  }

  

  createLender(lender) {
    return api.post(URL,lender,AuthService.getToken("token"));
  }


 // Exemple de requête POST avec un token d'authentification
 postData = async (ins) => {
  try {
    const token = AuthService.getCookie('token'); // Remplacez par votre propre token
    const data = {ins};
    const response = await api.post(URL, data, {
      headers: {
        'Authorization': `Bearer ${token}` // Ajoutez votre token d'authentification dans l'en-tête
      }
    });
    console.log(response.data); // Traitement de la réponse
  } catch (error) {
    console.error(error); // Gestion des erreurs
  }
};

editInstitution=(objet, id)=> {
  return api.put(URL + "/" + id,objet, AuthService.getToken("token"))
    .then((objet) => {});
}

 updateInstitution = (userId, userData) => {
  api.put(URL+`/${userId}`, userData)
    .then(response => {
      // La mise à jour a réussi, gérer les données mises à jour
      console.log(response.data);
    })
    .catch(error => {
      // La mise à jour a échoué, gérer l'erreur
      console.error(error);
    });
}


}

export default new InstitutionService();