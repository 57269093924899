import { NotificationManager } from "react-notifications";
import api from "./Api";
import AuthService from "./AuthService";

const url = "/api/orders?page=0&size=1000&sort=";
const CONSOMMATION_API_URL = "/api/orders";

class ConsommationService {
  getDemands() {
    return api.get(url, AuthService.getToken("token"));
  }

  getDemandById(demandId) {
    return api.get(
      CONSOMMATION_API_URL + "/" + demandId,
      AuthService.getToken("token")
    );
  }

  createDemand(demand) {
    return api.post(
      CONSOMMATION_API_URL,
      demand,
      AuthService.getToken("token")
    );
  }

  setOffer(id, demand) {
    return api.put(url + "/" + id, demand, AuthService.getToken("token"));
  }

  notifier = (type, textSuccess, textError) => {
    if (type == "error") {
      return NotificationManager.error(textError, "", 2000);
    } else if ("success") {
      return NotificationManager.success(textSuccess, "", 2000);
    }
  };

  editDemandeConso(objet, id) {
    return api.put(
      CONSOMMATION_API_URL + "/" + id,
      objet,
      AuthService.getToken("token")
    );
  }
}

export default new ConsommationService();
