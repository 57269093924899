import React, { useEffect, useState } from "react";
import "./myStyles.css";
import "./container.less";
import "rsuite/dist/rsuite.css";

import { Container, Sidebar, Sidenav, Navbar, Nav } from "rsuite";
import CogIcon from "@rsuite/icons/legacy/Cog";
import FolderFill from "@rsuite/icons/FolderFill";
import Storage from "@rsuite/icons/Storage";
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch";
// FaUsersCog

import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import DashboardIcon from "@rsuite/icons/Dashboard";
import AdminIcon from "@rsuite/icons/Admin";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import { Link } from "react-router-dom";
import ListIcon from "@rsuite/icons/List";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import TableIcon from "@rsuite/icons/Table";
import NavBar from "../NavBar/NavBar";
import AuthService from "../../Service/AuthService";
import { setDataDemandesEquipement, store } from "../../redux/redux";
import EquipementService from "../../Service/EquipementService";
import { useDispatch } from "react-redux";

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: "#34c3ff",
  color: " #fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav>
        <Nav.Menu
          noCaret
          placement="topStart"
          trigger="click"
          title={<CogIcon style={{ width: 20, height: 20 }} size="sm" />}
        >
          <Nav.Item>Help</Nav.Item>
          <Nav.Item>Settings</Nav.Item>
          <Nav.Item>Sign out</Nav.Item>
        </Nav.Menu>
      </Nav>

      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const SideMenu = ({ content }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (AuthService.isAgent(AuthService.getCookie("role"))) {
      EquipementService.getOrderDivisionUser(
        AuthService.getCookie("user")
      ).then((res) => {
        dispatch(setDataDemandesEquipement(res.data.reverse()));
      });
    } else {
      EquipementService.getDemands().then((dmd) => {
        dispatch(
          setDataDemandesEquipement(
            dmd.data
              .filter((element) => element.typeOrder === "EQUIPEMENT")
              .reverse()
          )
        );
      });
    }
  }, []);

  const [expand, setExpand] = React.useState(true);
  const [newDemandes, setNewDemandes] = useState(0);
  const [demandeEnvoye, setDemandeEnvoye] = useState(0);
  const [demandeAccepte, setDemandeAccepte] = useState(0);
  const [demandeRefuse, setDemandeRefuse] = useState(0);

  useEffect(() => {
    store.subscribe(() => {
      const newDemandesCount = store
        .getState()
        .equipements.demandesEquipement.filter(
          (element) => element.status === "CREATED"
        ).length;
      const demandeAccepteCount = store
        .getState()
        .equipements.demandesEquipement.filter(
          (element) => element.status === "ACCEPTED"
        ).length;
      const demandeRefuseCount = store
        .getState()
        .equipements.demandesEquipement.filter(
          (element) => element.status === "REFUSED"
        ).length;
      const demandeEnvoyeCount = store
        .getState()
        .equipements.demandesEquipement.filter(
          (element) => element.status === "SENT"
        ).length;
      setNewDemandes(newDemandesCount);
      setDemandeAccepte(demandeAccepteCount);
      setDemandeRefuse(demandeRefuseCount);
      setDemandeEnvoye(demandeEnvoyeCount);
      // Enregistrer les valeurs dans le localStorage
      localStorage.setItem("newDemandesCount", newDemandesCount);
      localStorage.setItem("demandeAccepteCount", demandeAccepteCount);
      localStorage.setItem("demandeRefuseCount", demandeRefuseCount);
      localStorage.setItem("demandeEnvoyeCount", demandeEnvoyeCount);
    });
  }, []);

  useEffect(() => {
    const newDemandesCount = localStorage.getItem("newDemandesCount");
    const demandeAccepteCount = localStorage.getItem("demandeAccepteCount");
    const demandeRefuseCount = localStorage.getItem("demandeRefuseCount");
    const demandeEnvoyeCount = localStorage.getItem("demandeEnvoyeCount");

    if (newDemandesCount) setNewDemandes(Number(newDemandesCount));
    if (demandeAccepteCount) setDemandeAccepte(Number(demandeAccepteCount));
    if (demandeRefuseCount) setDemandeRefuse(Number(demandeRefuseCount));
    if (demandeEnvoyeCount) setDemandeEnvoye(Number(demandeEnvoyeCount));
  }, []);

  const taches = [
    {
      tache: "Nouvelles Demandes",
      nombre: newDemandes,
      lien: "/demands/CREATED",
    },
    {
      tache: "Demandes Acceptées",
      nombre: demandeAccepte,
      lien: "/demands/ACCEPTED",
    },
    {
      tache: "Demandes Envoyées",
      nombre: demandeEnvoye,
      lien: "/demands/SENT",
    },

    {
      tache: "Demandes Refusées",
      nombre: demandeRefuse,
      lien: "/demands/REFUSED",
    },
  ];

  return (
    <div className="show-fake-browser sidebar-page">
      <NavBar myArray={taches} />
      <Container style={{ color: "black" }}>
        <Sidebar
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#272c36",
            marginTop: "2.5rem",
            position: "fixed",
            overflowY: "scroll",
            top: 0,
            bottom: 0,
          }}
          width={expand ? 260 : 56}
          collapsible
        >
          <Sidenav
            expanded={expand}
            defaultOpenKeys={["1", "3", "2", "5", "4", "6", "7", "8", "9"]}
            appearance="subtle"
          >
            <Sidenav.Body>
              <Nav>
                <Nav.Item eventKey="0" icon={<MagicIcon />}>
                  <Link
                    to="/home"
                    style={{ color: "#1daee7", fontSize: "1.2rem" }}
                  >
                    Accueil
                  </Link>
                </Nav.Item>

                <Nav.Menu
                  style={{ display: AuthService.allAutorisation() }}
                  eventKey="4"
                  trigger="hover"
                  title="Clients"
                  icon={<GroupIcon />}
                  placement="rightStart"
                >
                  <Nav.Item eventKey="4" icon={<GroupIcon />}>
                    <Link
                      to="/clients"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Clients
                    </Link>
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  eventKey="5"
                  trigger="hover"
                  title="Demandes"
                  icon={<ListIcon />}
                  placement="rightStart"
                >
                  <Nav.Item eventKey="5-3" icon={<ExpandOutlineIcon />}>
                    <Link
                      to="/client"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Demander
                    </Link>{" "}
                  </Nav.Item>
                  <Nav.Item eventKey="5-2" icon={<TableIcon></TableIcon>}>
                    <Link
                      to="/demands/equipement"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Equipements
                    </Link>{" "}
                  </Nav.Item>
                  <Nav.Item eventKey="5-3" icon={<TableIcon />}>
                    <Link
                      to="/demands/CREATED"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Créées
                    </Link>{" "}
                  </Nav.Item>
                  <Nav.Item eventKey="5-3" icon={<TableIcon />}>
                    <Link
                      to="/demands/SENT"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Envoyées
                    </Link>{" "}
                  </Nav.Item>
                  <Nav.Item eventKey="5-3" icon={<TableIcon />}>
                    <Link
                      to="/demands/ACCEPTED"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Acceptées
                    </Link>{" "}
                  </Nav.Item>
                  <Nav.Item eventKey="5-3" icon={<TableIcon />}>
                    <Link
                      to="/demands/REFUSED"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Refusées
                    </Link>{" "}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  style={{ display: AuthService.userAutorisation("ADMIN") }}
                  eventKey="6"
                  trigger="hover"
                  title="Tiers"
                  icon={<Storage />}
                  placement="rightStart"
                >
                  <Nav.Item eventKey="6-5" icon={<ExpandOutlineIcon />}>
                    <Link
                      to="/institution/1"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Institution
                    </Link>
                  </Nav.Item>
                  <Nav.Item eventKey="6-3" icon={<TableIcon />}>
                    <Link
                      to="/divisions"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Boutiques
                    </Link>
                  </Nav.Item>

                  <Nav.Item eventKey="6-1" icon={<TableIcon></TableIcon>}>
                    <Link
                      to="/banks"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Banques
                    </Link>
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  style={{ display: AuthService.allAutorisation() }}
                  eventKey="7"
                  trigger="hover"
                  title="Statistique"
                  icon={<DashboardIcon />}
                  placement="rightStart"
                >
                  <Nav.Item eventKey="7-1" icon={<DashboardIcon />}>
                    <Link
                      to="/statistique"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Tableau de Bord
                    </Link>
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  style={{ display: AuthService.allAutorisation() }}
                  eventKey="8"
                  trigger="hover"
                  title="Documents"
                  icon={<FolderFill />}
                  placement="rightStart"
                >
                  <Nav.Item eventKey="8-1" icon={<FolderFill />}>
                    <Link
                      to="/document/documents"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Recherche
                    </Link>
                  </Nav.Item>
                  <Nav.Item eventKey="8-2" icon={<FolderFill />}>
                    <Link
                      to="/document/ARCHIVED"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Archivés
                    </Link>
                  </Nav.Item>
                </Nav.Menu>

                <Nav.Menu
                  style={{ display: AuthService.userAutorisation("ADMIN") }}
                  eventKey="9"
                  trigger="hover"
                  title="Administration"
                  icon={<PeopleBranchIcon />}
                  placement="rightStart"
                >
                  <Nav.Item eventKey="9-1" icon={<AdminIcon />}>
                    <Link
                      to="/roles"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Rôles
                    </Link>
                  </Nav.Item>
                  <Nav.Item eventKey="9-2" icon={<TableIcon />}>
                    <Link
                      to="/users"
                      style={{ color: "#1daee7", fontSize: "1.2rem" }}
                    >
                      Utilisateurs
                    </Link>
                  </Nav.Item>
                </Nav.Menu>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
        </Sidebar>

        <Container className="m-4 longSideMenu">
          <div
            style={{
              marginLeft: "17rem",
              marginRight: "2rem",
              marginTop: "4rem",
            }}
          >
            {content}
          </div>
        </Container>
      </Container>
    </div>
  );
};
export default SideMenu;
