import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate,  useParams } from "react-router-dom";

import UserService from "../../../Service/UserService";
import EquipementService from "../../../Service/EquipementService";
import { Button, Form } from "react-bootstrap";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import BanqueService from "../../../Service/BanqueService";
import ConsommationService from "../../../Service/ConsommationService";
import generalService from "../../../Service/generalService";

import { addDemandeEquipement } from "../../../redux/redux";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AuthService from "../../../Service/AuthService";

const EquipementForm = () => {
  const [client, setClient] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    phoneNumber: "",
  });
  const [banques, setBanques] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    UserService.getClientById(id).then((res) => {
      setClient({
        clientId: res.data.id,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        birthday: generalService.formatDate(res.data.birthday),
        phoneNumber: res.data.phoneNumber,
      });
    });

    BanqueService.getBanques().then((rest) => {
      setBanques(rest.data.filter((element) => element.status === "ACTIVE"));
    });
  }, []);

  const navigate = useNavigate();


  const formik = useFormik({
    initialValues: {
      description: "",
      sectorActivity: "",
      includeInsurance: false,
      remboursement: "",
      contrat: "",
      operator: "",
      identity: "",
      employer: "",
      duration: "",
      salary: "",
      income: 0,
      currentCredit: 0,
      propertyValue: "",
      amount: 0,
      bankDto: {
        id: "",
      },
    },
    onSubmit: (values) => {
      const orderEquipData = {
        ...values,
        bankDto: {
          id: parseInt(values.bankDto, 10),
        },
        lendersDto: [
          {
            id: 1,
          },
        ],
        userDto: {
          id: AuthService.getCookie("user"),
        },
        customerDto: {
          id: client.clientId,
        },
        typeOrder: "EQUIPEMENT",
        status: "CREATED",
        application: "WEB",
      };

      EquipementService.createDemand(orderEquipData)
        .then(() => {
          dispatch(addDemandeEquipement(orderEquipData));
          ConsommationService.notifier(
            "success",
            "Votre Demande a été enregistrée avec succès",
            "erreur"
          );
          navigate("/demands/CREATED");
        })
        .catch(() => {
          ConsommationService.notifier(
            "error",
            "Votre Demande a été enregistrée avec succès",
            "Une erreur est survenue lors de la création de votre demande, Veuillez réessayer !"
          );
        });
    },
  });

  const typeDeContrat = ["CDI", "CDD", "STAGE", "PRESTATION"];

  const durreePret = [3, 6, 12];

  function checkLength(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  function onlyNumberValue(e) {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
  }

  return (
    <div className="container ">
      <NotificationContainer />
      <h3 className="text-center mt-1 mb-2 fw-bold">INFORMATIONS CLIENT</h3>
      <div className="row">
        <div className="col-6">
          <label htmlFor="description">
            <b>Nom</b>
          </label>
          <input
            className="form-control form-control-sm mb-2"
            type="text"
            disabled
            value={client.lastName}
          ></input>
          <label htmlFor="description">
            <b>Prénom(s)</b>
          </label>
          <input
            disabled
            className="form-control form-control-sm"
            type="text"
            value={client.firstName}
          ></input>
        </div>
        <div className="col-6">
          <label htmlFor="description">
            <b>Date Naissance</b>
          </label>
          <input
            className="form-control form-control-sm mb-2"
            type="text"
            disabled
            value={client.birthday}
          ></input>
          <label htmlFor="description">
            <b>Téléphone</b>
          </label>
          <input
            className="form-control form-control-sm"
            type="text"
            disabled
            value={formatPhoneNumberIntl(client.phoneNumber)}
          ></input>
        </div>
      </div>

      <h3 className="text-center m-5">INFORMATIONS DEMANDE</h3>
      <div className="col-md-12">
        <Form onSubmit={formik.handleSubmit}>
          <div className="row col-md-12">
            <div className="col-6">
              <div className="demande col-md-12">
                {/* description input */}
                <div className="form-group mb-2">
                  <label htmlFor="description" className="fw-bold">
                    Description Article <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    style={{ marginBottom: "0.7rem" }}
                    name="description"
                    rows="4"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    required
                    maxLength={200}
                  />
                </div>

                {/* employer input */}
                <div className="form-group mb-3">
                  <label htmlFor="employer" className="fw-bold">
                    Employeur <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="employer"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    value={formik.values.employer}
                    min={0}
                    required
                    maxLength={25}
                    onInput={(e) => {
                      checkLength(e);
                    }}
                  />

                  <small className="text-warning">
                    {generalService.alertMessage(
                      "La valeur du bien est limité à 9 chiffres",
                      formik.initialValues.propertyValue,
                      9
                    )}
                  </small>
                </div>

                {/* propertyValue input */}
                <div className="form-group mb-3">
                  <label htmlFor="propertyValue" className="fw-bold">
                    Valeur Bien <span className="text-danger">*</span>
                  </label>
                  <input
                    id="propertyValue"
                    type="text"
                    name="propertyValue"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    value={formik.values.propertyValue}
                    min={1}
                    required
                    maxLength={10}
                    onInput={(e) => {
                      checkLength(e);
                      onlyNumberValue(e);
                    }}
                  />
                  <small className="text-warning">
                    {generalService.alertMessage(
                      "La valeur du bien est limité à 9 chiffres",
                      formik.initialValues.propertyValue,
                      9
                    )}
                  </small>
                </div>

                {/* propertyValue input */}
                <div className="form-group mb-3">
                  <label htmlFor="amount" className="fw-bold">
                    Montant Apport Personnel{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    id="amount"
                    type="text"
                    name="amount"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                    min={0}
                    maxLength={10}
                    required
                    onInput={(e) => {
                      checkLength(e);
                      onlyNumberValue(e);
                    }}
                  />
                  <p className="text-warning">
                    {generalService.alertMessageValeurBien(
                      "L'apport doit etre inférieur a la valeur du bien",
                      formik.values.amount,
                      formik.values.propertyValue
                    )}
                  </p>
                  <small className="text-warning">
                    {generalService.alertMessage(
                      "Le montant de l'apport  est limité à 9 chiffres",
                      formik.initialValues.amount,
                      9
                    )}
                  </small>
                </div>

                {/* select duration input */}
                <div className="form-group mb-3">
                  <label htmlFor="duration" className="fw-bold">
                    Durée Prêt <span className="text-danger">*</span>
                  </label>
                  <select
                    id="duration"
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                    name="duration"
                    onChange={formik.handleChange}
                    value={formik.values.duration}
                    required
                  >
                    <option selected>Veuillez choisir la durée de pret</option>
                    {durreePret.map((durreePret) => (
                      <option key={durreePret} value={durreePret}>
                        {durreePret} mois
                      </option>
                    ))}
                  </select>
                </div>

                {/* question input */}
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  onChange={formik.handleChange}
                  value={formik.values.includeInsurance}
                >
                  <div className="fw-bold mb-2">
                    Voulez vous être contacter par email ou par téléphone ?{" "}
                    <span className="text-danger">*</span>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="includeInsurance"
                      id="email"
                      value="true"
                    />
                    <label className="form-check-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="includeInsurance"
                      id="phone"
                      value="false"
                    />
                    <label className="form-check-label" htmlFor="phone">
                      Téléphone
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              {/*  contract  input */}
              <div className="form-group mb-3">
                <label htmlFor="contrat" className="fw-bold">
                  <b>
                    Type Contrat Travail <span className="text-danger">*</span>
                  </b>
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  name="contrat"
                  onChange={formik.handleChange}
                  value={formik.values.contrat}
                  required
                >
                  <option selected>Veuillez choisir un type de contrat</option>
                  {typeDeContrat.map((typecontrat) => (
                    <option key={typecontrat} value={typecontrat}>
                      {typecontrat}
                    </option>
                  ))}
                </select>
              </div>

              {/*  identity  input */}
              <div className="form-group mb-2">
                <label htmlFor="identity" className="fw-bold">
                  Numéro Piéce d'identité <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  name="identity"
                  className="form-control form-control-sm"
                  onChange={formik.handleChange}
                  value={formik.values.identity}
                  min={0}
                  required
                  maxLength={29}
                  onInput={(e) => {
                    checkLength(e);
                    onlyNumberValue(e);
                  }}
                />
                <small className="text-warning">
                  {generalService.alertMessage(
                    "La valeur du bien est limité à 9 chiffres",
                    formik.initialValues.propertyValue,
                    9
                  )}
                </small>
              </div>

              {/*  salary  input */}
              <div className="form-group mb-3">
                <label htmlFor="salaire" className="fw-bold">
                  Montant Salaire <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="salaire"
                  name="salary"
                  className="form-control form-control-sm"
                  onChange={formik.handleChange}
                  value={formik.values.salary}
                  required
                  min={0}
                  maxLength={10}
                  onInput={(e) => {
                    checkLength(e);
                    onlyNumberValue(e);
                  }}
                />

                <small className="text-warning">
                  {generalService.alertMessage(
                    "Le salaire est limité à 9 chiffres",
                    formik.initialValues.salary,
                    9
                  )}
                </small>
              </div>

              {/* Select income  input */}
              <div className="form-group mb-3">
                <label htmlFor="revenu" className="fw-bold">
                  Revenu supplémentaire <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="income"
                  className="form-control form-control-sm"
                  onChange={formik.handleChange}
                  value={formik.values.income}
                  required
                  min={0}
                  maxLength={10}
                  onInput={(e) => {
                    checkLength(e);
                    onlyNumberValue(e);
                  }}
                />
                {Number(formik.values.income) === 0 && (
                  <small className="text-warning">
                    {generalService.alertMessage(
                      "Le Revenu doit etre superieur à 0",
                      formik.initialValues.income,
                      9
                    )}
                  </small>
                )}
                <small className="text-warning">
                  {generalService.alertMessage(
                    "Le Revenu est limité à 9 chiffres",
                    formik.initialValues.income,
                    9
                  )}
                </small>
              </div>

              {/* currentCredit input */}
              <div className="form-group mb-3 ">
                <label htmlFor="currentCredit" className="fw-bold">
                  Crédit mensuel en cours <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="currentCredit"
                  name="currentCredit"
                  className="form-control form-control-sm"
                  onChange={formik.handleChange}
                  value={formik.values.currentCredit}
                  min={0}
                  required
                  maxLength={10}
                  onInput={(e) => {
                    checkLength(e);
                    onlyNumberValue(e);
                  }}
                />

                {formik.values.currentCredit.length === 9 && (
                  <small className="text-warning">
                    Le crédit mensuel est limité à 9 chiffres
                  </small>
                )}
              </div>
              {/* Select banque  input */}
              <div className="form-group mb-3">
                <label htmlFor="banque">
                  <b>
                    Banque Principale <span className="text-danger">*</span>
                  </b>
                </label>
                <select
                  className="form-select form-select-sm  mb-3"
                  aria-label=".form-select-sm example"
                  name="bankDto"
                  onChange={formik.handleChange}
                  value={formik.values.bankDto.id}
                  required
                >
                  <option selected>Veuillez choisir une banque</option>
                  {banques.map((operator) => (
                    <option key={operator.id} value={operator.id}>
                      {operator.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Fréquence remboursement  input */}
              <div>
                <div className="fw-bold mb-2">
                  Fréquence remboursement <span className="text-danger">*</span>
                </div>
                <div
                  className="d-flex gap-2 pb-2"
                  role="group"
                  aria-labelledby="my-radio-group"
                  onChange={formik.handleChange}
                  value={formik.values.remboursement}
                >
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        id="remboursement1"
                        type="radio"
                        name="remboursement"
                        value="MENSUALITE_EGALE"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="remboursement1"
                      >
                        Mensualités égales
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="remboursement2"
                        type="radio"
                        name="remboursement"
                        value="PLAN_DIFFERE"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="remboursement2"
                      >
                        Plans differés
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="remboursement3"
                        type="radio"
                        name="remboursement"
                        value="AUTRE"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="remboursement3"
                      >
                        Autres
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* accepts terms input */}
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="acceptTerms"
              style={{ fontSize: "1.2rem", marginLeft: "30rem" }}
              id="acceptTerms"
              onChange={formik.handleChange}
              value={formik.values.acceptTerms}
              required
            />
            <label
              htmlFor="acceptTerms"
              className="form-check-label fw-bold"
              style={{ marginLeft: "1rem", marginTop: "0.4rem" }}
            >
              J'ai lu et{" "}
              <span style={{ color: "red" }}>accepté les conditions</span>{" "}
            </label>
          </div>

          <div className="d-flex align-items-center justify-content-center gap-4 mt-5">
            <Button
              type="button"
              style={{
                backgroundColor: "black",
                border: "#2b4366",
                width: "20%",
              }}
              onClick={() => {
                navigate("/demands/equipement");
              }}
            >
              Annuler
            </Button>
            <Button
              type="submit"
              style={{
                backgroundColor: "#2b4366",
                border: "#2b4366",
                width: "20%",
              }}
            >
              Demander
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EquipementForm;
