import NavBar from "../../NavBar/NavBar"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import UserService from "../../../Service/UserService";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import  { useState } from 'react';
import EquipementService from "../../../Service/EquipementService";
import Example from "../../Notification/NotificationCompenent";
import {Button} from"react-bootstrap"
import generalService from "../../../Service/generalService";
import dateFormat from "dateformat";
import ReactPhoneNumber from "../../ComposantsElementaire/ReactPhoneNumber"

import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input'
// import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import ConsommationService from "../../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";

const UserForm = () =>{
    const client=EquipementService.idClient
    // const [name,setName]=useState("")
    const values = [true];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [age,setAge]=useState()


    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Le nom est obligatoire"),
        surname: Yup.string()
            .required("Le prenom est obligatoire"),
        email: Yup.string()
            .email("email invalide")
            .required("L'email est obligatoire"),


        gender: Yup.string()
            .required("Le sexe est obligatoire"),

        // birthday: Yup.string()
        // .required("La date de naissance est obligatoire"),
        
        // phoneNumber: Yup.string()
        // .required("Le Numero de Téléphone est obligatoire"),

        job: Yup.string()
        .required("La profession est obligatoire"),

     
        nombre: Yup.string()
        .required("Le nombre d'année ou mois de travail est obligatoire"),

        duree: Yup.string()
        .required("La durée de travail est obligatoire"),

        activitySector: Yup.string()
        .required("Le secteur d'activité est obligatoire"),

        activityLocation: Yup.string()
        .required("Le lieu de travail est obligatoire"),
    });

    const tetsAge=(age)=>{
        if (age<18) {
            return  <small  className="text-danger">Le client doit avoir au moins 18 ans</small>
        }
        else{
            return <small></small>

        }

    }

    const initialValues = {
        name: EquipementService.idClient.name,
        surname: EquipementService.idClient.surname,
        gender: EquipementService.idClient.gender,
        email: EquipementService.idClient.email,
        birthday: EquipementService.idClient.birthday,
        phoneNumber:  EquipementService.idClient.phoneNumber,
        job: EquipementService.idClient.job,
        duree: EquipementService.idClient.duree,
        nombre: nombre,
        jobDuration: EquipementService.idClient.jobDuration,
        activitySector:EquipementService.idClient.activitySector,
        activityLocation: EquipementService.idClient.activityLocation,
        acceptTerms: false,
    };

 
    const [conso,setConso]=useState()
    const [equip,setEquip]=useState("")
    const [duree,setDuree]=useState("")
    const [nombre,setNombre]=useState("")
    // const [gender,setGender]=useState("")
    // const [activitySector,setActivitySector]=useState("")

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [role, setRole] = useState("");
    const [username, setUsername] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [birthday, setBirthday] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [job, setJob] = useState('')
    const [jobDuration, setJobDuration] = useState('')
    const [activitySector, setActivitySector] = useState('')
    const [activityLocation, setActivityLocation] = useState('')
const [deactive,setDeactive]=useState(true)



const navigate=useNavigate()

const [value,setValue]=useState("")
const [fermer,setFermer]=useState(false)


function myRedirect() {
    navigate("/users")
}

const afficheText = (value) => {
  if (isValidPhoneNumber(`${value}`)) {
    return <span className="text-success">numéro valide</span>;
  } else {
    return <span className="text-warning">numéro incomplet</span>;
  }
};




const customer = {
  lastname: name,
  firstname: surname,
  username: username,
  phone: value,
  address: address,
  role: role
  
};
//"password":password
// console.log(customer);
// console.log(isValidPhoneNumber(`${value}`));
    const handleSubmit = () => {
        UserService.createUser(customer).then((user)=>{
            console.log(user.data);
            ConsommationService.notifier("success","Utilisateur enregistré avec succèes","erreur")
            setFermer(true)
            setTimeout(myRedirect,3000)
        }).catch(err => {
            ConsommationService.notifier("error","Utilisateur enregistré avec succèes","Une erreur est survenue lors de la création d'utilisateur, Veuillez réessayer !")
            console.log(err);
        })
  

    
   
       
    };

    return (
      <>
        <div className="container mt-5">
          <div className="row">
            <h1 className="text-center m-5">INFORMATIONS UTILISATEUR</h1>
            <div className="col-md-12">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={() => handleSubmit()}>
                {({ resetForm }) => (
                  <Form>
                    <NotificationContainer />
                    <div class="row col-md-12 justify-content-center">
                      <div className="gauche col-md-6">
                        <div className="form-group">
                          <label htmlFor="firstName" className="mb-2">
                            <b>Prénom(s)</b>
                          </label>
                          <Field
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="Saisir Prénom(s)"
                            className="form-control mb-2"
                            onChange={(e) => {
                              setSurname(e.target.value);
                            }}
                          />
  
                        </div>
                        <div className="form-group">
                          <label htmlFor="name" className="mb-2">
                            <b> Nom</b>
                          </label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Saisir Nom"
                            className="form-control mb-2"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        
                        </div>
                        <div className="form-group">
                          <label htmlFor="email" className="mb-2">
                            <b>Email</b>
                          </label>
                          <Field
                            style={{ height: "25px" }}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Saisir Email"
                            className="form-control mb-2"
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                          />
                      
                        </div>
                        <div className="form-group mb-2">
                          <label htmlFor="phoneNumber" className="mb-2">
                            <b>Téléphone {afficheText(value)}</b>
                          </label>

                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="SN"
                            placeholder="Enter phone number"
                            value={value}
                            onChange={setValue}
                            error={
                              value
                                ? isValidPhoneNumber(value)
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address" className="mb-2">
                            <b> Adresse</b>
                          </label>
                          <Field
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Saisir Adresse"
                            className="form-control mb-2"
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          />
                        </div>
                       
                        <div className="form-group">
                          <label htmlFor="activitySector" className="mb-2">
                            <b>Rôle Utilisateur</b>
                          </label>
                          <Field
                            as="select"
                            id="activitySector"
                            className="form-control form-select-sm  mb-3"
                            aria-label=".form-select-sm example"
                            name="activitySector"
                            onChange={(e) => {
                              setRole(e.target.value);
                            }}>
                            <option selected disabled>
                              Veuillez sélectionner le rôle de l'utilisateur
                            </option>

                            <option value="AGENT">Agent</option>
                         
                          </Field>
                          
                        </div>
                        
                      </div>
                      <div className="form-group d-flex justify-content-center gap-4">
                        <Button
                          type="button"
                          onClick={resetForm}
                          className="btn btn-dark"
                          href="/users">
                          Annuler
                        </Button>
                        <button
                          type="submit"
                          className="btn btn-success"
                          style={{
                            backgroundColor: "#2b4366",
                            border: "#2b4366",
                          }}
                          disabled={
                            customer.name == "" ||
                            customer.surname == "" ||
                            customer.username == "" ||
                            customer.value == "" ||
                            customer.role == "" ||
                            customer.address == ""
                              ? deactive
                              : !deactive
                          }>
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Example />
      </>
    );
}

export  default UserForm