import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string, number } from "yup";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import InputOutline from "../Inputs/InputOutline";
import { useNavigate } from "react-router-dom";
import ConsommationService from "../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";
import { Form } from "react-bootstrap";
import { AiFillFileAdd } from "react-icons/ai";
import SelectInput from "../Inputs/SelectInput";
import DivisionService from "../../Service/DivisionService";
import InstitutionService from "../../Service/InstitutionService";
import PhonNumerInput from "../Inputs/PhonNumerInput";
import { useDispatch } from "react-redux";
import { addDivision } from "../../redux/redux";
import PhoneInputNumber from "../Inputs/PhoneInputNumber";

export default function DivisionModal({
  isOpen,
  onClose,
  onRefresh,
  selectedData,
}) {
  const [institution, setInstitution] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lenderError, setLenderError] = useState({
    isError: false,
    message: "",
  });
  const [lender, setLender] = useState({ id: "", name: "" });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function checkLenderRequired(lender = {}) {
    if (lender === null) {
      setLenderError({ isError: true, message: "Institution est requis." });
    } else {
      setLenderError({ isError: false, message: "" });
    }
  }

  function handleInstitutionChange(e) {
    setLender({
      id: e.target.value,
    });
    checkLenderRequired(lender);
  }

  const toggleShow = () => onClose(false);

  // useEffect(() => {
  //   InstitutionService.getInstitution().then((rest) => {
  //     setInstitution(rest.data);
  //   });
  // }, []);

  const validateSchema = object({
    name: string().required("Name is required"),
    location: string().required("Location is required"),
    email: string()
      .email("Please enter a valid email")
      .required("Email is required"),
    budget: number().required("Budget is required").positive(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      email: "",
      budget: "",
      interestRate: "10",
      latenessGracePeriod: "2",
      collectionGracePeriod: "4",
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      const divisionData = {
        ...values,
        phoneNumber: phoneNumber,
        budget: parseInt(values.budget, 10),
        // interestRate: parseInt(values.interestRate, 10),
        // latenessGracePeriod: parseInt(values.latenessGracePeriod, 10),
        // collectionGracePeriod: parseInt(values.collectionGracePeriod, 10),
        status: "ACTIVE",
        lender: {
          id: lender.id,
        },
      };

      DivisionService.createDivision(divisionData)
        .then((userResponse) => {
          dispatch(addDivision(userResponse.data));
          toggleShow();
          ConsommationService.notifier(
            "success",
            "Division enregistrée avec succèes",
            "erreur"
          );
          onRefresh();
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Division enregistrée avec succèes",
            "Une erreur est survenue lors de la création de la division, Veuillez réessayer !"
          );
          console.log(err);
        });
    },
  });

  return (
    <MDBModal show={isOpen} setShow={onClose} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>CRÉER BOUTIQUE</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggleShow}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <FormControl required fullWidth size="small">
                  <InputLabel id="demo-simple-select-helper-label">
                    Institution
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="lender"
                    type="text"
                    value={lender.id}
                    label="Institution"
                    onChange={handleInstitutionChange}
                    error={Boolean(lenderError.isError)}
                    helperText={
                      Boolean(lenderError.isError) && lenderError.message
                    }
                  >
                    {selectedData.map((option) => (
                      <MenuItem key={option.value} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <InputOutline
                value={formik.values.name}
                type="text"
                func={formik.handleChange}
                name="name"
                label="Nom Boutique"
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <InputOutline
                value={formik.values.email}
                type="text"
                func={formik.handleChange}
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <InputOutline
                value={formik.values.location}
                type="text"
                func={formik.handleChange}
                label="Localité"
                name="location"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
              <div className="mb-3">
                <PhoneInputNumber
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </div>
              <InputOutline
                value={formik.values.budget}
                type="number"
                func={formik.handleChange}
                label="Budget"
                limit={9}
                name="budget"
                onBlur={formik.handleBlur}
                error={formik.touched.budget && Boolean(formik.errors.budget)}
                helperText={formik.touched.budget && formik.errors.budget}
              />
              {/* <InputOutline
                value={formik.values.interestRate}
                type="number"
                func={formik.handleChange}
                limit={2}
                label="Intérêt(%)"
                name="interestRate"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.interestRate &&
                  Boolean(formik.errors.interestRate)
                }
                helperText={
                  formik.touched.interestRate && formik.errors.interestRate
                }
              />
              <InputOutline
                value={formik.values.collectionGracePeriod}
                type="number"
                func={formik.handleChange}
                limit={2}
                label="Recouvrement Grâce"
                name="collectionGracePeriod"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.collectionGracePeriod &&
                  Boolean(formik.errors.collectionGracePeriod)
                }
                helperText={
                  formik.touched.collectionGracePeriod &&
                  formik.errors.collectionGracePeriod
                }
              />
              <InputOutline
                value={formik.values.latenessGracePeriod}
                type="number"
                func={formik.handleChange}
                limit={2}
                label="Retard Grâce"
                name="latenessGracePeriod"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.latenessGracePeriod &&
                  Boolean(formik.errors.latenessGracePeriod)
                }
                helperText={
                  formik.touched.latenessGracePeriod &&
                  formik.errors.latenessGracePeriod
                }
              /> */}
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-dark m-1"
                  type="button"
                  onClick={toggleShow}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="btn m-1"
                  disabled={!formik.isValid}
                  style={{
                    backgroundColor: "#2b4366",
                    border: "#2b4366",
                    color: "white",
                  }}
                >
                  Enregistrer
                </button>
              </div>
            </Form>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
