import axios from "axios";
import Cookies from "js-cookie";
import api from "./Api";

const URL_RESET_PASSWORD = "/api/users/{id}/reset-password";

class AuthService {
  login = async ({ email, password }) => {
    return await axios.post(
      process.env.REACT_APP_TODOLIST_DATAFILE_URL + "/api/auth/authenticate",
      {
        email: email,
        password: password,
      }
    );
  };

  currentUser = (user) => {
    let exist = false;
    if (user != null) {
      exist = !exist;
    } else {
      exist = exist;
    }
    return exist;
  };

  deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  getToken = (cookiename) => {
    // console.log(Cookies.get(cookiename));
    return { headers: { Authorization: "Bearer " + Cookies.get(cookiename) } };
  };

  logout = () => {
    Cookies.remove("token");
    Cookies.remove("prenom");
    Cookies.remove("nom");
    Cookies.remove("role");
    Cookies.remove("user");
    Cookies.remove("divisionCode");
    Cookies.remove("SECURE_LEN");
  };

  saveInCookieStorage = (key, value, options = {}) => {
    Cookies.set(key,value, options)
  }

  getCookie(name) {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim().split("="))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    return cookies[name];
  }

  isAuthenticated = () => {
    return this.getCookie("token") !== "";
  };

  resetPassword = (email) => {
    return api.put(`/api/users/${email}/reset-password`);
  };

  userAutorisation = (role) => {
    return this.getCookie("role") == role ? "block" : "none";
  };

  allAutorisation = () => {
    return this.isAuthenticated() ? "block" : "none";
  };

  isAgent = (role) => {
    return this.getCookie("role") === "AGENT";
  };

  isAdmin = (role) => {
    return this.getCookie("role") === "ADMIN";
  };
}

export default new AuthService();
