import React, { useEffect, useState } from "react";
import BanqueService from "../../Service/BanqueService";
import { Table } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import { Paginate } from "../Utils/Paginate";
import Pagination from "../Pagination/Pagination";
import generalService from "../../Service/generalService";
import { useDispatch, useSelector } from "react-redux";
import { setDataBank, updateStatusBank } from "../../redux/redux";
import FiltreComposant from "../ComposantsElementaire/FiltreComposant";
import ConsommationService from "../../Service/ConsommationService";
import Title from "../eng_composants/Title";



function AuditeList() {
  const [search, setSearch] = React.useState("");
  const [myBool, setMyBool] = useState(false);
  const [tabBank, setTabBank] = useState([]);
  const dispatch = useDispatch();
  const lesbanks = useSelector((state) => state.banques.Banks);
  const [filtre, setFiltrer] = useState("");

 
  useEffect(() => {
    BanqueService.getBanques().then((banks) => {
      dispatch(setDataBank(banks.data));
    });
  }, []);
  


  //Pagination
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const page = Paginate(lesbanks, currentPage, pageSize);



  //Page courant
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // FONCTION FILTER
  const functionFiltere = (criteria, filterValue) => {
    setSearch(filterValue);
    setFiltrer(criteria);
  };

  console.log(search);
  console.log(filtre);
  //FILTRER LISTE
  let myArray = [];

  // FILTRE LISTE DES BANK
  if (filtre === "") {
    myArray = { nodes: page };
  } else if (filtre === "Sigle") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.code.includes(search) || item.code.toLowerCase().includes(search)
      ),
    };
  } else if (filtre === "Adresse") {
    myArray = {
      nodes: page.filter(
        (item) =>
          item.location.includes(search) ||item.location.toLowerCase().includes(search)
      ),
    };
  }

  const bankStatus = (tabBank, etat) => {
    tabBank.map((bank) => {
      BanqueService.updateBankStatus(bank, etat).then((element) => {
        if (etat == "ACTIVE") {
          ConsommationService.notifier(
            "success",
            "Banque(s) Activé(s) avec succèes, Statut:ACTIVE",
            "erreur"
            );
            dispatch(updateStatusBank(["ACTIVE", bank.id]));
        } else {
      dispatch(updateStatusBank(["INACTIVE", bank.id]));
          ConsommationService.notifier(
            "success",
            "Banque(s) Désactivé(s) avec succèes, Statut:INACTIVE",
            "erreur"
          );
        }
      });
    });
    tabBank.length = 0;
  };

  const activer=()=>{
    bankStatus(tabBank, "ACTIVE");
    setMyBool(false);
  }
  const desactiver=()=>{
    bankStatus(tabBank, "INACTIVE");
    setMyBool(false);
  }



  return (
    <>
        <Title texte="LISTE OPÉRATIONS AUDITEES"/>
      <div className="col-12 d-flex flex-row justify-content-between">
        <FiltreComposant
          className="col-3"
          functionFiltere={functionFiltere}
          labelCritere="Critère"
          labelFiltre="Filtre"
          listeCritere={["Sigle", "Adresse"]}
        />
      </div>

      <Table striped bordered hover className="mt-4">
        <thead >
          <tr>
            <th className="text-center">
              <Checkbox
                checked={generalService.valuesExistes(page, tabBank)}
                onClick={(e) => {
                  generalService.toggle(myBool, setMyBool);
                  generalService.checkedAll(
                    e.target.checked,
                    page,
                    tabBank,
                    setTabBank
                  );
                }}
              />
            </th>
            <th className="align-middle">AuditID</th>
            <th className="align-middle">TableName</th>
            <th className="align-middle">Operation</th>
            <th className="align-middle">RecordID</th>
            <th className="align-middle text-center">UserID</th>
            <th className="align-middle text-center">MachineIP</th>
            <th className="align-middle text-center">AuditDateTime</th>

          </tr>
        </thead>
        <tbody>
          {/* {[].nodes.map((bank) => (
            <tr key={bank.id}>
              <td className="text-center">
                <Checkbox
                  checked={generalService.testingExistInTable(bank, tabBank)}
                  onClick={(e) => {
                    generalService.tabExport(
                      bank,
                      tabBank,
                      e.target.checked,
                      setTabBank
                    );
                  }}
                />
              </td>
              <td className="align-middle">{bank.code}</td>
              <td className="align-middle">{bank.name}</td>
              <td className="align-middle">{bank.location}</td>
              <td className="align-middle">{bank.email}</td>
              <td className="align-middle text-center"><Badge etat={bank.status}></Badge></td>
              <td className="align-middle text-center">
                <MDBTooltip
                  tag="a"
                  wrapperProps={{ color: "#2b4366" }}
                  title={"Editer Banque"}>
                  <EditeBanque objet={bank} />
                </MDBTooltip>
              </td>
            </tr>
          ))} */}
        </tbody>
      </Table>
      <Pagination
        items={lesbanks.length}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export default AuditeList;
