import React, { ChangeEvent, useState } from "react";
import { useFormik } from "formik";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBInput,
} from "mdb-react-ui-kit";
import { MdLocalOffer } from "react-icons/md";
import { style } from "@mui/system";
import { number } from "yup";
import OfferService from "../../Service/OfferService";
import InputOutline from "../Inputs/InputOutline";
import generalService from "../../Service/generalService";
import { Button } from "mdbreact";
import { useNavigate } from "react-router-dom";
import ConsommationService from "../../Service/ConsommationService";
import { NotificationContainer } from "react-notifications";
import { Form } from "react-bootstrap";
import BanqueService from "../../Service/BanqueService";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { updateBank } from "../../redux/redux";

export default function EditeBanque({ data, onRefresh }) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      id: data.id,
      code: data.code,
      location: data.location,
      email: data.email,
      name: data.name,
      status: data.status,
    },
    onSubmit: (values) => {
      const bankData = {
        ...values,
      };
      BanqueService.editBank(bankData, bankData.id)
        .then(() => {
          dispatch(updateBank(bankData));
          onRefresh()
          handleClose();
          ConsommationService.notifier(
            "success",
            "Banque éditée avec succèes",
            "erreur"
          );
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Votre Demande a été enregistrée avec succèes",
            "Une erreur est survenue lors de la création de l'offre, Veuillez réessayer !"
          );
          console.log(err);
        });
    },
  });


  return (
    <>
      <div>
        <NotificationContainer />
      </div>

      <button
        style={{ backgroundColor: "transparent", border: "none" }}
        onClick={() => setOpen(!open)}
      >
        <FiEdit
          style={{ backgroundColor: "transparent", fontSize: "1.5rem" }}
        ></FiEdit>
      </button>

      <MDBModal show={open} setShow={setOpen} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>EDITER BANQUE</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={handleClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Form onSubmit={formik.handleSubmit}>
                <InputOutline
                  name="name"
                  value={formik.values.name}
                  type="text"
                  func={formik.handleChange}
                  label="Nom Banque"
                />
                <InputOutline
                  name="email"
                  value={formik.values.email}
                  type="text"
                  func={formik.handleChange}
                  label="Email"
                />
                <InputOutline
                  name="location"
                  value={formik.values.location}
                  type="text"
                  func={formik.handleChange}
                  label="Adresse"
                />
                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-dark m-1"
                    type="button"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                  <button
                    disabled={!formik.isValid}
                    type="submit"
                    className="btn m-1"
                    style={{
                      backgroundColor: "#2b4366",
                      border: "#2b4366",
                      color: "white",
                    }}
                  >
                    Enregistrer
                  </button>
                </div>
              </Form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
