import api from "./Api";
import AuthService from "./AuthService";

const DIVISION_API_URL = "/api/references/divisions";

class DivisionService {
  getDivisions() {
    return api.get(DIVISION_API_URL, AuthService.getToken("token"));
  }

  getDivisionById(id) {
    return api.get(DIVISION_API_URL + "/" + id, AuthService.getToken("token"));
  }

  createDivision(div) {
    return api.post(DIVISION_API_URL, div, AuthService.getToken("token"));
  }

  editDivision(divisionData, divisionId) {
    return api.put(
      `${DIVISION_API_URL}/${divisionId}`,
      divisionData,
      AuthService.getToken("token")
    );
  }

  changeStatus(id, status) {
    return api.put(DIVISION_API_URL + "/" + id + "/status?status=" + status, {}, AuthService.getToken("token"));
  }
}

export default new DivisionService();
