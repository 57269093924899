import { Outlet, Navigate } from 'react-router-dom';
import React from 'react';
import AuthService from '../../Service/AuthService';

// GARDE ROUTE
export const ProtectRoutes = () => {
    return AuthService.isAuthenticated() ? <Outlet /> : <Navigate to='/' exact />
};

// PROTEGER LA ROUTE SUIVANT LE PROFILE CONNECTER
export const ProtectRoutesUser = ({ role }) => {
    return AuthService.isAuthenticated() && AuthService.getCookie("role") == role ? <Outlet /> : <Navigate to='/home' exact />
};

