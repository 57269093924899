import React, { useState } from "react";
import { useFormik } from "formik";

import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Button, Form, Modal } from "react-bootstrap";
import UserService from "../../Service/UserService";
import { FaUserEdit } from "react-icons/fa";
import "./EditModal.css";
import InputOutline from "../Inputs/InputOutline";
import dateFormat from "dateformat";
import { useDispatch } from "react-redux";
import { updateClient } from "../../redux/redux";
import InputPrompt from "../Inputs/InputPrompt";
import ConsommationService from "../../Service/ConsommationService";
import PhoneInputNumber from "../Inputs/PhoneInputNumber";

function EditModal({ data, onRefresh }) {
  const [show, setShow] = useState(false);
  const [duree, setDuree] = useState(String(data.jobDuration).split(" ")[1]);
  const [nombre, setNombre] = useState(String(data.jobDuration).split(" ")[0]);
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);
  const [activitySector, setActivitySector] = useState(data.activitySector);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      id: data.id,
      code: data.code,
      lastName: data.lastName,
      firstName: data.firstName,
      gender: data.gender,
      email: data.email,
      birthday: dateFormat(
        new Date(parseInt(data.birthday) * 1000),
        "yyyy-mm-dd"
      ),
      job: data.job,
      
      activityLocation: data.activityLocation,
      status: data.status,
    },
    onSubmit: (values) => {
      const clientData = {
        ...values,
        phoneNumber: phoneNumber,
        activitySector: activitySector,
        jobDuration: `${nombre} ${duree}`,
        birthday: new Date(values.birthday).toISOString(),
      };

      UserService.editClient(clientData)
        .then(() => {
          dispatch(updateClient(clientData));
          ConsommationService.notifier(
            "success",
            "Informations Client enregistrées avec succèes",
            "erreur"
          );
          handleClose();
          onRefresh();
        })
        .catch((err) => {
          ConsommationService.notifier(
            "error",
            "Informations Client enregistrées avec succèes",
            "Une erreur est survenue lors de la sauvegarde, Veuillez réessayer !"
          );
          console.log(err);
        });
    },
  });

  function handleActivitySectorChange(e) {
    setActivitySector(e.target.value)
  }

  const activitySectorsTables = [
    { key: "administration", value: "Administration" },
    { key: "agroalimentaire", value: "Agroalimentaire et Agriculture" },
    { key: "audit", value: "Audit et Comptabilité" },
    { key: "finance", value: "Banque - Finance - Assurance" },
    { key: "btp", value: "Construction - BTP" },
    { key: "economie", value: "Banque - Finance - Assurance" },
    { key: "education", value: "Education - Formation" },
    { key: "industrie", value: "Industrie" },
    { key: "tech", value: "Technologies de l'Information" },
    { key: "RH", value: "Resources Humaines" },
    { key: "sante", value: "Santé - Social" },
    { key: "transport", value: "Transport - Logistique" },
  ];

  return (
    <>
      <FaUserEdit
        className="m-2 translate"
        onClick={() => {
          handleShow();
        }}
        style={{ color: "black", border: "#2b4366", fontSize: "1.25rem" }}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>INFORMATIONS CLIENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <div class="row col-md-12">
              <div className="col-md-6">
                <InputOutline
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  func={formik.handleChange}
                  label="Prénom"
                  onBlur={formik.handleBlur}
                  limit={50}
                />
                <InputOutline
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  func={formik.handleChange}
                  label="Nom"
                  onBlur={formik.handleBlur}
                  limit={50}
                />
                <InputOutline
                  type="text"
                  name="email"
                  value={formik.values.email}
                  func={formik.handleChange}
                  label="Email"
                  onBlur={formik.handleBlur}
                />
                <FormControl className="mb-4" size="small" fullWidth>
                  <InputLabel id="sexe">Sexe</InputLabel>
                  <Select
                    labelId="sexe"
                    id="sexe"
                    name="gender"
                    value={formik.values.gender}
                    label="Sexe"
                    defaultValue={formik.values.gender}
                    onChange={formik.handleChange}
                  >
                    {[
                      { key: 1, value: "MASCULIN" },
                      { key: 2, value: "FEMININ" },
                    ].map((gender) => (
                      <MenuItem key={gender.key} value={gender.value}>
                        {gender.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <InputOutline
                  type="date"
                  name="birthday"
                  value={formik.values.birthday}
                  func={formik.handleChange}
                  label="Date de Naissance"
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <PhoneInputNumber
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </div>
                <InputOutline
                  type="texte"
                  name="job"
                  value={formik.values.job}
                  func={formik.handleChange}
                  label="Profession"
                  onBlur={formik.handleBlur}
                />
                <div className="mb-3">
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-select-small-label">
                      Secteur d'activité
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="activitySector"
                      value={activitySector}
                      label="Secteur d'activité"
                      onChange={handleActivitySectorChange}
                    >
                      {activitySectorsTables.map((activity) => (
                        <MenuItem key={activity.key} value={activity.key}>
                          {activity.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <InputOutline
                  type="texte"
                  name="activityLocation"
                  value={formik.values.activityLocation}
                  func={formik.handleChange}
                  label="Adresse de l'activité"
                  onBlur={formik.handleBlur}
                />
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  onChange={(e) => setDuree(e.target.value)}
                  value={duree}
                  defaultChecked={duree}
                >
                  <label className="mx-2">
                    <b>Expérience de Travail : </b>
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="duree"
                      id="mois"
                      value="mois"
                      checked={duree == "mois"}
                    />
                    <label className="form-check-label " for="mois">
                      <b>Mois</b>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label " htmlFor="ans">
                      <b>Années</b>
                    </label>
                    <input
                      class="form-check-input "
                      type="radio"
                      name="duree"
                      id="ans"
                      value="ans"
                      checked={duree == "ans"}
                    />
                  </div>
                </div>
                <div>
                  <input
                    min="0"
                    max="99"
                    className="form-control form-control-sm mb-2 w-100"
                    type="number"
                    name="nombre"
                    id="nombre"
                    value={nombre}
                    onChange={(e) => {
                      setNombre(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4 gap-4">
                <Button
                  type="button"
                  variant="contained"
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={handleClose}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#2b4366", color: "white" }}
                >
                  Enregistrer
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditModal;
