import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import UserService from "../../../Service/UserService";
import { Link, useNavigate } from "react-router-dom";
import Example from "../../Notification/NotificationCompenent";
import { Button, Form } from "react-bootstrap";
import generalService from "../../../Service/generalService";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { addCustomer, getAllCustomers } from "../../../redux/redux";
import Title from "../../eng_composants/Title";
import SexeCustomer from "../../../enumerations/SexeCustomer";
import "react-phone-number-input/style.css";
import dateFormat from "dateformat";
import Alert from "@mui/material/Alert";

import PhoneInputNumber from "../../Inputs/PhoneInputNumber";

const ClientForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [duree, setDuree] = useState("");
  const [nombre, setNombre] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [job, setJob] = useState("");
  const [activitySector, setActivitySector] = useState("");
  const [activityLocation, setActivityLocation] = useState("");
  const [age, setAge] = useState();
  const [error, setError] = useState({ isError: false, message: "" });
  const [phoneError, setPhoneError] = useState({
    isPhoneError: false,
    message: "",
  });
  const [emailError, setEmailError] = useState({
    isEmailError: false,
    message: "",
  });

  const [hasError, setHasError] = useState({ error: false, message: "" });

  const listeCustomer = useSelector((state) => state.clients.customers);

  let listEmailCustomer = listeCustomer.map((cust) => cust.email);
  let listPhoneCustomer = listeCustomer.map((cust) => cust.phoneNumber);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    UserService.getAllClients()
      .then((res) => {
        dispatch(getAllCustomers(res.data.reverse()));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function checkEmailExist(email, listEmails = []) {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (listEmails.includes(email)) {
      setEmailError({ isEmailError: true, message: "Email existe déja." });
    } else if (!regex.test(email)) {
      setEmailError({ isEmailError: true, message: "Email invalid." });
    } else {
      setEmailError({ isEmailError: false, message: "" });
    }
  }

  function checkPhoneExist(phone = "", listPhones = []) {
    if (listPhones.includes(phone.trim())) {
      setPhoneError({ isPhoneError: true, message: "Téléphone existe déja." });
    } else {
      setPhoneError({ isPhoneError: false, message: "" });
    }
  }

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    checkPhoneExist(value, listPhoneCustomer);
  };

  function handlePostClient(e) {
    e.preventDefault();

    const customerData = {
      firstName,
      lastName,
      gender,
      job,
      activitySector,
      activityLocation,
      jobDuration: `${nombre} ${duree}`,
      phoneNumber: phoneNumber,
      email: email,
      birthday: new Date(birthday).toISOString(),
      status: "ACTIVE",
      typeCustomer: "WEB",
    };

    console.log(customerData);

    const isClientUnique = listeCustomer.some(
      (client) =>
        client.firstName === customerData.firstName &&
        client.lastName === customerData.lastName &&
        new Date(parseInt(client.birthday) * 1000).toISOString() ===
          customerData.birthday
    );

    if (isClientUnique) {
      setError({
        isError: true,
        message: "Ce client existe déja dans le système.",
      });
    } else {
      setError({ isError: false, message: "" });

      UserService.createClient(customerData)
        .then((customer) => {
          addCustomer(customerData);
          navigate("/equip/" + customer.data.id);
        })
        .catch((err) => {
          console.log(err.response.data);
          throw err;
        });
    }
  }

  function checkLength(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }
  const afficheText = (value) => {
    if (isValidPhoneNumber(`${value}`, "SN")) {
      return <span className="text-success">numéro valide</span>;
    } else {
      return <span className="text-warning">numéro incomplet</span>;
    }
  };

  const activitySectorsTables = [
    { key: "administration", value: "Administration" },
    { key: "agroalimentaire", value: "Agroalimentaire et Agriculture" },
    { key: "audit", value: "Audit et Comptabilité" },
    { key: "finance", value: "Banque - Finance - Assurance" },
    { key: "btp", value: "Construction - BTP" },
    { key: "economie", value: "Banque - Finance - Assurance" },
    { key: "education", value: "Education - Formation" },
    { key: "industrie", value: "Industrie" },
    { key: "tech", value: "Technologies de l'Information" },
    { key: "RH", value: "Resources Humaines" },
    { key: "sante", value: "Santé - Social" },
    { key: "transport", value: "Transport - Logistique" },
  ];

  return (
    <>
      <div style={{ marginTop: "2rem" }}>
        <div className="text-center">
          <Title texte="INFORMATIONS CLIENT" />
          <div>
            {error.isError && (
              <Alert severity="error" className="text-center">
                {error.message}
              </Alert>
            )}
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <Form onSubmit={handlePostClient}>
                <div className="row col-md-12">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="firstName" className="mb-2">
                        <b>Prénom</b>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm mb-2"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        maxLength={50}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name" className="mb-2">
                        <b> Nom</b>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm mb-2"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className="mb-2">
                        <b>Email</b>
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control form-control-sm mb-2"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          checkEmailExist(e.target.value, listEmailCustomer);
                        }}
                        required
                      />
                      {emailError.isEmailError && (
                        <div className="text-danger">{emailError.message}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="gender" className="mb-2">
                        <b>Sexe</b>
                      </label>
                      <select
                        className="form-select form-select-sm  mb-3"
                        name="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      >
                        <option selected>Veuillez sélectionner le sexe</option>
                        {SexeCustomer.sexe.map((sexe) => (
                          <option key={sexe.value} value={sexe.value}>
                            {sexe.lebel}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="birthday" className="mb-2">
                        <b>Date de Naissance</b>
                      </label>
                      <input
                        type="date"
                        name="birthday"
                        className="form-control form-control-sm mb-1"
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          setBirthday(e.target.value);
                          setAge(generalService.getAge(e.target.value));
                        }}
                        value={birthday}
                        required
                      />
                      {age <= 18 && (
                        <div className="text-danger">
                          Le client doit avoir au moins 18 ans
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      <label htmlFor="phoneNumber" className="mb-2">
                        <b>Téléphone {afficheText(phoneNumber)}</b>
                      </label>
                      <PhoneInputNumber
                        inputStyle={{ height: "16px" }}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        error={Boolean(phoneError.isPhoneError)}
                        helperText={
                          Boolean(phoneError.isPhoneError) && phoneError.message
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="job" className="mb-2">
                        <b>Profession</b>
                      </label>
                      <input
                        type="text"
                        id="job"
                        name="job"
                        className="form-control form-control-sm mb-2"
                        value={job}
                        onChange={(e) => setJob(e.target.value)}
                        required
                        maxLength={50}
                      />
                    </div>
                    <div
                      role="group"
                      aria-labelledby="my-radio-group"
                      onChange={(e) => setDuree(e.target.value)}
                      value={duree}
                      required
                    >
                      <div>
                        <label htmlFor="jobDuration" className="mx-2">
                          <b>Expérience de Travail : </b>
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="duree"
                            id="mois"
                            value="mois"
                          />
                          <label className="form-check-label " for="mois">
                            <b>Mois</b>
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label className="form-check-label " for="ans">
                            <b>Années</b>
                          </label>
                          <input
                            className="form-check-input "
                            type="radio"
                            name="duree"
                            id="ans"
                            value="ans"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      role="group"
                      aria-labelledby="my-radio-group"
                      // onChange={handleChange}
                    >
                      <div className="form-group">
                        <input
                          min="0"
                          max="99"
                          className="form-control form-control-sm mb-2"
                          type="number"
                          name="nombre"
                          id="nombre"
                          value={nombre}
                          required
                          onChange={(e) => {
                            setNombre(e.target.value);
                            document
                              .getElementById("nombre")
                              .addEventListener("change", function () {
                                let v = parseInt(this.value);
                                if (v < 0) this.value = 0;
                                if (v > 99) this.value = 99;
                              });
                          }}
                          maxLength={2}
                          onInput={checkLength}
                          // onKeyUp={(e) => checkLength(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="activitySector" className="mb-2">
                        <b>Secteur d'activité</b>
                      </label>

                      <select
                        className="form-select form-select-sm  mb-3"
                        name="activitySector"
                        onChange={(e) => setActivitySector(e.target.value)}
                        value={activitySector}
                        required
                      >
                        <option selected>
                          Veuillez sélectionner le secteur d'activité
                        </option>
                        {activitySectorsTables.map((activite) => (
                          <option key={activite.key} value={activite.key}>
                            {activite.value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email " className="mb-2">
                        <b>Addresse de l'activité</b>
                      </label>
                      <input
                        type="text"
                        id="activityLocation"
                        name="activityLocation"
                        className="form-control form-control-sm mb-2"
                        onChange={(e) => setActivityLocation(e.target.value)}
                        value={activityLocation}
                        required
                        maxLength={25}
                      />
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center align-items-around gap-4 mt-4">
                    <Button
                      type="button"
                      onClick={() => {}}
                      className="btn btn-dark"
                    >
                      <Link
                        style={{ color: "white", textDecoration: "none" }}
                        to="/clients"
                      >
                        Annuler
                      </Link>
                    </Button>
                    <button
                      className="btn btn-success"
                      style={{
                        backgroundColor: "#2b4366",
                        border: "#2b4366",
                      }}
                      type="submit"
                      disabled={
                        !isValidPhoneNumber(phoneNumber, "SN") ||
                        firstName === "" ||
                        lastName === "" ||
                        phoneNumber === "" ||
                        gender === "" ||
                        job === "" ||
                        email === "" ||
                        activitySector === "" ||
                        activityLocation === ""
                      }
                    >
                      Demander
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Example />
    </>
  );
};

export default ClientForm;
