import React from "react";
import ClientCard from "../ComposantsElementaire/ClientCard";
import Title from "../eng_composants/Title";
import CardRole from "../eng_composants/CardRole";

export default function ListeRole() {
  const lesRoles = [
    {
      role: "ADMINISTRATEUR",
    },
  ];
  return (
    <>
      <Title texte="LISTE  ROLES UTILISATEURS" />
      <div
        className=" justify-content-around align-items-center mt-5"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <CardRole
          role="ADMINISTRATEUR"
          sexe="MASCULIN"
          activte="GESTIONNAIRE DE L'APPLICATION"
        ></CardRole>
        <CardRole
          role="AGENT"
          sexe="FEMININ"
          activte=" CHARGÉ DES OPÉRATIONS"
        ></CardRole>
      </div>
    </>
  );
}
