import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import NavBarHome from "../NavBar/NavBarHome";
import CardHome from "../eng_composants/CardHome";
import HomeService from "./HomeService";
import { useDispatch } from "react-redux";
import EquipementService from "../../Service/EquipementService";
import { setDataDemandesEquipement } from "../../redux/redux";
import AuthService from "../../Service/AuthService";

const Home = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    EquipementService.getDemands().then((rest) => {
      dispatch(
        setDataDemandesEquipement(
          rest.data
            .filter((element) => element.typeOrder === "EQUIPEMENT")
            .reverse()
        )
      );
    });
  });

  const tiles = () => {
    return (
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mb-4">
            <CardHome
              titre="DEMANDE"
              images={HomeService.demandes}
              textStyle={{ color: " #575757", textAlign: "center" }}
            />
          </div>
          <div class="col-lg-6 mb-4">
            <CardHome
              titre="DOCUMENTS"
              images={HomeService.fichiers}
              textStyle={{ color: " #575757", textAlign: "center" }}
            />
          </div>
        </div>

        <div class="row">
          <div
            class="col-lg-6 mb-4"
            style={{
              color: AuthService.isAdmin("ADMIN") ? "#575757" : "darkgray",
            }}
          >
            <CardHome
              titre="ORGANISATIONS"
              images={HomeService.tiers}
              textStyle={{
                color: AuthService.isAdmin("ADMIN") ? "#575757" : "darkgray",
                cursor: AuthService.isAdmin("ADMIN") ? "pointer" : "default",
                textAlign: "center",
              }}
            />
          </div>
          <div
            class="col-lg-6 mb-4"
            style={{
              color: AuthService.isAdmin("ADMIN") ? "#575757" : "darkgray",
            }}
          >
            <CardHome
              textStyle={{
                color: AuthService.isAdmin("ADMIN") ? "#575757" : "darkgray",
                cursor: AuthService.isAdmin("ADMIN") ? "pointer" : "default",
                textAlign: "center",
              }}
              titre="ADMINISTRATION"
              images={HomeService.administration}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container fluid className="" style={{ marginTop: "10rem" }}>
      <NavBarHome></NavBarHome>
      {tiles()}
    </Container>
  );
};

export default Home;
