import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router";
import ConsommationService from "../../Service/ConsommationService";
import DocumentOrder from "../Modals/DocumentsOrder";
import AWS from "aws-sdk";
import GestionDesDocuments from "../../Service/GestionDesDocuments";
import Checkbox from "@mui/material/Checkbox";
import { Table } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocuments, updateStatusDocument } from "../../redux/redux";
import Title from "../eng_composants/Title";
import { saveAs } from "file-saver";
import {
  FaCloudDownloadAlt,
  FaArchive,
} from "react-icons/fa";
import { MDBTooltip } from "mdb-react-ui-kit";
import Badge from "../Badge/Badge";
import generalService from "../../Service/generalService";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import ButtonIcon from "../eng_composants/ButtonIcon";
import { Paginate } from "../Utils/Paginate";
import Pagination from "../Pagination/Pagination";
import { NotificationContainer } from "react-notifications";

function FicheDemande() {
  const { id } = useParams();
  const [code, setCode] = useState("");
  const [montant, setMontant] = useState("");
  const [typeOrder, setTypeOrder] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const listDocument = useSelector((state) => state.documents.documents);

  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const REGION = "eu-west-3";

  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    Bucket: BUCKET_NAME,
    region: REGION,
    signatureVersion: "v4",
  });

  useEffect(() => {
    ConsommationService.getDemandById(id).then((data) => {
      setCode(data.data.code);
      setMontant(data.data.propertyValue);
      setTypeOrder(data.data.typeOrder);
      setPrenom(data.data.customerDto.firstName);
      setNom(data.data.customerDto.lastName);
      setEmail(data.data.customerDto.email);
      setPhoneNumber(data.data.customerDto.phoneNumber);
      setOrderDate(generalService.formatDate(data.data.orderDate));
      setDescription(data.data.description);
    });

    GestionDesDocuments.getDocumentById(id).then((rest) => {
      dispatch(getAllDocuments(rest.data));
    });
  }, [ignored]);

  const downloadFileFromS3 = async (keyName, fileName) => {
    // Créez une instance AWS S3 en utilisant les informations d'identification appropriées
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_KEY,
    });
    // Récupérez le contenu du fichier depuis S3
    const s3Object = await s3
      .getObject({ Bucket: BUCKET_NAME, Key: keyName })
      .promise();
    const fileContent = s3Object.Body;
    // Créez un objet File à partir du contenu du fichier et du nom de fichier spécifié
    const file = new File([fileContent], fileName, {
      type: s3Object.ContentType,
    });
    const fileUrl = URL.createObjectURL(file);
    saveAs(fileUrl, file.name);
  };

  // Set the URL of the PDF worker script
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const documentStatus = (tabBank, etat) => {
    tabBank.map((bank) => {
      GestionDesDocuments.changeStatusDocument(bank.id, etat).then(() => {
        dispatch(updateStatusDocument({ id: bank.id, status: etat }));
        ConsommationService.notifier(
          "success",
          `Document(s) Activé(s) avec succès, Statut: ${etat}`,
          "erreur"
        );
        forceUpdate();
      });
    });

    setDocuments([]);
  };

  const expirer = () => {
    documentStatus(documents, "EXPIRED");
  };
  const actualiser = () => {
    documentStatus(documents, "CURRENT");
  };

  const archiver = () => {
    documentStatus(documents, "ARCHIVED");
  };

  //Pagination
  const pageSize = 10;

  const page = Paginate(listDocument, currentPage, pageSize);
  //Page courant
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="col-6">
        <NotificationContainer />
      </div>

      <Title texte="INFORMATION DEMANDE" />
      <div className="row" style={{ marginTop: "2rem" }}>
        <div className="col-6">
          <div className="card">
            <div className="card-header" style={{ backgroundColor: "#b5e0f0" }}>
              <h4> INFORMATIONS DEMANDE</h4>
            </div>
            <div className="card-body ">
              <h5 className="card-title">Code : {code}</h5>
              <h5 className="card-title">
                Montant : {generalService.formatAmount(montant)}
              </h5>
              <h5 className="card-title">Type : {typeOrder} </h5>
              <h5 className="card-title">Date : {orderDate}</h5>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div
              className="card-header "
              style={{ backgroundColor: "#b5e0f0" }}
            >
              <h4>INFORMATIONS CLIENT</h4>
            </div>
            <div className="card-body">
              <h5 className="card-title">Prénom : {prenom}</h5>
              <h5 className="card-title">Nom : {nom}</h5>
              <h5 className="card-title">Email : {email}</h5>
              <h5 className="card-title">Téléphone : {phoneNumber}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <Title texte="LISTE DOCUMENTS" />
        <div className="col-12 d-flex flex-row justify-content-between">
          <DocumentOrder orderId={id} onRefresh={forceUpdate} />
          <div className="d-flex col-3.4 align-self-center">
            <ButtonIcon
              disabled={generalService.isActivatedButton(documents)}
              action={actualiser}
              iconName={<BsFillCheckCircleFill />}
              titre="Actualiser"
            />
            <ButtonIcon
              disabled={generalService.isActivatedButton(documents)}
              action={expirer}
              iconName={<BsFillXCircleFill />}
              titre="Expirer"
            />
            <ButtonIcon
              disabled={generalService.isActivatedButton(documents)}
              action={archiver}
              iconName={<FaArchive />}
              titre="Archiver"
            />
          </div>
        </div>

        <Table striped bordered hover>
          <thead style={{ backgroundColor: "#b5e0f0", fontWeight: "bold" }}>
            <tr className="text-center">
              <th>
                <Checkbox
                  checked={generalService.isSelectedAll(page, documents)}
                  onClick={(e) => {
                    generalService.selectedAllElementsInPage(
                      e.target.checked,
                      page,
                      documents,
                      setDocuments
                    );
                  }}
                />
              </th>
              <th className="align-middle">Nom</th>
              <th className="align-middle">Description</th>
              <th className="align-middle">Type Document</th>
              <th className="align-middle">Document</th>
              <th className="align-middle">Statut</th>
              <th className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listDocument.map((doc) => (
              <tr className="text-center" key={doc.id}>
                <td className="align-middle">
                  <Checkbox
                    checked={generalService.isSelectedElement(doc, documents)}
                    onClick={(e) => {
                      generalService.tabExport(
                        doc,
                        documents,
                        e.target.checked,
                        setDocuments
                      );
                    }}
                  />
                </td>
                <td className="align-middle">{doc.name}</td>
                <td className="align-middle">{doc.description}</td>
                <td className="align-middle">{doc.type}</td>
                <td className="align-middle">{doc.path.substring(48, 100)}</td>
                <td className="align-middle text-center">
                  <Badge etat={doc.status}></Badge>
                </td>

                <td className="align-middle">
                  <MDBTooltip
                    tag="a"
                    wrapperProps={{ color: "#2b4366" }}
                    title={"Télécharger"}
                  >
                    <FaCloudDownloadAlt
                      onClick={() => {
                        downloadFileFromS3(
                          doc.path.substring(48, 100),
                          doc.path.substring(48, 100)
                        );
                      }}
                      style={{ fontSize: "1.3rem" }}
                    />
                  </MDBTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          items={listDocument.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>

      {/* <div>
      <h4>PDF Content:</h4>
      {pdfUrl && (
        <Document
          file={pdfUrl}
          onLoadError={console.error}
        >
          <Page pageNumber={1} />
        </Document>
      )}
    </div> */}
    </>
  );
}

export default FicheDemande;
