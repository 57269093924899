import React, { useState } from 'react';
// limite la taille des fichiers à 5 Mo :
function UploadFile({myFile}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [erreu,setErreu]=useState("")

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      // alert("Le fichier est trop grand. La taille maximale est de 5 Mo.");
      setErreu("Le fichier est trop grand. La taille maximale est de 5 Mo.")
      setSelectedFile(null);
    } else {
      setErreu("")
      setSelectedFile(file);
    }
  }

  myFile(selectedFile)
  return (
    <div>
      <input type="file" accept=".jpg, .jpeg, .png ,.pdf" onChange={handleFileChange} /><br/>
     <span className='text-danger'> {erreu}</span>
    </div>
  );
}

export default UploadFile;
